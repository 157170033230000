import { NgModule, Type } from '@angular/core';

import { BaseComponent } from './base/base.component';
import { ComponentShieldDirective } from './component-shield.directive';
import { SlotComponent } from './slot/slot.component';
import { SlotDirective } from './slot/slot.directive';

const COMPONENTS: Type<any>[] = [BaseComponent, SlotComponent];
const DIRECTIVES: Type<any>[] = [ComponentShieldDirective, SlotDirective];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports: [...COMPONENTS, ...DIRECTIVES],
})
export class CoreModule {}
