import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';

import { SmartTableColumn } from '@uibakery/fields-types';

@Component({
  selector: 'ub-smart-table-header-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cell.scss', './smart-table-header-cell.component.scss'],
  template: `
    <div>
      <span (click)="changeSort.emit()">{{ column?.name }}</span>
      <button class="sort-button" *ngIf="!!sortIcon" (click)="changeSort.emit()" nbButton appearance="ghost">
        <nb-icon [icon]="sortIcon"></nb-icon>
      </button>
    </div>
  `,
})
export class SmartTableHeaderCellComponent {
  sortIcon: string = '';

  @Input() set sortDirection(sortDirection: string) {
    this.setSortIcon(sortDirection);
  }

  @Input() column?: SmartTableColumn;
  @Input() tableColumn!: TableColumn;

  @Output() changeSort: EventEmitter<void> = new EventEmitter<void>();

  private setSortIcon(dir: string): void {
    if (dir === 'asc') {
      this.sortIcon = 'arrow-up';
    } else if (dir === 'desc') {
      this.sortIcon = 'arrow-down';
    } else {
      this.sortIcon = '';
    }
  }
}
