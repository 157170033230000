import { DataConfig } from './lib/schema';

export { CoreModule } from './lib/core.module';
export { ComponentRegistry } from './lib/component-registry';
export { CREATE_REASON, Base } from './lib/base';
export { BaseComponent, BAKERY_COMPONENT } from './lib/base/base.component';
export { Slot } from './lib/slot';
export { SlotComponent } from './lib/slot/slot.component';
export { SlotDirective } from './lib/slot/slot.directive';
export {
  Schema,
  ComponentMetaInfo,
  PropertyField,
  ComponentInput,
  ComponentOutput,
  ComponentPropertyDataHintFormat,
  ComponentUIProperty,
  getInputName,
  DataConfig,
  InterpolatedPropertiesInfo,
  ChildField,
  ChildrenFactory,
  InterpolationType,
  SchemaResolver,
  SchemaFactory,
  ValueType,
  DataNotification,
} from './lib/schema';
export {
  ComponentMetadata,
  ComponentSchemaWithAssets,
  ComponentModule,
  COMPONENT_METADATA,
} from './lib/component-metadata';
export { Renderer } from './lib/renderer';
export { ComponentType, ComponentFactory, ModuleFactory, ComponentRef, PipeType } from './lib/component-type';
export { ComponentLibrary, ComponentLibraryLoader, ComponentLibraryInstaller } from './lib/component.library';
export { ThemeService } from './lib/theme.service';
export { ComponentRenderer, ComponentRendererFactory } from './lib/component.renderer';
export { RenderComponent } from './lib/render-component.model';
export {
  BreakpointWidth,
  ComponentActions,
  BreakpointStyles,
  ComponentProperties,
  ComponentStyles,
  TriggeredAction,
} from './lib/component';

export { AppModeProvider, AppMode } from './lib/app-mode-provider';
export { mergeFieldValue } from './lib/merge-field-value';
export { ComponentInitService } from './lib/component-init.service';
export * from './lib/styles';
