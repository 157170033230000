import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NbMediaBreakpoint, NbThemeService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointWidth, StylesService } from '@uibakery/core';

@Pipe({
  name: 'responsive',
  pure: false,
})
export class ResponsivePipe implements PipeTransform, OnDestroy {
  private currentBreakpoint!: NbMediaBreakpoint;
  private destroyed: Subject<void> = new Subject<void>();
  private initialized: boolean = false;

  constructor(
    private themeService: NbThemeService,
    private ref: ChangeDetectorRef,
    private stylesService: StylesService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed.next();
  }

  transform(value: any): any {
    if (!this.initialized) {
      this.init();
    }
    if (!this.currentBreakpoint || value === null || value === undefined) {
      return value;
    }
    return this.stylesService.getMostSuitableStyle(value, this.currentBreakpoint.name as BreakpointWidth);
  }

  private init(): void {
    this.initialized = true;
    this.themeService
      .onMediaQueryChange()
      .pipe(takeUntil(this.destroyed))
      .subscribe(([_, breakpoint]: NbMediaBreakpoint[]) => {
        this.currentBreakpoint = breakpoint;
        this.ref.markForCheck();
      });
  }
}
