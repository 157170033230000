import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { CardHeaderComponent } from './card-header.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [CardHeaderComponent],
  exports: [CardHeaderComponent],
})
export class CardHeaderModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: CardHeaderComponent,
    schema: require('./card-header.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./card-header.calculator.svg').default,
        componentTree: require('!!raw-loader?!./card-header.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./card-header.component-tree-active.svg').default,
      },
    },
  };
}
