import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbListModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { ListItemComponent } from './list-item.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [ListItemComponent],
  exports: [ListItemComponent],
})
export class ListItemModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: ListItemComponent,
    schema: require('./list-item.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!../list/list.calculator.svg').default,
        componentTree: require('!!raw-loader?!../list/list.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!../list/list.component-tree-active.svg').default,
      },
    },
  };
}
