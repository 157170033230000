import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbCardModule, NbIconModule, NbThemeModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { SharedModule } from '../shared/shared.module';
import { ChartComponent } from './chart.component';

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule, NbIconModule, NbThemeModule, NbCardModule],
  declarations: [ChartComponent],
  exports: [ChartComponent],
})
export class ChartModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: ChartComponent,
    schema: require('./chart.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./chart.calculator.svg').default,
        componentTree: require('!!raw-loader?!./chart.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./chart.component-tree-active.svg').default,
      },
    },
  };
}
