import { ViewContainerRef } from '@angular/core';

import { Base } from './base';
import { ComponentRenderer } from './component.renderer';
import { RenderComponent } from './render-component.model';

export abstract class Slot extends Base {
  abstract name: string;
  abstract content: RenderComponent[];
  abstract vcr: ViewContainerRef;
  protected abstract componentRenderer: ComponentRenderer;
}
