import { createReducer, on, Action } from '@ngrx/store';

import * as AppActions from './app.actions';

export interface State {
  uiSUpportMesage: {
      value: any;
  };
  uiSupportURGENT: {
      value: any;
  };
  uiNomAgentSupport: {
      value: any;
  };
  uiC_v2_signes: {
      value: any;
  };
  uiC_v2_couleur: {
      value: any;
  };
  uiC_v2_modele: {
      value: any;
  };
  uiC_v2_plaque: {
      value: any;
  };
  uiC_v2_type: {
      value: any;
  };
  uiC_v1_signes: {
      value: any;
  };
  uiC_v1_couleur: {
      value: any;
  };
  uiC_v1_modele: {
      value: any;
  };
  uiC_v1_plaque: {
      value: any;
  };
  uiC_v1_type: {
      value: any;
  };
  uiC_p4_description: {
      value: any;
  };
  uiC_p4_matricule: {
      value: any;
  };
  uiC_p4_nom: {
      value: any;
  };
  uiC_p4_statut: {
      value: any;
  };
  uiC_p3_description: {
      value: any;
  };
  uiC_p3_matricule: {
      value: any;
  };
  uiC_p3_nom: {
      value: any;
  };
  uiC_p3_statut: {
      value: any;
  };
  uiC_p2_description: {
      value: any;
  };
  uiC_P2_matricule: {
      value: any;
  };
  uiC_P2_nom: {
      value: any;
  };
  uiC_P2_statut: {
      value: any;
  };
  uiC_P1_description: {
      value: any;
  };
  uiC_P1_matricule: {
      value: any;
  };
  uiC_P1_nom: {
      value: any;
  };
  uiC_P1_statut: {
      value: any;
  };
  uiC_Nbvehicules: {
      value: any;
  };
  uiC_nbPersonnes: {
      value: any;
  };
  uiC_DescriptionSituation: {
      value: any;
  };
  uiC_Check_autres: {
      value: any;
  };
  uiC_Check_Observation: {
      value: any;
  };
  uiC_Check_premierssoins: {
      value: any;
  };
  uiC_Check_Nuisible: {
      value: any;
  };
  uiC_Check_AppelRecu: {
      value: any;
  };
  uiC_Check_materielRecupere: {
      value: any;
  };
  uiC_Check_materieldonnee: {
      value: any;
  };
  uiC_Check_ramassagedechet: {
      value: any;
  };
  uiC_Check_InterventionCommercant: {
      value: any;
  };
  uiC_Check_ContactOrganisme: {
      value: any;
  };
  uiC_Check_contactmarginalisee: {
      value: any;
  };
  uiC_Check_ContactCitoyen: {
      value: any;
  };
  uiC_Check_911: {
      value: any;
  };
  uiC_Check_311: {
      value: any;
  };
  uiC_Check_Referencement: {
      value: any;
  };
  uiC_Secteur: {
      value: any;
  };
  uiC_Lieu: {
      value: any;
  };
  uiC_Temperature: {
      value: any;
  };
  uiC_Horaire: {
      value: any;
  };
  uiC_NomRemplacant: {
      value: any;
  };
  uiC_NomAgent2: {
      value: any;
  };
  uiC_NomAgent1: {
      value: any;
  };
  uiC_HeureIntervention: {
      value: any;
  };
  uiC_DateIntervention: {
      value: any;
  };
  uiVehicule2_signes: {
      value: any;
  };
  uiVehicule2_Couleur: {
      value: any;
  };
  uiVehicule2_modele: {
      value: any;
  };
  uiVehicule2_plaque: {
      value: any;
  };
  uiVehicule2_type: {
      value: any;
  };
  uiVehicule1_signes: {
      value: any;
  };
  uiVehicule1_Couleur: {
      value: any;
  };
  uiVehicule1_modele: {
      value: any;
  };
  uiVehicule1_plaque: {
      value: any;
  };
  uiVehicule1_type: {
      value: any;
  };
  uiPersonne4_description: {
      value: any;
  };
  uiPersonne4_matricule: {
      value: any;
  };
  uiPersonne4_nom: {
      value: any;
  };
  uiPersonne4_Statut: {
      value: any;
  };
  uiPersonne3_description: {
      value: any;
  };
  uiPersonne3_matricule: {
      value: any;
  };
  uiPersonne3_nom: {
      value: any;
  };
  uiPersonne3_Statut: {
      value: any;
  };
  uiPersonne2_description: {
      value: any;
  };
  uiPersonne2_matricule: {
      value: any;
  };
  uiPersonne2_nom: {
      value: any;
  };
  uiPersonne2_Statut: {
      value: any;
  };
  uiPersonne1_description: {
      value: any;
  };
  uiPersonne1_matricule: {
      value: any;
  };
  uiPersonne1_nom: {
      value: any;
  };
  uiPersonne1_Statut: {
      value: any;
  };
  uiNbVehicule: {
      value: any;
  };
  uiNbPersonnes: {
      value: any;
  };
  uiDescriptionIntervention: {
      value: any;
  };
  uiCheck_Autres: {
      value: any;
  };
  uiCheck_Observation: {
      value: any;
  };
  uiCheck_Premierssoins: {
      value: any;
  };
  uiCheck_InterventionNuisible: {
      value: any;
  };
  uiCheck_AppelRecu: {
      value: any;
  };
  uiCheck_MaterielRecupere: {
      value: any;
  };
  uiCheck_MaterielDonne: {
      value: any;
  };
  uiCheck_RamassageDechet: {
      value: any;
  };
  uiCheck_InterventionCommercant: {
      value: any;
  };
  uiCheck_ContactOrganisme: {
      value: any;
  };
  uiCheck_ContactMarginalisees: {
      value: any;
  };
  uiCheck_ContactCitoyens: {
      value: any;
  };
  uiCheck_Appel911: {
      value: any;
  };
  uiCheck_Appel311: {
      value: any;
  };
  uiCheck_Referencement: {
      value: any;
  };
  uiNumSecteur: {
      value: any;
  };
  uiLieuIntervention: {
      value: any;
  };
  uiTemperature: {
      value: any;
  };
  uiHoraire: {
      value: any;
  };
  uiNomAgentRemplacant: {
      value: any;
  };
  uiNomAgent2: {
      value: any;
  };
  uiNomAgent1: {
      value: any;
  };
  uiHeureIntervention: {
      value: any;
  };
  uiDateIntervention: {
      value: any;
  };
  uiTableInterventions: {
      deletedRow: any;
      editedRow: any;
      newRow: any;
      selectedRow: any;
      selectedRowIndex: any;
  };
  uiSmartTableIA_Inter: {
      deletedRow: any;
      editedRow: any;
      newRow: any;
      selectedRow: any;
      selectedRowIndex: any;
  };
  uiTextArea7: {
      value: any;
  };
  uiTextArea6: {
      value: any;
  };
  uiTextArea5: {
      value: any;
  };
  uiTextArea42: {
      value: any;
  };
  uiTextArea41: {
      value: any;
  };
  uiTextArea4: {
      value: any;
  };
  uiTextArea3: {
      value: any;
  };
  uiTextArea2: {
      value: any;
  };
  uiTextArea1: {
      value: any;
  };
  uiTextArea: {
      value: any;
  };
  uiTabs: {
      selectedIndex: any;
      length: any;
  };
  uiSmartTable: {
      deletedRow: any;
      editedRow: any;
      newRow: any;
      selectedRow: any;
      selectedRowIndex: any;
  };
  uiTabs2: {
      selectedIndex: any;
      length: any;
  };
  uiSecret: {
      value: any;
  };
  uiUsername: {
      value: any;
  };
  stateAMoutofINTCONCAT: any;
  stateListconcat: any;
  stateListinc2: any;
  stateTempSelectedIntervention: any;
  stateListInterventions: any;
  stateAmountofInterventions: any;
  stateEventID_vehicule2_signes: any;
  stateEventID_vehicule2_couleur: any;
  stateEventID_vehicule2_modele: any;
  stateEventID_vehicule2_plaque: any;
  stateEventID_vehicule2_type: any;
  stateEventID_vehicule1_signes: any;
  stateEventID_vehicule1_couleur: any;
  stateEventID_vehicule1_modele: any;
  stateEventID_vehicule1_plaque: any;
  stateEventID_vehicule1_type: any;
  stateEventID_personne4_description: any;
  stateEventID_personne4_matricule: any;
  stateEventID_personne4_nom: any;
  stateEventID_personne4_statut: any;
  stateEventID_personne3_description: any;
  stateEventID_personne3_matricule: any;
  stateEventID_personne3_nom: any;
  stateEventID_personne3_statut: any;
  stateEventID_personne2_description: any;
  stateEventID_personne2_matricule: any;
  stateEventID_personne2_nom: any;
  stateEventID_personne2_statut: any;
  stateEventID_personne1_description: any;
  stateEventID_personne1_matricule: any;
  stateEventID_personne1_nom: any;
  stateEventID_personne1_statut: any;
  stateEventID_nbvehicules: any;
  stateEventID_nbpersonnes: any;
  stateEventID_description_situation: any;
  stateEventID_nature_autres: any;
  stateEventID_nature_observation: any;
  stateEventID_nature_premiersoins: any;
  stateEventID_nature_nuisible: any;
  stateEventID_nature_appelrecu: any;
  stateEventID_nature_materiel_recupere: any;
  stateEventID_nature_materiel_donne: any;
  stateEventID_nature_dechet: any;
  stateEventID_nature_intervention: any;
  stateEventID_nature_contact_organisme: any;
  stateEventID_nature_contact_population: any;
  stateEventID_nature_contact_citoyen: any;
  stateEventID_nature_911: any;
  stateEventID_nature_311: any;
  stateEventID_nature_referencement: any;
  stateEventID_secteur: any;
  stateEventID_lieu: any;
  stateEventID_temperature: any;
  stateEventID_horaire: any;
  stateEventID_agentremplacant: any;
  stateEventID_agent2: any;
  stateEventID_agent1: any;
  stateEventID_heureintervention: any;
  stateEventID_dateutc: any;
  stateEventID_dateintervention: any;
  stateInterventions_personne4_desc: any;
  stateInterventions_personne3_desc: any;
  stateInterventions_personne2_desc: any;
  stateInterventions_personne1_desc: any;
  stateDateTest: any;
  stateTEMP_PAYLOAD: any;
  stateTEMP_PRINTINTERVENTION_ID: any;
  stateTEMP_PRINTINTERVENTION_URL: any;
  stateTEMP_PRINTINTERVENTION: any;
  stateAVIS_Statut: any;
  stateAVIS_Titre: any;
  stateAVIS_Message: any;
  stateDataFromEventID_dateutc: any;
  stateDataFromIntervention_dateinterventionUTC: any;
  stateDash_inc_apr: any;
  stateDash_inc_mar: any;
  stateDash_inc_feb: any;
  stateDash_Inc_Jan: any;
  stateDash_Int_Apr: any;
  stateDash_int_Mar: any;
  stateDash_int_Feb: any;
  stateDash_Int_Jan: any;
  stateB64F1: any;
  stateIncidents_convert_date: any;
  stateIncidents_convert_individu6: any;
  stateIncidents_convert_individu5: any;
  stateIncidents_convert_individu4: any;
  stateIncidents_convert_individu3: any;
  stateIncidents_convert_individu2: any;
  stateIncidents_convert_individu1: any;
  stateIncidents_convert_description: any;
  stateDateConvert: any;
  stateInterventions_convert_resolution: any;
  stateInterventions_convert_description: any;
  stateInterventions_convert_comments: any;
  stateUsernameAPI: any;
  stateSubmissionDate: any;
  stateIntIDIntervention: any;
  stateDataFromIntervention_dateintervention: any;
  stateDataFromIntervention_agent2: any;
  stateDataFromIntervention_agent1: any;
  stateDataFromIntervention_comments: any;
  stateDataFromIntervention_description: any;
  stateDataFromIntervention_resolution_detail: any;
  stateDataFromIntervention_type: any;
  stateDataFromIntervention_heure: any;
  stateDataFromIntervention_secteur: any;
  stateDataFromIntervention_lieu: any;
  stateDataFromIntervention_temps: any;
  stateDataFromIntervention_horaire: any;
  stateTempSelectedIDIntervention: any;
  stateAmoutofINC: any;
  stateListinc: any;
  stateBDExtPWD: any;
  stateValidtySessionToken: any;
  stateMD5Users: any;
  stateDataFromEventID_instance_suivi_num: any;
  stateDataFromEventID_instance_suivi: any;
  stateDataFromEventID_instance_heure: any;
  stateDataFromEventID_instance_tel: any;
  stateDataFromEventID_instance_nom_contact: any;
  stateDataFromEventID_instance_nom: any;
  stateDataFromEventID_individu6: any;
  stateDataFromEventID_individu5: any;
  stateDataFromEventID_individu4: any;
  stateDataFromEventID_individu3: any;
  stateDataFromEventID_individu2: any;
  stateDataFromEventID_individu1: any;
  stateDataFromEventID_deroulement: any;
  stateDataFromEventID_dateincident: any;
  stateDataFromEventID_heureincident: any;
  stateDataFromEventID_natureincident: any;
  stateDataFromEventID_transversale: any;
  stateDataFromEventID_Adresse: any;
  stateUsers: any;
  stateTempSelectedIDEvent: any;
  stateGetDataFromEventID: any;
  stateAmoutofEvent: any;
  stateIntIDEvent: any;
  stateUsers2: any;
  stateListevenement: any;
  stateAIR_TABLE_TOKEN: any;
  stateLoginErrorMessage: any;
}

const initialState: State = {
  uiSUpportMesage: {
      value: null,
  },
  uiSupportURGENT: {
      value: null,
  },
  uiNomAgentSupport: {
      value: null,
  },
  uiC_v2_signes: {
      value: null,
  },
  uiC_v2_couleur: {
      value: null,
  },
  uiC_v2_modele: {
      value: null,
  },
  uiC_v2_plaque: {
      value: null,
  },
  uiC_v2_type: {
      value: null,
  },
  uiC_v1_signes: {
      value: null,
  },
  uiC_v1_couleur: {
      value: null,
  },
  uiC_v1_modele: {
      value: null,
  },
  uiC_v1_plaque: {
      value: null,
  },
  uiC_v1_type: {
      value: null,
  },
  uiC_p4_description: {
      value: null,
  },
  uiC_p4_matricule: {
      value: null,
  },
  uiC_p4_nom: {
      value: null,
  },
  uiC_p4_statut: {
      value: null,
  },
  uiC_p3_description: {
      value: null,
  },
  uiC_p3_matricule: {
      value: null,
  },
  uiC_p3_nom: {
      value: null,
  },
  uiC_p3_statut: {
      value: null,
  },
  uiC_p2_description: {
      value: null,
  },
  uiC_P2_matricule: {
      value: null,
  },
  uiC_P2_nom: {
      value: null,
  },
  uiC_P2_statut: {
      value: null,
  },
  uiC_P1_description: {
      value: null,
  },
  uiC_P1_matricule: {
      value: null,
  },
  uiC_P1_nom: {
      value: null,
  },
  uiC_P1_statut: {
      value: null,
  },
  uiC_Nbvehicules: {
      value: null,
  },
  uiC_nbPersonnes: {
      value: null,
  },
  uiC_DescriptionSituation: {
      value: null,
  },
  uiC_Check_autres: {
      value: null,
  },
  uiC_Check_Observation: {
      value: null,
  },
  uiC_Check_premierssoins: {
      value: null,
  },
  uiC_Check_Nuisible: {
      value: null,
  },
  uiC_Check_AppelRecu: {
      value: null,
  },
  uiC_Check_materielRecupere: {
      value: null,
  },
  uiC_Check_materieldonnee: {
      value: null,
  },
  uiC_Check_ramassagedechet: {
      value: null,
  },
  uiC_Check_InterventionCommercant: {
      value: null,
  },
  uiC_Check_ContactOrganisme: {
      value: null,
  },
  uiC_Check_contactmarginalisee: {
      value: null,
  },
  uiC_Check_ContactCitoyen: {
      value: null,
  },
  uiC_Check_911: {
      value: null,
  },
  uiC_Check_311: {
      value: null,
  },
  uiC_Check_Referencement: {
      value: null,
  },
  uiC_Secteur: {
      value: null,
  },
  uiC_Lieu: {
      value: null,
  },
  uiC_Temperature: {
      value: null,
  },
  uiC_Horaire: {
      value: null,
  },
  uiC_NomRemplacant: {
      value: null,
  },
  uiC_NomAgent2: {
      value: null,
  },
  uiC_NomAgent1: {
      value: null,
  },
  uiC_HeureIntervention: {
      value: null,
  },
  uiC_DateIntervention: {
      value: null,
  },
  uiVehicule2_signes: {
      value: null,
  },
  uiVehicule2_Couleur: {
      value: null,
  },
  uiVehicule2_modele: {
      value: null,
  },
  uiVehicule2_plaque: {
      value: null,
  },
  uiVehicule2_type: {
      value: null,
  },
  uiVehicule1_signes: {
      value: null,
  },
  uiVehicule1_Couleur: {
      value: null,
  },
  uiVehicule1_modele: {
      value: null,
  },
  uiVehicule1_plaque: {
      value: null,
  },
  uiVehicule1_type: {
      value: null,
  },
  uiPersonne4_description: {
      value: null,
  },
  uiPersonne4_matricule: {
      value: null,
  },
  uiPersonne4_nom: {
      value: null,
  },
  uiPersonne4_Statut: {
      value: null,
  },
  uiPersonne3_description: {
      value: null,
  },
  uiPersonne3_matricule: {
      value: null,
  },
  uiPersonne3_nom: {
      value: null,
  },
  uiPersonne3_Statut: {
      value: null,
  },
  uiPersonne2_description: {
      value: null,
  },
  uiPersonne2_matricule: {
      value: null,
  },
  uiPersonne2_nom: {
      value: null,
  },
  uiPersonne2_Statut: {
      value: null,
  },
  uiPersonne1_description: {
      value: null,
  },
  uiPersonne1_matricule: {
      value: null,
  },
  uiPersonne1_nom: {
      value: null,
  },
  uiPersonne1_Statut: {
      value: null,
  },
  uiNbVehicule: {
      value: null,
  },
  uiNbPersonnes: {
      value: null,
  },
  uiDescriptionIntervention: {
      value: null,
  },
  uiCheck_Autres: {
      value: null,
  },
  uiCheck_Observation: {
      value: null,
  },
  uiCheck_Premierssoins: {
      value: null,
  },
  uiCheck_InterventionNuisible: {
      value: null,
  },
  uiCheck_AppelRecu: {
      value: null,
  },
  uiCheck_MaterielRecupere: {
      value: null,
  },
  uiCheck_MaterielDonne: {
      value: null,
  },
  uiCheck_RamassageDechet: {
      value: null,
  },
  uiCheck_InterventionCommercant: {
      value: null,
  },
  uiCheck_ContactOrganisme: {
      value: null,
  },
  uiCheck_ContactMarginalisees: {
      value: null,
  },
  uiCheck_ContactCitoyens: {
      value: null,
  },
  uiCheck_Appel911: {
      value: null,
  },
  uiCheck_Appel311: {
      value: null,
  },
  uiCheck_Referencement: {
      value: null,
  },
  uiNumSecteur: {
      value: null,
  },
  uiLieuIntervention: {
      value: null,
  },
  uiTemperature: {
      value: null,
  },
  uiHoraire: {
      value: null,
  },
  uiNomAgentRemplacant: {
      value: null,
  },
  uiNomAgent2: {
      value: null,
  },
  uiNomAgent1: {
      value: null,
  },
  uiHeureIntervention: {
      value: null,
  },
  uiDateIntervention: {
      value: null,
  },
  uiTableInterventions: {
      deletedRow: null,
      editedRow: null,
      newRow: null,
      selectedRow: null,
      selectedRowIndex: null,
  },
  uiSmartTableIA_Inter: {
      deletedRow: null,
      editedRow: null,
      newRow: null,
      selectedRow: null,
      selectedRowIndex: null,
  },
  uiTextArea7: {
      value: null,
  },
  uiTextArea6: {
      value: null,
  },
  uiTextArea5: {
      value: null,
  },
  uiTextArea42: {
      value: null,
  },
  uiTextArea41: {
      value: null,
  },
  uiTextArea4: {
      value: null,
  },
  uiTextArea3: {
      value: null,
  },
  uiTextArea2: {
      value: null,
  },
  uiTextArea1: {
      value: null,
  },
  uiTextArea: {
      value: null,
  },
  uiTabs: {
      selectedIndex: null,
      length: null,
  },
  uiSmartTable: {
      deletedRow: null,
      editedRow: null,
      newRow: null,
      selectedRow: null,
      selectedRowIndex: null,
  },
  uiTabs2: {
      selectedIndex: null,
      length: null,
  },
  uiSecret: {
      value: null,
  },
  uiUsername: {
      value: null,
  },
  stateAMoutofINTCONCAT: 0,
  stateListconcat: [],
  stateListinc2: [],
  stateTempSelectedIntervention: '',
  stateListInterventions: [],
  stateAmountofInterventions: 0,
  stateEventID_vehicule2_signes: '',
  stateEventID_vehicule2_couleur: '',
  stateEventID_vehicule2_modele: '',
  stateEventID_vehicule2_plaque: '',
  stateEventID_vehicule2_type: '',
  stateEventID_vehicule1_signes: '',
  stateEventID_vehicule1_couleur: '',
  stateEventID_vehicule1_modele: '',
  stateEventID_vehicule1_plaque: '',
  stateEventID_vehicule1_type: '',
  stateEventID_personne4_description: '',
  stateEventID_personne4_matricule: '',
  stateEventID_personne4_nom: '',
  stateEventID_personne4_statut: '',
  stateEventID_personne3_description: '',
  stateEventID_personne3_matricule: '',
  stateEventID_personne3_nom: '',
  stateEventID_personne3_statut: '',
  stateEventID_personne2_description: '',
  stateEventID_personne2_matricule: '',
  stateEventID_personne2_nom: '',
  stateEventID_personne2_statut: '',
  stateEventID_personne1_description: '',
  stateEventID_personne1_matricule: '',
  stateEventID_personne1_nom: '',
  stateEventID_personne1_statut: '',
  stateEventID_nbvehicules: '',
  stateEventID_nbpersonnes: '',
  stateEventID_description_situation: '',
  stateEventID_nature_autres: false,
  stateEventID_nature_observation: false,
  stateEventID_nature_premiersoins: false,
  stateEventID_nature_nuisible: false,
  stateEventID_nature_appelrecu: false,
  stateEventID_nature_materiel_recupere: false,
  stateEventID_nature_materiel_donne: false,
  stateEventID_nature_dechet: false,
  stateEventID_nature_intervention: false,
  stateEventID_nature_contact_organisme: false,
  stateEventID_nature_contact_population: false,
  stateEventID_nature_contact_citoyen: false,
  stateEventID_nature_911: false,
  stateEventID_nature_311: false,
  stateEventID_nature_referencement: false,
  stateEventID_secteur: '',
  stateEventID_lieu: '',
  stateEventID_temperature: '',
  stateEventID_horaire: '',
  stateEventID_agentremplacant: '',
  stateEventID_agent2: '',
  stateEventID_agent1: '',
  stateEventID_heureintervention: '',
  stateEventID_dateutc: '',
  stateEventID_dateintervention: '',
  stateInterventions_personne4_desc: '',
  stateInterventions_personne3_desc: '',
  stateInterventions_personne2_desc: '',
  stateInterventions_personne1_desc: '',
  stateDateTest: '',
  stateTEMP_PAYLOAD: '',
  stateTEMP_PRINTINTERVENTION_ID: '',
  stateTEMP_PRINTINTERVENTION_URL: '',
  stateTEMP_PRINTINTERVENTION: {},
  stateAVIS_Statut: false,
  stateAVIS_Titre: '',
  stateAVIS_Message: '',
  stateDataFromEventID_dateutc: '',
  stateDataFromIntervention_dateinterventionUTC: '',
  stateDash_inc_apr: 0,
  stateDash_inc_mar: 0,
  stateDash_inc_feb: 0,
  stateDash_Inc_Jan: 0,
  stateDash_Int_Apr: 0,
  stateDash_int_Mar: 0,
  stateDash_int_Feb: 0,
  stateDash_Int_Jan: 0,
  stateB64F1: {},
  stateIncidents_convert_date: '',
  stateIncidents_convert_individu6: '',
  stateIncidents_convert_individu5: '',
  stateIncidents_convert_individu4: '',
  stateIncidents_convert_individu3: '',
  stateIncidents_convert_individu2: '',
  stateIncidents_convert_individu1: '',
  stateIncidents_convert_description: '',
  stateDateConvert: '',
  stateInterventions_convert_resolution: '',
  stateInterventions_convert_description: '',
  stateInterventions_convert_comments: '',
  stateUsernameAPI: '',
  stateSubmissionDate: '',
  stateIntIDIntervention: 0,
  stateDataFromIntervention_dateintervention: '',
  stateDataFromIntervention_agent2: '',
  stateDataFromIntervention_agent1: '',
  stateDataFromIntervention_comments: '',
  stateDataFromIntervention_description: '',
  stateDataFromIntervention_resolution_detail: '',
  stateDataFromIntervention_type: '',
  stateDataFromIntervention_heure: '',
  stateDataFromIntervention_secteur: '',
  stateDataFromIntervention_lieu: '',
  stateDataFromIntervention_temps: '',
  stateDataFromIntervention_horaire: '',
  stateTempSelectedIDIntervention: '',
  stateAmoutofINC: '',
  stateListinc: [],
  stateBDExtPWD: [],
  stateValidtySessionToken: '',
  stateMD5Users: '',
  stateDataFromEventID_instance_suivi_num: '',
  stateDataFromEventID_instance_suivi: '',
  stateDataFromEventID_instance_heure: '',
  stateDataFromEventID_instance_tel: '',
  stateDataFromEventID_instance_nom_contact: '',
  stateDataFromEventID_instance_nom: '',
  stateDataFromEventID_individu6: '',
  stateDataFromEventID_individu5: '',
  stateDataFromEventID_individu4: '',
  stateDataFromEventID_individu3: '',
  stateDataFromEventID_individu2: '',
  stateDataFromEventID_individu1: '',
  stateDataFromEventID_deroulement: '',
  stateDataFromEventID_dateincident: '',
  stateDataFromEventID_heureincident: '',
  stateDataFromEventID_natureincident: '',
  stateDataFromEventID_transversale: '',
  stateDataFromEventID_Adresse: '',
  stateUsers: [],
  stateTempSelectedIDEvent: 0,
  stateGetDataFromEventID: [],
  stateAmoutofEvent: '',
  stateIntIDEvent: 0,
  stateUsers2: {},
  stateListevenement: [],
  stateAIR_TABLE_TOKEN: 'keyN2bJNIXYeDCU9t',
  stateLoginErrorMessage: '',
};

export const appReducer = createReducer(
  initialState,
  on(AppActions.updateUiSUpportMesage, (state: State, changes) => ({ ...state, uiSUpportMesage: { ...state.uiSUpportMesage, ...changes.param }})),
  on(AppActions.updateUiSupportURGENT, (state: State, changes) => ({ ...state, uiSupportURGENT: { ...state.uiSupportURGENT, ...changes.param }})),
  on(AppActions.updateUiNomAgentSupport, (state: State, changes) => ({ ...state, uiNomAgentSupport: { ...state.uiNomAgentSupport, ...changes.param }})),
  on(AppActions.updateUiCV2Signes, (state: State, changes) => ({ ...state, uiC_v2_signes: { ...state.uiC_v2_signes, ...changes.param }})),
  on(AppActions.updateUiCV2Couleur, (state: State, changes) => ({ ...state, uiC_v2_couleur: { ...state.uiC_v2_couleur, ...changes.param }})),
  on(AppActions.updateUiCV2Modele, (state: State, changes) => ({ ...state, uiC_v2_modele: { ...state.uiC_v2_modele, ...changes.param }})),
  on(AppActions.updateUiCV2Plaque, (state: State, changes) => ({ ...state, uiC_v2_plaque: { ...state.uiC_v2_plaque, ...changes.param }})),
  on(AppActions.updateUiCV2Type, (state: State, changes) => ({ ...state, uiC_v2_type: { ...state.uiC_v2_type, ...changes.param }})),
  on(AppActions.updateUiCV1Signes, (state: State, changes) => ({ ...state, uiC_v1_signes: { ...state.uiC_v1_signes, ...changes.param }})),
  on(AppActions.updateUiCV1Couleur, (state: State, changes) => ({ ...state, uiC_v1_couleur: { ...state.uiC_v1_couleur, ...changes.param }})),
  on(AppActions.updateUiCV1Modele, (state: State, changes) => ({ ...state, uiC_v1_modele: { ...state.uiC_v1_modele, ...changes.param }})),
  on(AppActions.updateUiCV1Plaque, (state: State, changes) => ({ ...state, uiC_v1_plaque: { ...state.uiC_v1_plaque, ...changes.param }})),
  on(AppActions.updateUiCV1Type, (state: State, changes) => ({ ...state, uiC_v1_type: { ...state.uiC_v1_type, ...changes.param }})),
  on(AppActions.updateUiCP4Description, (state: State, changes) => ({ ...state, uiC_p4_description: { ...state.uiC_p4_description, ...changes.param }})),
  on(AppActions.updateUiCP4Matricule, (state: State, changes) => ({ ...state, uiC_p4_matricule: { ...state.uiC_p4_matricule, ...changes.param }})),
  on(AppActions.updateUiCP4Nom, (state: State, changes) => ({ ...state, uiC_p4_nom: { ...state.uiC_p4_nom, ...changes.param }})),
  on(AppActions.updateUiCP4Statut, (state: State, changes) => ({ ...state, uiC_p4_statut: { ...state.uiC_p4_statut, ...changes.param }})),
  on(AppActions.updateUiCP3Description, (state: State, changes) => ({ ...state, uiC_p3_description: { ...state.uiC_p3_description, ...changes.param }})),
  on(AppActions.updateUiCP3Matricule, (state: State, changes) => ({ ...state, uiC_p3_matricule: { ...state.uiC_p3_matricule, ...changes.param }})),
  on(AppActions.updateUiCP3Nom, (state: State, changes) => ({ ...state, uiC_p3_nom: { ...state.uiC_p3_nom, ...changes.param }})),
  on(AppActions.updateUiCP3Statut, (state: State, changes) => ({ ...state, uiC_p3_statut: { ...state.uiC_p3_statut, ...changes.param }})),
  on(AppActions.updateUiCP2Description, (state: State, changes) => ({ ...state, uiC_p2_description: { ...state.uiC_p2_description, ...changes.param }})),
  on(AppActions.updateUiCP2Matricule, (state: State, changes) => ({ ...state, uiC_P2_matricule: { ...state.uiC_P2_matricule, ...changes.param }})),
  on(AppActions.updateUiCP2Nom, (state: State, changes) => ({ ...state, uiC_P2_nom: { ...state.uiC_P2_nom, ...changes.param }})),
  on(AppActions.updateUiCP2Statut, (state: State, changes) => ({ ...state, uiC_P2_statut: { ...state.uiC_P2_statut, ...changes.param }})),
  on(AppActions.updateUiCP1Description, (state: State, changes) => ({ ...state, uiC_P1_description: { ...state.uiC_P1_description, ...changes.param }})),
  on(AppActions.updateUiCP1Matricule, (state: State, changes) => ({ ...state, uiC_P1_matricule: { ...state.uiC_P1_matricule, ...changes.param }})),
  on(AppActions.updateUiCP1Nom, (state: State, changes) => ({ ...state, uiC_P1_nom: { ...state.uiC_P1_nom, ...changes.param }})),
  on(AppActions.updateUiCP1Statut, (state: State, changes) => ({ ...state, uiC_P1_statut: { ...state.uiC_P1_statut, ...changes.param }})),
  on(AppActions.updateUiCNbvehicules, (state: State, changes) => ({ ...state, uiC_Nbvehicules: { ...state.uiC_Nbvehicules, ...changes.param }})),
  on(AppActions.updateUiCNbPersonnes, (state: State, changes) => ({ ...state, uiC_nbPersonnes: { ...state.uiC_nbPersonnes, ...changes.param }})),
  on(AppActions.updateUiCDescriptionSituation, (state: State, changes) => ({ ...state, uiC_DescriptionSituation: { ...state.uiC_DescriptionSituation, ...changes.param }})),
  on(AppActions.updateUiCCheckAutres, (state: State, changes) => ({ ...state, uiC_Check_autres: { ...state.uiC_Check_autres, ...changes.param }})),
  on(AppActions.updateUiCCheckObservation, (state: State, changes) => ({ ...state, uiC_Check_Observation: { ...state.uiC_Check_Observation, ...changes.param }})),
  on(AppActions.updateUiCCheckPremierssoins, (state: State, changes) => ({ ...state, uiC_Check_premierssoins: { ...state.uiC_Check_premierssoins, ...changes.param }})),
  on(AppActions.updateUiCCheckNuisible, (state: State, changes) => ({ ...state, uiC_Check_Nuisible: { ...state.uiC_Check_Nuisible, ...changes.param }})),
  on(AppActions.updateUiCCheckAppelRecu, (state: State, changes) => ({ ...state, uiC_Check_AppelRecu: { ...state.uiC_Check_AppelRecu, ...changes.param }})),
  on(AppActions.updateUiCCheckMaterielRecupere, (state: State, changes) => ({ ...state, uiC_Check_materielRecupere: { ...state.uiC_Check_materielRecupere, ...changes.param }})),
  on(AppActions.updateUiCCheckMaterieldonnee, (state: State, changes) => ({ ...state, uiC_Check_materieldonnee: { ...state.uiC_Check_materieldonnee, ...changes.param }})),
  on(AppActions.updateUiCCheckRamassagedechet, (state: State, changes) => ({ ...state, uiC_Check_ramassagedechet: { ...state.uiC_Check_ramassagedechet, ...changes.param }})),
  on(AppActions.updateUiCCheckInterventionCommercant, (state: State, changes) => ({ ...state, uiC_Check_InterventionCommercant: { ...state.uiC_Check_InterventionCommercant, ...changes.param }})),
  on(AppActions.updateUiCCheckContactOrganisme, (state: State, changes) => ({ ...state, uiC_Check_ContactOrganisme: { ...state.uiC_Check_ContactOrganisme, ...changes.param }})),
  on(AppActions.updateUiCCheckContactmarginalisee, (state: State, changes) => ({ ...state, uiC_Check_contactmarginalisee: { ...state.uiC_Check_contactmarginalisee, ...changes.param }})),
  on(AppActions.updateUiCCheckContactCitoyen, (state: State, changes) => ({ ...state, uiC_Check_ContactCitoyen: { ...state.uiC_Check_ContactCitoyen, ...changes.param }})),
  on(AppActions.updateUiCCheck911, (state: State, changes) => ({ ...state, uiC_Check_911: { ...state.uiC_Check_911, ...changes.param }})),
  on(AppActions.updateUiCCheck311, (state: State, changes) => ({ ...state, uiC_Check_311: { ...state.uiC_Check_311, ...changes.param }})),
  on(AppActions.updateUiCCheckReferencement, (state: State, changes) => ({ ...state, uiC_Check_Referencement: { ...state.uiC_Check_Referencement, ...changes.param }})),
  on(AppActions.updateUiCSecteur, (state: State, changes) => ({ ...state, uiC_Secteur: { ...state.uiC_Secteur, ...changes.param }})),
  on(AppActions.updateUiCLieu, (state: State, changes) => ({ ...state, uiC_Lieu: { ...state.uiC_Lieu, ...changes.param }})),
  on(AppActions.updateUiCTemperature, (state: State, changes) => ({ ...state, uiC_Temperature: { ...state.uiC_Temperature, ...changes.param }})),
  on(AppActions.updateUiCHoraire, (state: State, changes) => ({ ...state, uiC_Horaire: { ...state.uiC_Horaire, ...changes.param }})),
  on(AppActions.updateUiCNomRemplacant, (state: State, changes) => ({ ...state, uiC_NomRemplacant: { ...state.uiC_NomRemplacant, ...changes.param }})),
  on(AppActions.updateUiCNomAgent2, (state: State, changes) => ({ ...state, uiC_NomAgent2: { ...state.uiC_NomAgent2, ...changes.param }})),
  on(AppActions.updateUiCNomAgent1, (state: State, changes) => ({ ...state, uiC_NomAgent1: { ...state.uiC_NomAgent1, ...changes.param }})),
  on(AppActions.updateUiCHeureIntervention, (state: State, changes) => ({ ...state, uiC_HeureIntervention: { ...state.uiC_HeureIntervention, ...changes.param }})),
  on(AppActions.updateUiCDateIntervention, (state: State, changes) => ({ ...state, uiC_DateIntervention: { ...state.uiC_DateIntervention, ...changes.param }})),
  on(AppActions.updateUiVehicule2Signes, (state: State, changes) => ({ ...state, uiVehicule2_signes: { ...state.uiVehicule2_signes, ...changes.param }})),
  on(AppActions.updateUiVehicule2Couleur, (state: State, changes) => ({ ...state, uiVehicule2_Couleur: { ...state.uiVehicule2_Couleur, ...changes.param }})),
  on(AppActions.updateUiVehicule2Modele, (state: State, changes) => ({ ...state, uiVehicule2_modele: { ...state.uiVehicule2_modele, ...changes.param }})),
  on(AppActions.updateUiVehicule2Plaque, (state: State, changes) => ({ ...state, uiVehicule2_plaque: { ...state.uiVehicule2_plaque, ...changes.param }})),
  on(AppActions.updateUiVehicule2Type, (state: State, changes) => ({ ...state, uiVehicule2_type: { ...state.uiVehicule2_type, ...changes.param }})),
  on(AppActions.updateUiVehicule1Signes, (state: State, changes) => ({ ...state, uiVehicule1_signes: { ...state.uiVehicule1_signes, ...changes.param }})),
  on(AppActions.updateUiVehicule1Couleur, (state: State, changes) => ({ ...state, uiVehicule1_Couleur: { ...state.uiVehicule1_Couleur, ...changes.param }})),
  on(AppActions.updateUiVehicule1Modele, (state: State, changes) => ({ ...state, uiVehicule1_modele: { ...state.uiVehicule1_modele, ...changes.param }})),
  on(AppActions.updateUiVehicule1Plaque, (state: State, changes) => ({ ...state, uiVehicule1_plaque: { ...state.uiVehicule1_plaque, ...changes.param }})),
  on(AppActions.updateUiVehicule1Type, (state: State, changes) => ({ ...state, uiVehicule1_type: { ...state.uiVehicule1_type, ...changes.param }})),
  on(AppActions.updateUiPersonne4Description, (state: State, changes) => ({ ...state, uiPersonne4_description: { ...state.uiPersonne4_description, ...changes.param }})),
  on(AppActions.updateUiPersonne4Matricule, (state: State, changes) => ({ ...state, uiPersonne4_matricule: { ...state.uiPersonne4_matricule, ...changes.param }})),
  on(AppActions.updateUiPersonne4Nom, (state: State, changes) => ({ ...state, uiPersonne4_nom: { ...state.uiPersonne4_nom, ...changes.param }})),
  on(AppActions.updateUiPersonne4Statut, (state: State, changes) => ({ ...state, uiPersonne4_Statut: { ...state.uiPersonne4_Statut, ...changes.param }})),
  on(AppActions.updateUiPersonne3Description, (state: State, changes) => ({ ...state, uiPersonne3_description: { ...state.uiPersonne3_description, ...changes.param }})),
  on(AppActions.updateUiPersonne3Matricule, (state: State, changes) => ({ ...state, uiPersonne3_matricule: { ...state.uiPersonne3_matricule, ...changes.param }})),
  on(AppActions.updateUiPersonne3Nom, (state: State, changes) => ({ ...state, uiPersonne3_nom: { ...state.uiPersonne3_nom, ...changes.param }})),
  on(AppActions.updateUiPersonne3Statut, (state: State, changes) => ({ ...state, uiPersonne3_Statut: { ...state.uiPersonne3_Statut, ...changes.param }})),
  on(AppActions.updateUiPersonne2Description, (state: State, changes) => ({ ...state, uiPersonne2_description: { ...state.uiPersonne2_description, ...changes.param }})),
  on(AppActions.updateUiPersonne2Matricule, (state: State, changes) => ({ ...state, uiPersonne2_matricule: { ...state.uiPersonne2_matricule, ...changes.param }})),
  on(AppActions.updateUiPersonne2Nom, (state: State, changes) => ({ ...state, uiPersonne2_nom: { ...state.uiPersonne2_nom, ...changes.param }})),
  on(AppActions.updateUiPersonne2Statut, (state: State, changes) => ({ ...state, uiPersonne2_Statut: { ...state.uiPersonne2_Statut, ...changes.param }})),
  on(AppActions.updateUiPersonne1Description, (state: State, changes) => ({ ...state, uiPersonne1_description: { ...state.uiPersonne1_description, ...changes.param }})),
  on(AppActions.updateUiPersonne1Matricule, (state: State, changes) => ({ ...state, uiPersonne1_matricule: { ...state.uiPersonne1_matricule, ...changes.param }})),
  on(AppActions.updateUiPersonne1Nom, (state: State, changes) => ({ ...state, uiPersonne1_nom: { ...state.uiPersonne1_nom, ...changes.param }})),
  on(AppActions.updateUiPersonne1Statut, (state: State, changes) => ({ ...state, uiPersonne1_Statut: { ...state.uiPersonne1_Statut, ...changes.param }})),
  on(AppActions.updateUiNbVehicule, (state: State, changes) => ({ ...state, uiNbVehicule: { ...state.uiNbVehicule, ...changes.param }})),
  on(AppActions.updateUiNbPersonnes, (state: State, changes) => ({ ...state, uiNbPersonnes: { ...state.uiNbPersonnes, ...changes.param }})),
  on(AppActions.updateUiDescriptionIntervention, (state: State, changes) => ({ ...state, uiDescriptionIntervention: { ...state.uiDescriptionIntervention, ...changes.param }})),
  on(AppActions.updateUiCheckAutres, (state: State, changes) => ({ ...state, uiCheck_Autres: { ...state.uiCheck_Autres, ...changes.param }})),
  on(AppActions.updateUiCheckObservation, (state: State, changes) => ({ ...state, uiCheck_Observation: { ...state.uiCheck_Observation, ...changes.param }})),
  on(AppActions.updateUiCheckPremierssoins, (state: State, changes) => ({ ...state, uiCheck_Premierssoins: { ...state.uiCheck_Premierssoins, ...changes.param }})),
  on(AppActions.updateUiCheckInterventionNuisible, (state: State, changes) => ({ ...state, uiCheck_InterventionNuisible: { ...state.uiCheck_InterventionNuisible, ...changes.param }})),
  on(AppActions.updateUiCheckAppelRecu, (state: State, changes) => ({ ...state, uiCheck_AppelRecu: { ...state.uiCheck_AppelRecu, ...changes.param }})),
  on(AppActions.updateUiCheckMaterielRecupere, (state: State, changes) => ({ ...state, uiCheck_MaterielRecupere: { ...state.uiCheck_MaterielRecupere, ...changes.param }})),
  on(AppActions.updateUiCheckMaterielDonne, (state: State, changes) => ({ ...state, uiCheck_MaterielDonne: { ...state.uiCheck_MaterielDonne, ...changes.param }})),
  on(AppActions.updateUiCheckRamassageDechet, (state: State, changes) => ({ ...state, uiCheck_RamassageDechet: { ...state.uiCheck_RamassageDechet, ...changes.param }})),
  on(AppActions.updateUiCheckInterventionCommercant, (state: State, changes) => ({ ...state, uiCheck_InterventionCommercant: { ...state.uiCheck_InterventionCommercant, ...changes.param }})),
  on(AppActions.updateUiCheckContactOrganisme, (state: State, changes) => ({ ...state, uiCheck_ContactOrganisme: { ...state.uiCheck_ContactOrganisme, ...changes.param }})),
  on(AppActions.updateUiCheckContactMarginalisees, (state: State, changes) => ({ ...state, uiCheck_ContactMarginalisees: { ...state.uiCheck_ContactMarginalisees, ...changes.param }})),
  on(AppActions.updateUiCheckContactCitoyens, (state: State, changes) => ({ ...state, uiCheck_ContactCitoyens: { ...state.uiCheck_ContactCitoyens, ...changes.param }})),
  on(AppActions.updateUiCheckAppel911, (state: State, changes) => ({ ...state, uiCheck_Appel911: { ...state.uiCheck_Appel911, ...changes.param }})),
  on(AppActions.updateUiCheckAppel311, (state: State, changes) => ({ ...state, uiCheck_Appel311: { ...state.uiCheck_Appel311, ...changes.param }})),
  on(AppActions.updateUiCheckReferencement, (state: State, changes) => ({ ...state, uiCheck_Referencement: { ...state.uiCheck_Referencement, ...changes.param }})),
  on(AppActions.updateUiNumSecteur, (state: State, changes) => ({ ...state, uiNumSecteur: { ...state.uiNumSecteur, ...changes.param }})),
  on(AppActions.updateUiLieuIntervention, (state: State, changes) => ({ ...state, uiLieuIntervention: { ...state.uiLieuIntervention, ...changes.param }})),
  on(AppActions.updateUiTemperature, (state: State, changes) => ({ ...state, uiTemperature: { ...state.uiTemperature, ...changes.param }})),
  on(AppActions.updateUiHoraire, (state: State, changes) => ({ ...state, uiHoraire: { ...state.uiHoraire, ...changes.param }})),
  on(AppActions.updateUiNomAgentRemplacant, (state: State, changes) => ({ ...state, uiNomAgentRemplacant: { ...state.uiNomAgentRemplacant, ...changes.param }})),
  on(AppActions.updateUiNomAgent2, (state: State, changes) => ({ ...state, uiNomAgent2: { ...state.uiNomAgent2, ...changes.param }})),
  on(AppActions.updateUiNomAgent1, (state: State, changes) => ({ ...state, uiNomAgent1: { ...state.uiNomAgent1, ...changes.param }})),
  on(AppActions.updateUiHeureIntervention, (state: State, changes) => ({ ...state, uiHeureIntervention: { ...state.uiHeureIntervention, ...changes.param }})),
  on(AppActions.updateUiDateIntervention, (state: State, changes) => ({ ...state, uiDateIntervention: { ...state.uiDateIntervention, ...changes.param }})),
  on(AppActions.updateUiTableInterventions, (state: State, changes) => ({ ...state, uiTableInterventions: { ...state.uiTableInterventions, ...changes.param }})),
  on(AppActions.updateUiSmartTableIAInter, (state: State, changes) => ({ ...state, uiSmartTableIA_Inter: { ...state.uiSmartTableIA_Inter, ...changes.param }})),
  on(AppActions.updateUiTextArea7, (state: State, changes) => ({ ...state, uiTextArea7: { ...state.uiTextArea7, ...changes.param }})),
  on(AppActions.updateUiTextArea6, (state: State, changes) => ({ ...state, uiTextArea6: { ...state.uiTextArea6, ...changes.param }})),
  on(AppActions.updateUiTextArea5, (state: State, changes) => ({ ...state, uiTextArea5: { ...state.uiTextArea5, ...changes.param }})),
  on(AppActions.updateUiTextArea42, (state: State, changes) => ({ ...state, uiTextArea42: { ...state.uiTextArea42, ...changes.param }})),
  on(AppActions.updateUiTextArea41, (state: State, changes) => ({ ...state, uiTextArea41: { ...state.uiTextArea41, ...changes.param }})),
  on(AppActions.updateUiTextArea4, (state: State, changes) => ({ ...state, uiTextArea4: { ...state.uiTextArea4, ...changes.param }})),
  on(AppActions.updateUiTextArea3, (state: State, changes) => ({ ...state, uiTextArea3: { ...state.uiTextArea3, ...changes.param }})),
  on(AppActions.updateUiTextArea2, (state: State, changes) => ({ ...state, uiTextArea2: { ...state.uiTextArea2, ...changes.param }})),
  on(AppActions.updateUiTextArea1, (state: State, changes) => ({ ...state, uiTextArea1: { ...state.uiTextArea1, ...changes.param }})),
  on(AppActions.updateUiTextArea, (state: State, changes) => ({ ...state, uiTextArea: { ...state.uiTextArea, ...changes.param }})),
  on(AppActions.updateUiTabs, (state: State, changes) => ({ ...state, uiTabs: { ...state.uiTabs, ...changes.param }})),
  on(AppActions.updateUiSmartTable, (state: State, changes) => ({ ...state, uiSmartTable: { ...state.uiSmartTable, ...changes.param }})),
  on(AppActions.updateUiTabs2, (state: State, changes) => ({ ...state, uiTabs2: { ...state.uiTabs2, ...changes.param }})),
  on(AppActions.updateUiSecret, (state: State, changes) => ({ ...state, uiSecret: { ...state.uiSecret, ...changes.param }})),
  on(AppActions.updateUiUsername, (state: State, changes) => ({ ...state, uiUsername: { ...state.uiUsername, ...changes.param }})),
  on(AppActions.updateStateAMoutofINTCONCAT, (state: State, { stateAMoutofINTCONCAT }) => ({ ...state, stateAMoutofINTCONCAT })),
  on(AppActions.updateStateListconcat, (state: State, { stateListconcat }) => ({ ...state, stateListconcat })),
  on(AppActions.updateStateListinc2, (state: State, { stateListinc2 }) => ({ ...state, stateListinc2 })),
  on(AppActions.updateStateTempSelectedIntervention, (state: State, { stateTempSelectedIntervention }) => ({ ...state, stateTempSelectedIntervention })),
  on(AppActions.updateStateListInterventions, (state: State, { stateListInterventions }) => ({ ...state, stateListInterventions })),
  on(AppActions.updateStateAmountofInterventions, (state: State, { stateAmountofInterventions }) => ({ ...state, stateAmountofInterventions })),
  on(AppActions.updateStateEventIDVehicule2Signes, (state: State, { stateEventID_vehicule2_signes }) => ({ ...state, stateEventID_vehicule2_signes })),
  on(AppActions.updateStateEventIDVehicule2Couleur, (state: State, { stateEventID_vehicule2_couleur }) => ({ ...state, stateEventID_vehicule2_couleur })),
  on(AppActions.updateStateEventIDVehicule2Modele, (state: State, { stateEventID_vehicule2_modele }) => ({ ...state, stateEventID_vehicule2_modele })),
  on(AppActions.updateStateEventIDVehicule2Plaque, (state: State, { stateEventID_vehicule2_plaque }) => ({ ...state, stateEventID_vehicule2_plaque })),
  on(AppActions.updateStateEventIDVehicule2Type, (state: State, { stateEventID_vehicule2_type }) => ({ ...state, stateEventID_vehicule2_type })),
  on(AppActions.updateStateEventIDVehicule1Signes, (state: State, { stateEventID_vehicule1_signes }) => ({ ...state, stateEventID_vehicule1_signes })),
  on(AppActions.updateStateEventIDVehicule1Couleur, (state: State, { stateEventID_vehicule1_couleur }) => ({ ...state, stateEventID_vehicule1_couleur })),
  on(AppActions.updateStateEventIDVehicule1Modele, (state: State, { stateEventID_vehicule1_modele }) => ({ ...state, stateEventID_vehicule1_modele })),
  on(AppActions.updateStateEventIDVehicule1Plaque, (state: State, { stateEventID_vehicule1_plaque }) => ({ ...state, stateEventID_vehicule1_plaque })),
  on(AppActions.updateStateEventIDVehicule1Type, (state: State, { stateEventID_vehicule1_type }) => ({ ...state, stateEventID_vehicule1_type })),
  on(AppActions.updateStateEventIDPersonne4Description, (state: State, { stateEventID_personne4_description }) => ({ ...state, stateEventID_personne4_description })),
  on(AppActions.updateStateEventIDPersonne4Matricule, (state: State, { stateEventID_personne4_matricule }) => ({ ...state, stateEventID_personne4_matricule })),
  on(AppActions.updateStateEventIDPersonne4Nom, (state: State, { stateEventID_personne4_nom }) => ({ ...state, stateEventID_personne4_nom })),
  on(AppActions.updateStateEventIDPersonne4Statut, (state: State, { stateEventID_personne4_statut }) => ({ ...state, stateEventID_personne4_statut })),
  on(AppActions.updateStateEventIDPersonne3Description, (state: State, { stateEventID_personne3_description }) => ({ ...state, stateEventID_personne3_description })),
  on(AppActions.updateStateEventIDPersonne3Matricule, (state: State, { stateEventID_personne3_matricule }) => ({ ...state, stateEventID_personne3_matricule })),
  on(AppActions.updateStateEventIDPersonne3Nom, (state: State, { stateEventID_personne3_nom }) => ({ ...state, stateEventID_personne3_nom })),
  on(AppActions.updateStateEventIDPersonne3Statut, (state: State, { stateEventID_personne3_statut }) => ({ ...state, stateEventID_personne3_statut })),
  on(AppActions.updateStateEventIDPersonne2Description, (state: State, { stateEventID_personne2_description }) => ({ ...state, stateEventID_personne2_description })),
  on(AppActions.updateStateEventIDPersonne2Matricule, (state: State, { stateEventID_personne2_matricule }) => ({ ...state, stateEventID_personne2_matricule })),
  on(AppActions.updateStateEventIDPersonne2Nom, (state: State, { stateEventID_personne2_nom }) => ({ ...state, stateEventID_personne2_nom })),
  on(AppActions.updateStateEventIDPersonne2Statut, (state: State, { stateEventID_personne2_statut }) => ({ ...state, stateEventID_personne2_statut })),
  on(AppActions.updateStateEventIDPersonne1Description, (state: State, { stateEventID_personne1_description }) => ({ ...state, stateEventID_personne1_description })),
  on(AppActions.updateStateEventIDPersonne1Matricule, (state: State, { stateEventID_personne1_matricule }) => ({ ...state, stateEventID_personne1_matricule })),
  on(AppActions.updateStateEventIDPersonne1Nom, (state: State, { stateEventID_personne1_nom }) => ({ ...state, stateEventID_personne1_nom })),
  on(AppActions.updateStateEventIDPersonne1Statut, (state: State, { stateEventID_personne1_statut }) => ({ ...state, stateEventID_personne1_statut })),
  on(AppActions.updateStateEventIDNbvehicules, (state: State, { stateEventID_nbvehicules }) => ({ ...state, stateEventID_nbvehicules })),
  on(AppActions.updateStateEventIDNbpersonnes, (state: State, { stateEventID_nbpersonnes }) => ({ ...state, stateEventID_nbpersonnes })),
  on(AppActions.updateStateEventIDDescriptionSituation, (state: State, { stateEventID_description_situation }) => ({ ...state, stateEventID_description_situation })),
  on(AppActions.updateStateEventIDNatureAutres, (state: State, { stateEventID_nature_autres }) => ({ ...state, stateEventID_nature_autres })),
  on(AppActions.updateStateEventIDNatureObservation, (state: State, { stateEventID_nature_observation }) => ({ ...state, stateEventID_nature_observation })),
  on(AppActions.updateStateEventIDNaturePremiersoins, (state: State, { stateEventID_nature_premiersoins }) => ({ ...state, stateEventID_nature_premiersoins })),
  on(AppActions.updateStateEventIDNatureNuisible, (state: State, { stateEventID_nature_nuisible }) => ({ ...state, stateEventID_nature_nuisible })),
  on(AppActions.updateStateEventIDNatureAppelrecu, (state: State, { stateEventID_nature_appelrecu }) => ({ ...state, stateEventID_nature_appelrecu })),
  on(AppActions.updateStateEventIDNatureMaterielRecupere, (state: State, { stateEventID_nature_materiel_recupere }) => ({ ...state, stateEventID_nature_materiel_recupere })),
  on(AppActions.updateStateEventIDNatureMaterielDonne, (state: State, { stateEventID_nature_materiel_donne }) => ({ ...state, stateEventID_nature_materiel_donne })),
  on(AppActions.updateStateEventIDNatureDechet, (state: State, { stateEventID_nature_dechet }) => ({ ...state, stateEventID_nature_dechet })),
  on(AppActions.updateStateEventIDNatureIntervention, (state: State, { stateEventID_nature_intervention }) => ({ ...state, stateEventID_nature_intervention })),
  on(AppActions.updateStateEventIDNatureContactOrganisme, (state: State, { stateEventID_nature_contact_organisme }) => ({ ...state, stateEventID_nature_contact_organisme })),
  on(AppActions.updateStateEventIDNatureContactPopulation, (state: State, { stateEventID_nature_contact_population }) => ({ ...state, stateEventID_nature_contact_population })),
  on(AppActions.updateStateEventIDNatureContactCitoyen, (state: State, { stateEventID_nature_contact_citoyen }) => ({ ...state, stateEventID_nature_contact_citoyen })),
  on(AppActions.updateStateEventIDNature911, (state: State, { stateEventID_nature_911 }) => ({ ...state, stateEventID_nature_911 })),
  on(AppActions.updateStateEventIDNature311, (state: State, { stateEventID_nature_311 }) => ({ ...state, stateEventID_nature_311 })),
  on(AppActions.updateStateEventIDNatureReferencement, (state: State, { stateEventID_nature_referencement }) => ({ ...state, stateEventID_nature_referencement })),
  on(AppActions.updateStateEventIDSecteur, (state: State, { stateEventID_secteur }) => ({ ...state, stateEventID_secteur })),
  on(AppActions.updateStateEventIDLieu, (state: State, { stateEventID_lieu }) => ({ ...state, stateEventID_lieu })),
  on(AppActions.updateStateEventIDTemperature, (state: State, { stateEventID_temperature }) => ({ ...state, stateEventID_temperature })),
  on(AppActions.updateStateEventIDHoraire, (state: State, { stateEventID_horaire }) => ({ ...state, stateEventID_horaire })),
  on(AppActions.updateStateEventIDAgentremplacant, (state: State, { stateEventID_agentremplacant }) => ({ ...state, stateEventID_agentremplacant })),
  on(AppActions.updateStateEventIDAgent2, (state: State, { stateEventID_agent2 }) => ({ ...state, stateEventID_agent2 })),
  on(AppActions.updateStateEventIDAgent1, (state: State, { stateEventID_agent1 }) => ({ ...state, stateEventID_agent1 })),
  on(AppActions.updateStateEventIDHeureintervention, (state: State, { stateEventID_heureintervention }) => ({ ...state, stateEventID_heureintervention })),
  on(AppActions.updateStateEventIDDateutc, (state: State, { stateEventID_dateutc }) => ({ ...state, stateEventID_dateutc })),
  on(AppActions.updateStateEventIDDateintervention, (state: State, { stateEventID_dateintervention }) => ({ ...state, stateEventID_dateintervention })),
  on(AppActions.updateStateInterventionsPersonne4Desc, (state: State, { stateInterventions_personne4_desc }) => ({ ...state, stateInterventions_personne4_desc })),
  on(AppActions.updateStateInterventionsPersonne3Desc, (state: State, { stateInterventions_personne3_desc }) => ({ ...state, stateInterventions_personne3_desc })),
  on(AppActions.updateStateInterventionsPersonne2Desc, (state: State, { stateInterventions_personne2_desc }) => ({ ...state, stateInterventions_personne2_desc })),
  on(AppActions.updateStateInterventionsPersonne1Desc, (state: State, { stateInterventions_personne1_desc }) => ({ ...state, stateInterventions_personne1_desc })),
  on(AppActions.updateStateDateTest, (state: State, { stateDateTest }) => ({ ...state, stateDateTest })),
  on(AppActions.updateStateTEMPPAYLOAD, (state: State, { stateTEMP_PAYLOAD }) => ({ ...state, stateTEMP_PAYLOAD })),
  on(AppActions.updateStateTEMPPRINTINTERVENTIONID, (state: State, { stateTEMP_PRINTINTERVENTION_ID }) => ({ ...state, stateTEMP_PRINTINTERVENTION_ID })),
  on(AppActions.updateStateTEMPPRINTINTERVENTIONURL, (state: State, { stateTEMP_PRINTINTERVENTION_URL }) => ({ ...state, stateTEMP_PRINTINTERVENTION_URL })),
  on(AppActions.updateStateTEMPPRINTINTERVENTION, (state: State, { stateTEMP_PRINTINTERVENTION }) => ({ ...state, stateTEMP_PRINTINTERVENTION })),
  on(AppActions.updateStateAVISStatut, (state: State, { stateAVIS_Statut }) => ({ ...state, stateAVIS_Statut })),
  on(AppActions.updateStateAVISTitre, (state: State, { stateAVIS_Titre }) => ({ ...state, stateAVIS_Titre })),
  on(AppActions.updateStateAVISMessage, (state: State, { stateAVIS_Message }) => ({ ...state, stateAVIS_Message })),
  on(AppActions.updateStateDataFromEventIDDateutc, (state: State, { stateDataFromEventID_dateutc }) => ({ ...state, stateDataFromEventID_dateutc })),
  on(AppActions.updateStateDataFromInterventionDateinterventionUTC, (state: State, { stateDataFromIntervention_dateinterventionUTC }) => ({ ...state, stateDataFromIntervention_dateinterventionUTC })),
  on(AppActions.updateStateDashIncApr, (state: State, { stateDash_inc_apr }) => ({ ...state, stateDash_inc_apr })),
  on(AppActions.updateStateDashIncMar, (state: State, { stateDash_inc_mar }) => ({ ...state, stateDash_inc_mar })),
  on(AppActions.updateStateDashIncFeb, (state: State, { stateDash_inc_feb }) => ({ ...state, stateDash_inc_feb })),
  on(AppActions.updateStateDashIncJan, (state: State, { stateDash_Inc_Jan }) => ({ ...state, stateDash_Inc_Jan })),
  on(AppActions.updateStateDashIntApr, (state: State, { stateDash_Int_Apr }) => ({ ...state, stateDash_Int_Apr })),
  on(AppActions.updateStateDashIntMar, (state: State, { stateDash_int_Mar }) => ({ ...state, stateDash_int_Mar })),
  on(AppActions.updateStateDashIntFeb, (state: State, { stateDash_int_Feb }) => ({ ...state, stateDash_int_Feb })),
  on(AppActions.updateStateDashIntJan, (state: State, { stateDash_Int_Jan }) => ({ ...state, stateDash_Int_Jan })),
  on(AppActions.updateStateB64F1, (state: State, { stateB64F1 }) => ({ ...state, stateB64F1 })),
  on(AppActions.updateStateIncidentsConvertDate, (state: State, { stateIncidents_convert_date }) => ({ ...state, stateIncidents_convert_date })),
  on(AppActions.updateStateIncidentsConvertIndividu6, (state: State, { stateIncidents_convert_individu6 }) => ({ ...state, stateIncidents_convert_individu6 })),
  on(AppActions.updateStateIncidentsConvertIndividu5, (state: State, { stateIncidents_convert_individu5 }) => ({ ...state, stateIncidents_convert_individu5 })),
  on(AppActions.updateStateIncidentsConvertIndividu4, (state: State, { stateIncidents_convert_individu4 }) => ({ ...state, stateIncidents_convert_individu4 })),
  on(AppActions.updateStateIncidentsConvertIndividu3, (state: State, { stateIncidents_convert_individu3 }) => ({ ...state, stateIncidents_convert_individu3 })),
  on(AppActions.updateStateIncidentsConvertIndividu2, (state: State, { stateIncidents_convert_individu2 }) => ({ ...state, stateIncidents_convert_individu2 })),
  on(AppActions.updateStateIncidentsConvertIndividu1, (state: State, { stateIncidents_convert_individu1 }) => ({ ...state, stateIncidents_convert_individu1 })),
  on(AppActions.updateStateIncidentsConvertDescription, (state: State, { stateIncidents_convert_description }) => ({ ...state, stateIncidents_convert_description })),
  on(AppActions.updateStateDateConvert, (state: State, { stateDateConvert }) => ({ ...state, stateDateConvert })),
  on(AppActions.updateStateInterventionsConvertResolution, (state: State, { stateInterventions_convert_resolution }) => ({ ...state, stateInterventions_convert_resolution })),
  on(AppActions.updateStateInterventionsConvertDescription, (state: State, { stateInterventions_convert_description }) => ({ ...state, stateInterventions_convert_description })),
  on(AppActions.updateStateInterventionsConvertComments, (state: State, { stateInterventions_convert_comments }) => ({ ...state, stateInterventions_convert_comments })),
  on(AppActions.updateStateUsernameAPI, (state: State, { stateUsernameAPI }) => ({ ...state, stateUsernameAPI })),
  on(AppActions.updateStateSubmissionDate, (state: State, { stateSubmissionDate }) => ({ ...state, stateSubmissionDate })),
  on(AppActions.updateStateIntIDIntervention, (state: State, { stateIntIDIntervention }) => ({ ...state, stateIntIDIntervention })),
  on(AppActions.updateStateDataFromInterventionDateintervention, (state: State, { stateDataFromIntervention_dateintervention }) => ({ ...state, stateDataFromIntervention_dateintervention })),
  on(AppActions.updateStateDataFromInterventionAgent2, (state: State, { stateDataFromIntervention_agent2 }) => ({ ...state, stateDataFromIntervention_agent2 })),
  on(AppActions.updateStateDataFromInterventionAgent1, (state: State, { stateDataFromIntervention_agent1 }) => ({ ...state, stateDataFromIntervention_agent1 })),
  on(AppActions.updateStateDataFromInterventionComments, (state: State, { stateDataFromIntervention_comments }) => ({ ...state, stateDataFromIntervention_comments })),
  on(AppActions.updateStateDataFromInterventionDescription, (state: State, { stateDataFromIntervention_description }) => ({ ...state, stateDataFromIntervention_description })),
  on(AppActions.updateStateDataFromInterventionResolutionDetail, (state: State, { stateDataFromIntervention_resolution_detail }) => ({ ...state, stateDataFromIntervention_resolution_detail })),
  on(AppActions.updateStateDataFromInterventionType, (state: State, { stateDataFromIntervention_type }) => ({ ...state, stateDataFromIntervention_type })),
  on(AppActions.updateStateDataFromInterventionHeure, (state: State, { stateDataFromIntervention_heure }) => ({ ...state, stateDataFromIntervention_heure })),
  on(AppActions.updateStateDataFromInterventionSecteur, (state: State, { stateDataFromIntervention_secteur }) => ({ ...state, stateDataFromIntervention_secteur })),
  on(AppActions.updateStateDataFromInterventionLieu, (state: State, { stateDataFromIntervention_lieu }) => ({ ...state, stateDataFromIntervention_lieu })),
  on(AppActions.updateStateDataFromInterventionTemps, (state: State, { stateDataFromIntervention_temps }) => ({ ...state, stateDataFromIntervention_temps })),
  on(AppActions.updateStateDataFromInterventionHoraire, (state: State, { stateDataFromIntervention_horaire }) => ({ ...state, stateDataFromIntervention_horaire })),
  on(AppActions.updateStateTempSelectedIDIntervention, (state: State, { stateTempSelectedIDIntervention }) => ({ ...state, stateTempSelectedIDIntervention })),
  on(AppActions.updateStateAmoutofINC, (state: State, { stateAmoutofINC }) => ({ ...state, stateAmoutofINC })),
  on(AppActions.updateStateListinc, (state: State, { stateListinc }) => ({ ...state, stateListinc })),
  on(AppActions.updateStateBDExtPWD, (state: State, { stateBDExtPWD }) => ({ ...state, stateBDExtPWD })),
  on(AppActions.updateStateValidtySessionToken, (state: State, { stateValidtySessionToken }) => ({ ...state, stateValidtySessionToken })),
  on(AppActions.updateStateMD5Users, (state: State, { stateMD5Users }) => ({ ...state, stateMD5Users })),
  on(AppActions.updateStateDataFromEventIDInstanceSuiviNum, (state: State, { stateDataFromEventID_instance_suivi_num }) => ({ ...state, stateDataFromEventID_instance_suivi_num })),
  on(AppActions.updateStateDataFromEventIDInstanceSuivi, (state: State, { stateDataFromEventID_instance_suivi }) => ({ ...state, stateDataFromEventID_instance_suivi })),
  on(AppActions.updateStateDataFromEventIDInstanceHeure, (state: State, { stateDataFromEventID_instance_heure }) => ({ ...state, stateDataFromEventID_instance_heure })),
  on(AppActions.updateStateDataFromEventIDInstanceTel, (state: State, { stateDataFromEventID_instance_tel }) => ({ ...state, stateDataFromEventID_instance_tel })),
  on(AppActions.updateStateDataFromEventIDInstanceNomContact, (state: State, { stateDataFromEventID_instance_nom_contact }) => ({ ...state, stateDataFromEventID_instance_nom_contact })),
  on(AppActions.updateStateDataFromEventIDInstanceNom, (state: State, { stateDataFromEventID_instance_nom }) => ({ ...state, stateDataFromEventID_instance_nom })),
  on(AppActions.updateStateDataFromEventIDIndividu6, (state: State, { stateDataFromEventID_individu6 }) => ({ ...state, stateDataFromEventID_individu6 })),
  on(AppActions.updateStateDataFromEventIDIndividu5, (state: State, { stateDataFromEventID_individu5 }) => ({ ...state, stateDataFromEventID_individu5 })),
  on(AppActions.updateStateDataFromEventIDIndividu4, (state: State, { stateDataFromEventID_individu4 }) => ({ ...state, stateDataFromEventID_individu4 })),
  on(AppActions.updateStateDataFromEventIDIndividu3, (state: State, { stateDataFromEventID_individu3 }) => ({ ...state, stateDataFromEventID_individu3 })),
  on(AppActions.updateStateDataFromEventIDIndividu2, (state: State, { stateDataFromEventID_individu2 }) => ({ ...state, stateDataFromEventID_individu2 })),
  on(AppActions.updateStateDataFromEventIDIndividu1, (state: State, { stateDataFromEventID_individu1 }) => ({ ...state, stateDataFromEventID_individu1 })),
  on(AppActions.updateStateDataFromEventIDDeroulement, (state: State, { stateDataFromEventID_deroulement }) => ({ ...state, stateDataFromEventID_deroulement })),
  on(AppActions.updateStateDataFromEventIDDateincident, (state: State, { stateDataFromEventID_dateincident }) => ({ ...state, stateDataFromEventID_dateincident })),
  on(AppActions.updateStateDataFromEventIDHeureincident, (state: State, { stateDataFromEventID_heureincident }) => ({ ...state, stateDataFromEventID_heureincident })),
  on(AppActions.updateStateDataFromEventIDNatureincident, (state: State, { stateDataFromEventID_natureincident }) => ({ ...state, stateDataFromEventID_natureincident })),
  on(AppActions.updateStateDataFromEventIDTransversale, (state: State, { stateDataFromEventID_transversale }) => ({ ...state, stateDataFromEventID_transversale })),
  on(AppActions.updateStateDataFromEventIDAdresse, (state: State, { stateDataFromEventID_Adresse }) => ({ ...state, stateDataFromEventID_Adresse })),
  on(AppActions.updateStateUsers, (state: State, { stateUsers }) => ({ ...state, stateUsers })),
  on(AppActions.updateStateTempSelectedIDEvent, (state: State, { stateTempSelectedIDEvent }) => ({ ...state, stateTempSelectedIDEvent })),
  on(AppActions.updateStateGetDataFromEventID, (state: State, { stateGetDataFromEventID }) => ({ ...state, stateGetDataFromEventID })),
  on(AppActions.updateStateAmoutofEvent, (state: State, { stateAmoutofEvent }) => ({ ...state, stateAmoutofEvent })),
  on(AppActions.updateStateIntIDEvent, (state: State, { stateIntIDEvent }) => ({ ...state, stateIntIDEvent })),
  on(AppActions.updateStateUsers2, (state: State, { stateUsers2 }) => ({ ...state, stateUsers2 })),
  on(AppActions.updateStateListevenement, (state: State, { stateListevenement }) => ({ ...state, stateListevenement })),
  on(AppActions.updateStateAIRTABLETOKEN, (state: State, { stateAIR_TABLE_TOKEN }) => ({ ...state, stateAIR_TABLE_TOKEN })),
  on(AppActions.updateStateLoginErrorMessage, (state: State, { stateLoginErrorMessage }) => ({ ...state, stateLoginErrorMessage })),
);

export function reducer(state: State | undefined, action: Action) {
  return appReducer(state, action);
}
