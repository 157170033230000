import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbFormFieldModule, NbIconModule, NbInputModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { InputComponent } from './input.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CoreModule, NbInputModule, NbFormFieldModule, NbIconModule],
  declarations: [InputComponent],
  exports: [InputComponent],
})
export class InputModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: InputComponent,
    schema: require('./input.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./input.calculator.svg').default,
        componentTree: require('!!raw-loader?!./input.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./input.component-tree-active.svg').default,
      },
    },
  };
}
