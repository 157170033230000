import { ChartColorModel, ChartDataItem, DataObject } from '@uibakery/fields-types';

export function to2DArray({ data, xProp, yProp }: Partial<ChartDataItem>): unknown[][] {
  if (!data?.length || !xProp || !yProp || !Array.isArray(data)) {
    return [];
  }

  return data?.map((item: DataObject) => {
    return [item?.[xProp?.trim()], item?.[yProp?.trim()]];
  });
}

export function toDataObjects({ data, xProp, yProp }: Partial<ChartDataItem>): DataObject[] {
  if (!data?.length || !xProp || !yProp || !Array.isArray(data)) {
    return [];
  }

  return data?.map((item: DataObject) => {
    return { value: item?.[yProp], name: item?.[xProp] };
  });
}

export function toDataObjectsFromLegacyFormat(data: (number | string)[][]): DataObject[] {
  if (!data?.length || !Array.isArray(data)) {
    return [];
  }

  return data?.map((item: (string | number)[]) => {
    return { value: item?.[1], name: item?.[0] };
  });
}

const colors: ChartColorModel[] = ['primary', 'success', 'warning', 'danger', 'info'];

export function getRandomChartColor(): ChartColorModel {
  return colors[Math.floor(Math.random() * colors.length)];
}
