export enum BreakpointWidth {
  Desktop = 'xl',
  TabletLandscape = 'lg',
  TabletPortrait = 'md',
  MobileLandscape = 'sm',
  MobilePortrait = 'xs',
}

export interface TriggeredAction {
  action: string;
  paramCode: string;
}

export interface BreakpointStyles {
  [key: string]: any;
}

export type ComponentStyles = Partial<Record<BreakpointWidth, BreakpointStyles>>;

export interface ComponentProperties {
  [key: string]: any;
}

export interface ComponentActions {
  [key: string]: TriggeredAction[];
}
