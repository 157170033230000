import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { NbSpinnerModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { SpaceComponent } from './space.component';

@NgModule({
  imports: [CoreModule, NbSpinnerModule, ScrollingModule],
  declarations: [SpaceComponent],
  exports: [SpaceComponent],
})
export class SpaceModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: SpaceComponent,
    schema: require('./space.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./space.calculator.svg').default,
        componentTree: require('!!raw-loader?!./space.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./space.component-tree-active.svg').default,
      },
    },
  };
}
