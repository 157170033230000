import { NgModule } from '@angular/core';
import { NbIconModule } from '@nebular/theme';

import { EchartsDirective } from './echarts/echarts.directive';
import { NoDataComponent } from './echarts/no-data/no-data.component';

@NgModule({
  imports: [NbIconModule],
  declarations: [EchartsDirective, NoDataComponent],
  exports: [EchartsDirective, NoDataComponent],
})
export class SharedModule {}
