import { NgModule } from '@angular/core';
import { NbLayoutModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { LayoutHeaderComponent } from './layout-header.component';

@NgModule({
  imports: [CoreModule, NbLayoutModule],
  declarations: [LayoutHeaderComponent],
  exports: [LayoutHeaderComponent],
})
export class LayoutHeaderModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: LayoutHeaderComponent,
    schema: require('./layout-header.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./layout-header.calculator.svg').default,
        componentTree: require('!!raw-loader?!./layout-header.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./layout-header.component-tree-active.svg').default,
      },
    },
  };
}
