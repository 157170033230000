import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbCheckboxModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { CheckboxComponent } from './checkbox.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbCheckboxModule, ReactiveFormsModule],
  declarations: [CheckboxComponent],
  exports: [CheckboxComponent],
})
export class CheckboxModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: CheckboxComponent,
    schema: require('./checkbox.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./checkbox.calculator.svg').default,
        componentTree: require('!!raw-loader?!./checkbox.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./checkbox.component-tree-active.svg').default,
      },
    },
  };
}
