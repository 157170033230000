import { NbComponentStatus } from '@nebular/theme';

import { ComponentProperties, ComponentStyles, TriggeredAction } from './component';

export enum ValueType {
  STRING = 'string',
  OBJECT = 'object',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export enum InterpolationType {
  STRING = 'string',
  CODE = 'code',
  CUSTOM_CODE = 'customCode',
}

export interface PropertyField {
  // id of the field component to render on UI
  fieldId: string;

  responsive?: boolean;
  config?: any;
  labelConfig?: LabelConfig;

  group?: string;
  default?: any;

  dependencyFields?: Record<string, string>;
  parentDependencyFields?: Record<string, string>;
}

export interface LabelConfig {
  label: string;
  labelHintIcon?: string;
  labelHintIconTooltip?: string;
}

export interface InterpolatedPropertiesInfo {
  propsUsed: boolean;
  inputInterpolationType: InterpolationType;
}

export interface DataConfig {
  switch: boolean;
  noLabel: boolean;
  interpolationType: InterpolationType;
  keepInterpolationInBuilderMode: boolean;
  formatHint?: string | string[];
  compileDataValue?: boolean;
  oneLine?: boolean;
  /**
   * property panew/profile/cnowlsth array ([`prop1`, `prop2.prop3`]) to interpolate certain property in data object
   * we use it for charts, where `value` property is array of objects,
   * each item of which has a string prop and it should be interpolated
   * `prop1` also can be property in array item
   *
   * https://akveo.myjetbrains.com/youtrack/issue/UIB_DEV_UB-3428
   */
  interpolationProperties?: string[];
}

export interface DataNotification {
  icon: string;
  pack: string;
  text: string;
  status?: NbComponentStatus;
  showCondition?: {
    propertyToCheck: string;
    type: string;
    condition: string;
  };
}

export type FieldsToUpdateValueMapping = Record<string, any>;

export type FieldsToUpdate = Record<string, FieldsToUpdateValueMapping>;

export interface ComponentInput {
  name: string;
  type: ValueType;
  field: PropertyField;
  data?: DataConfig;
  fieldsToUpdate?: FieldsToUpdate;
}

export interface ComponentPropertyDataHintFormat {
  dataFormatExample: string;
  dataFormatType: string;
}

export interface ComponentOutput {
  name: string;

  // Text to be shown on the ui
  title: string;
  withParam?: boolean;
}

export interface ComponentUIProperty {
  propertyName: string;
  outputName: string;
}

export interface ComponentMetaInfo {
  name: string;
  order: number;
  headerSupport: boolean;
  sidebarSupport: boolean;
  tags: string[];
  // component.properties.container always true (actual for complex children - tab, accordion-item)
  locked?: boolean;
  hidden?: boolean;
  forbidSelection?: boolean;
}

export interface ChildField {
  child: string;
  inputNames: string[];
  name: string;
  labelProperty?: string;
}

export interface ChildrenFactory {
  id?: string;
  definitionId: string;
  properties?: ComponentProperties;
  styles?: ComponentStyles;
  actions?: Record<string, TriggeredAction[]>;
  children?: Record<string, ChildrenFactory[]>;
  repeat?: number;
}

export interface SchemaFactory {
  children?: Record<string, ChildrenFactory[]>;
  properties?: ComponentProperties;
  styles?: ComponentStyles;
}

/**
 * When we reorder the component, we check for `syncDeepSibling` and `syncIndex`.
 * `parentLevel` determines how many levels of parents we will rise,
 * in case 2 it will be like this `tableHeaderCell` (current) -> `tableHeader` -> `table`.
 * Then we go down 2 levels for each child.
 * It turns out that we take all the children in the table (all `tableRow`,
 * but we will not take `tableHeader`, because this is the immediate parent of our original element,
 * we exclude it, but if there were 2 `tableHeader`,
 * then we would take the one that is not the parent of our `tableHeaderCell`),
 * and we take all children from them (it turns out that we take only `tableRowCell`).
 * If there is a `findBy` property, then we filter the found components by this property.
 * Since we are doing `syncIndex`, we trigger an index update for all children found.
 *
 * `syncDelete` is similar, we search for all siblings, filter by index, delete.
 *
 * `syncAdd`, when adding a column, we look for an element,
 * after which we add a column (for now, this is just the last column),
 * then we look for siblings for this element with the same index,
 * and trigger the addition of a new element after the found siblings with the same definitionId as sibling.
 *
 * syncChildLength, this is more for tableRow,
 * when we add a new row to make sure we add
 * the same number of tableRowCells to this same row
 * as we have all other elements in the table.
 */
export interface SyncDeepSibling {
  /**
   * Component param name which will be used for sibling search.
   */
  findBy?: string;

  /**
   * Level of common parent for all required siblings.
   */
  parentLevel?: number;

  /**
   * Allow update index of multiple components with the same index.
   * For example, after changing index of tableHeaderCell,
   * all tableRowCell with same index should be updated.
   */
  syncIndex?: boolean;

  /**
   * Allow add component in each dimension.
   * For example, after adding of tableHeaderCell,
   * tableRowCell should be added to each tableRow.
   */
  syncAdding?: boolean;

  /**
   * Allow delete component in each dimension.
   * For example, after deleting of tableHeaderCell,
   * tableRowCell with same index should be deleted in each tableRow.
   */
  syncDelete?: boolean;

  /**
   * Allow keep children component quantity same in each dimension.
   * For example, adding of tableRow,
   * tableRowCell quantity should be the same as tableHeaderCell in tableHeader.
   */
  syncChildLength?: boolean;
}

const a: SyncDeepSibling = {} as SyncDeepSibling;

export interface Schema {
  id: string;
  tag: string;
  attributeSelector?: string;
  metaInfo: ComponentMetaInfo;
  inputs: ComponentInput[];
  factory?: SchemaFactory;
  outputs?: ComponentOutput[];
  uiProperties?: ComponentUIProperty[];
  dynamicChildren?: string[];
  childrenFields?: ChildField[];
  syncDeepSibling?: SyncDeepSibling;
  /**
   * Will work similar to ComponentLogicPropName.CONDITION_PROPERTY but on Slot level.
   * Key is used for slot name.
   * Value is the name of the property that will be used for a condition over the slot.
   * `undefined` will be mapped to `true`.
   */
  slotConditionMapToProperty?: Record<string, string>;
}

export abstract class SchemaResolver {
  public abstract getSchemaByDefinitionId(definitionId: string): Schema;

  public abstract getAll(): Schema[];
}

export function getInputName(input: ComponentInput): string {
  const { name }: ComponentInput = input;
  const { fieldId }: PropertyField = input.field;
  return name ?? fieldId;
}
