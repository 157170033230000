import { Compiler, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Base } from './base';
import { ComponentModule, COMPONENT_METADATA } from './component-metadata';
import { ComponentDefinition, ComponentRegistry } from './component-registry';
import { ComponentFactory, ComponentType, ModuleFactory } from './component-type';

export interface ComponentLibrary {
  components: ComponentModule[];
}

export abstract class ComponentLibraryLoader {
  abstract load(): Observable<any>;
}

@Injectable({ providedIn: 'root' })
export class ComponentLibraryInstaller {
  constructor(
    private componentLibraryLoader: ComponentLibraryLoader,
    private compiler: Compiler,
    private injector: Injector,
    private componentRegistry: ComponentRegistry,
  ) {}

  install<T>(): Observable<ComponentLibrary> {
    return this.componentLibraryLoader.load().pipe(
      tap((componentLibrary: ComponentLibrary): void => {
        const definitions: ComponentDefinition[] = this.createDefinitions(componentLibrary);
        this.componentRegistry.install(definitions);
      }),
    );
  }

  private createDefinitions(componentLibrary: ComponentLibrary): ComponentDefinition[] {
    const componentsDefinitions: ComponentDefinition[] = [];

    for (const componentModule of componentLibrary.components) {
      const module: ModuleFactory = this.compileModule(componentModule);
      const componentFactory: ComponentFactory = this.createComponentFactory(componentModule, module);

      const componentDefinition: ComponentDefinition = {
        componentFactory,
        schema: componentModule[COMPONENT_METADATA].schema,
        assets: componentModule[COMPONENT_METADATA].assets,
      };

      componentsDefinitions.push(componentDefinition);
    }

    return componentsDefinitions;
  }

  private compileModule(componentModule: ComponentModule): ModuleFactory {
    return this.compiler.compileModuleSync(componentModule);
  }

  private createComponentFactory(componentModule: ComponentModule, module: ModuleFactory): ComponentFactory {
    return module
      .create(this.injector)
      .componentFactoryResolver.resolveComponentFactory(componentModule[COMPONENT_METADATA].componentType);
  }
}
