import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';
import { SizeProperty, WithSizeComponent } from '@uibakery/fields-types';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'th[ubTableHeaderCell]',
  template: `
    <ng-content></ng-content>
    <ub-slot name="content" [content]="content"></ub-slot>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderCellComponent extends BaseComponent implements WithSizeComponent {
  @Input() content?: RenderComponent[];

  @Input() size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  @HostBinding('style.width')
  get width(): string | undefined {
    return this.size?.width;
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return this.size?.height;
  }
}
