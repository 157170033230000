import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbSidebarComponent } from '@nebular/theme';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent, ComponentInitService, RenderComponent, ThemeService } from '@uibakery/core';
import { SizeProperty, WithSizeComponent } from '@uibakery/fields-types';

@Component({
  selector: 'ub-sidebar',
  styleUrls: ['./layout-sidebar.component.scss'],
  template: `
    <nb-sidebar left [fixed]="false" [collapsedBreakpoints]="['xs', 'is', 'sm']" [compactedBreakpoints]="[]">
      <ub-slot name="content" [content]="content">
        <ng-content></ng-content>
      </ub-slot>
    </nb-sidebar>
  `,
  // Sidebar doesn't work if inserted in component with ChangeDetectionStrategy.OnPush
  // tslint:disable-next-line:prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LayoutSidebarComponent extends BaseComponent implements AfterViewInit, OnDestroy, WithSizeComponent {
  @ViewChild(NbSidebarComponent) sidebar!: NbSidebarComponent;

  @Input() content?: RenderComponent[];

  @HostBinding('class.fixed')
  @Input()
  fixed: boolean = false;
  private _responsiveValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(private theme: ThemeService, cd: ChangeDetectorRef, initService: ComponentInitService) {
    super(cd, initService);
  }

  @Input() set responsiveValue(responsive: boolean) {
    this._responsiveValue$.next(responsive);
  }

  @HostBinding('class.collapsed')
  get sidebarCollapsed(): boolean {
    return this.sidebar?.collapsed;
  }

  get responsiveValue(): boolean {
    return this._responsiveValue$.getValue();
  }

  private _backgroundColor: string = 'var(--background-basic-color-1)';

  get backgroundColor(): string {
    return this._backgroundColor;
  }

  @Input() set backgroundColor(color: string) {
    this._backgroundColor = color;
    this.theme.setVariable('--sidebar-background-color', color);
  }

  private _spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };

  get spacing(): { paddings: string; margins: string } {
    return this._spacing;
  }

  @Input() set spacing(spacing: { paddings: string; margins: string }) {
    this._spacing = spacing;

    if (spacing?.paddings) {
      this.theme.setVariable('--sidebar-padding', spacing.paddings);
    }
  }

  private _size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  get size(): SizeProperty {
    return this._size;
  }

  @Input() set size(size: SizeProperty) {
    this._size = size;
    this.theme.setVariable('--sidebar-width', size.width!);
  }

  ngAfterViewInit(): void {
    this._responsiveValue$.pipe(takeUntil(this.destroyed$)).subscribe((responsive: boolean) => {
      this.sidebar.responsive = responsive;
      this.sidebar.toggleResponsive(responsive);

      if (!responsive) {
        this.sidebar.expand();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed$.next();
  }

  get width(): string | undefined {
    return this._size?.width;
  }

  get height(): string | undefined {
    return this._size?.height;
  }
}
