import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbComponentStatus } from '@nebular/theme';
import { BehaviorSubject, merge, Observable } from 'rxjs';

import { BAKERY_COMPONENT, BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexContainerProperty,
  FlexProperty,
  Option,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';

@Component({
  selector: 'ub-radio',
  template: `<nb-radio-group
    [formControl]="formControl"
    [status]="status"
    [name]="name"
    [style.flexDirection]="flexContainer?.flexDirection"
    [style.flexWrap]="flexContainer?.flexWrap"
    [style.justifyContent]="flexContainer?.justifyContent"
    [style.alignContent]="flexContainer?.alignContent"
    [style.alignItems]="flexContainer?.alignItems"
    [class.no-width-restriction]="noWidthRestriction"
    ubComponentShield
    [disabled]="disabled"
  >
    <nb-radio *ngFor="let option of options" [value]="option.value">{{ option.label }}</nb-radio>
  </nb-radio-group>`,
  styleUrls: ['./radio.component.scss'],
  providers: [{ provide: BAKERY_COMPONENT, useExisting: RadioComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent extends BaseComponent
  implements WithFlexComponent, WithVisibleComponent, WithSizeComponent {
  @Input() name: string = '';
  @Input() visible: boolean = true;
  @Input() flexContainer: FlexContainerProperty = {
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };
  @Input() status: NbComponentStatus = 'primary';
  @Input() size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() options: Option[] = [
    {
      value: 'option 1',
      label: 'Option 1',
    },
    {
      value: 'option 2',
      label: 'Option 2',
    },
  ];
  @Input() overflow: { overflowX: string; overflowY: string } = {
    overflowX: 'visible',
    overflowY: 'visible',
  };

  formControl: FormControl = new FormControl('');

  @Output() radioChange: Observable<string> = this.formControl.valueChanges;
  private _uiValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() uiValue: Observable<string> = merge(this._uiValue.asObservable(), this.formControl.valueChanges);

  @Input() set disabled(disabled: boolean) {
    // TODO Fix passing [disabled]="disabled" in template.
    // Nebular should implements `setDisabledState` method
    if (disabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }
  get disabled(): boolean {
    return this.formControl.disabled;
  }

  @Input() set value(value: string) {
    this.formControl.setValue(value, { emitEvent: false });
    this._uiValue.next(value);
  }

  get value(): string {
    return this.formControl.value;
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.overflowX')
  get overflowX(): string {
    return this.overflow?.overflowX;
  }

  @HostBinding('style.overflowY')
  get overflowY(): string {
    return this.overflow?.overflowY;
  }

  get noWidthRestriction(): boolean {
    return this.size?.width !== undefined && this.size?.width !== 'auto';
  }
}
