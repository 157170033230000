<ub-sidebar class="Sidebar"
            [spacing]="spacing"
            [size]="size"
            [fixed]="false"
            [responsiveValue]="true"
            backgroundColor="var(--background-basic-color-1)">
  <ub-space class="SidebarSpace"
            [flexContainer]="flexContainer"
            [visible]="true"
            [size]="size1"
            [overflow]="overflow"
            backgroundColor="unset"
            backgroundImage=""
            [showLoading]="false">
    <ub-menu class="Menu"
             [visible]="true"
             [size]="size2"
             color="basic"
             [items]="items"
             [autoSyncItems]="true"></ub-menu>
  </ub-space>
</ub-sidebar>