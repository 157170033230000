import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { COMPONENT_METADATA, ComponentMetadata, CoreModule } from '@uibakery/core';

import { AccordionItemComponent } from './accordion-item.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [AccordionItemComponent],
  exports: [AccordionItemComponent],
})
export class AccordionItemModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: AccordionItemComponent,
    schema: require('./accordion-item.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./accordion-item.calculator.svg').default,
        componentTree: require('!!raw-loader?!./accordion-item.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./accordion-item.component-tree-active.svg').default,
      },
    },
  };
}
