import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NbJSThemeOptions, NbJSThemeVariable, NbThemeService } from '@nebular/theme';
import { registerMap } from 'echarts';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent, ComponentInitService } from '@uibakery/core';
import {
  AlignSelfValue,
  BubbleMapVariation,
  FlexProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';

export interface MapData {
  name: string;
  color?: string;
  value: number;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'ub-bubble-map',
  styleUrls: ['./bubble-map.component.scss'],
  host: { class: 'chart' },
  template: `
    <ng-container *ngIf="variation === 'raw'">
      <ng-container *ngTemplateOutlet="bubbleMap"></ng-container>
    </ng-container>

    <ng-container *ngIf="variation === 'card'">
      <nb-card>
        <nb-card-header *ngIf="title">{{ title }}</nb-card-header>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="bubbleMap"></ng-container>
        </nb-card-body>
      </nb-card>
    </ng-container>

    <ng-template #bubbleMap>
      <div echarts [options]="options"></div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleMapComponent extends BaseComponent
  implements OnInit, OnDestroy, WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() title: string = 'Bubble Map';
  @Input() variation: BubbleMapVariation = 'card';
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() size: SizeProperty = {
    width: '100%',
    height: '100%',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  options: object = {};
  private colorConfig: NbJSThemeVariable = {};
  private geoColors: NbJSThemeVariable[] = [];
  private max: number = -Infinity;
  private initialized: boolean = false;
  private currentTheme!: NbJSThemeOptions;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private theme: NbThemeService,
    private http: HttpClient,
    public cd: ChangeDetectorRef,
    initService: ComponentInitService,
  ) {
    super(cd, initService);
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  private _data: MapData[] = [
    { name: 'Bangladesh', value: 150493658, latitude: 24, longitude: 90 },
    { name: 'Brazil', value: 196655014, latitude: -10, longitude: -55 },
    { name: 'China', value: 1347565324, latitude: 35, longitude: 105 },
    { name: 'Congo, Dem. Rep.', value: 67757577, latitude: 0, longitude: 25 },
    { name: 'Egypt', value: 82536770, latitude: 27, longitude: 30 },
    { name: 'Ethiopia', value: 84734262, latitude: 8, longitude: 38 },
    { name: 'Germany', value: 82162512, latitude: 51, longitude: 9 },
    { name: 'India', value: 1241491960, latitude: 20, longitude: 77 },
    { name: 'Indonesia', value: 242325638, latitude: -5, longitude: 120 },
    { name: 'Iran', value: 74798599, latitude: 32, longitude: 53 },
    { name: 'Japan', value: 126497241, latitude: 36, longitude: 138 },
    { name: 'Mexico', value: 114793341, latitude: 23, longitude: -102 },
    { name: 'Nigeria', value: 162470737, latitude: 10, longitude: 8 },
    { name: 'Pakistan', value: 176745364, latitude: 30, longitude: 70 },
    { name: 'Philippines', value: 94852030, latitude: 13, longitude: 122 },
    { name: 'Russia', value: 142835555, latitude: 60, longitude: 100 },
    { name: 'Thailand', value: 69518555, latitude: 15, longitude: 100 },
    { name: 'Turkey', value: 73639596, latitude: 39, longitude: 35 },
    { name: 'United States', value: 313085380, latitude: 38, longitude: -97 },
    { name: 'Vietnam', value: 88791996, latitude: 16, longitude: 106 },
  ];

  @Input() set data(data: MapData[]) {
    this._data = data ? [...data] : [];

    // avoid showing map without colors
    if (this.initialized) {
      this.updateOptions();
    }
  }
  get data(): MapData[] {
    return this._data;
  }

  ngOnInit(): void {
    combineLatest([this.http.get('assets/map/world.json'), this.theme.getJsTheme()])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([map, config]: [object, NbJSThemeOptions]) => {
        registerMap('world', map);
        this.initColors(config);
        this.updateOptions();

        this.initialized = true;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed$.next();
  }

  private initColors(config: NbJSThemeOptions): void {
    this.currentTheme = config;
    this.colorConfig = config.variables?.bubbleMap as NbJSThemeVariable;

    this.geoColors = [
      this.colorConfig.primary as NbJSThemeVariable,
      this.colorConfig.info as NbJSThemeVariable,
      this.colorConfig.success as NbJSThemeVariable,
      this.colorConfig.warning as NbJSThemeVariable,
      this.colorConfig.danger as NbJSThemeVariable,
    ];
  }

  private updateOptions(): void {
    this.max = -Infinity;
    this._data.forEach((item: MapData) => {
      if (item.value > this.max) {
        this.max = item.value;
      }
    });

    this.options = {
      title: {
        text: 'World Population (2011)',
        left: 'center',
        top: 'top',
        textStyle: {
          color: this.colorConfig.titleColor,
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: (params: { name: string; value: string[] }) => {
          return params.name + ': ' + params.value[2];
        },
      },
      visualMap: {
        show: false,
        min: 0,
        max: this.max,
        inRange: {
          symbolSize: [6, 60],
        },
      },
      geo: {
        name: 'World Population (2010)',
        type: 'map',
        map: 'world',
        roam: true,
        label: {
          emphasis: {
            show: false,
          },
        },
        itemStyle: {
          normal: {
            areaColor: this.colorConfig.areaColor,
            borderColor: this.colorConfig.areaBorderColor,
          },
          emphasis: {
            areaColor: this.colorConfig.areaHoverColor,
          },
        },
        zoom: 1.1,
      },
      series: [
        {
          type: 'scatter',
          coordinateSystem: 'geo',
          data: this.mapDataMapper(this._data),
        },
      ],
    };

    this.cd.markForCheck();
  }

  private mapDataMapper(data: MapData[]): object[] {
    return data.map((item: MapData) => {
      return {
        name: item.name,
        value: [item.longitude, item.latitude, item.value],
        itemStyle: {
          normal: {
            color: item.color || this.getRandomGeoColor(),
          },
        },
      };
    });
  }

  private getRandomGeoColor(): NbJSThemeVariable {
    const index: number = Math.round(Math.random() * this.geoColors.length);
    return this.geoColors[index] as NbJSThemeVariable;
  }
}
