import { EChartOption } from 'echarts';

import { ChartConfig, ChartLegend, ChartVariation, DataObject } from '@uibakery/fields-types';

import { ChartDataMapper } from '../chart.types';
import { getGrid, getLegendDefaults, getTitleDefaults } from './settings';

import Format = echarts.EChartOption.Tooltip.Format;
import SeriesBar = echarts.EChartOption.SeriesBar;

const initOptions: DataObject = {
  tooltip: {
    trigger: 'item',
    formatter: (format: Format | Format[]) => {
      const config: Format = format as Format;
      return `<span id="tooltip">${config.seriesName}: <b>${config.value}</b></span>`;
    },
    position: (
      point: (number | string)[],
      params: object | object[],
      element: HTMLElement,
      rect: object,
      size: object,
    ) => {
      const pointsArray: number[] = point as number[];
      const tooltipView: { contentSize: number[] } = size as { contentSize: number[] };
      return [pointsArray[0] - tooltipView.contentSize[0] / 2, pointsArray[1] - tooltipView.contentSize[1] - 10];
    },
    backgroundColor: '#223345',
    extraCssText: 'margin-top: -5px;',
  },
  barMaxWidth: 20,
};

// we use `bars` inside of `doughnut` chart to reach rounded series blocks
export class DoughnutChartDataMapper implements ChartDataMapper {
  computeOptions(
    config: ChartConfig,
    legend: ChartLegend,
    colorConfig: { [colorName: string]: string },
    variation: ChartVariation,
  ): EChartOption {
    return {
      ...initOptions,
      grid: getGrid(variation),
      series: this.mapSeries(config, colorConfig),
      title: {
        ...getTitleDefaults(variation),
        text: config.title,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
      legend: {
        ...getLegendDefaults(variation),
        show: legend.visible,
        orient: legend.orientation,
        left: legend.horizontal,
        top: legend.vertical,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
      backgroundColor: colorConfig.bg,
      color: [colorConfig.warning, colorConfig.info, colorConfig.danger, colorConfig.success, colorConfig.primary],
      radiusAxis: {
        type: 'category',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: ['a'],
      },
      angleAxis: {
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        min: 0,
        max: (value: { max: number }) => value.max,
        startAngle: 0,
      },
      polar: {
        center: ['50%', '50%'],
        radius: '130%',
      },
    };
  }

  getHoverSeriesStyles(event: { [key: string]: string }, options: EChartOption): DataObject[] {
    return (options.series || [])
      .filter((series: DataObject) => series.name === event.seriesName)
      .map((series: DataObject) => {
        return {
          ...series,
          itemStyle: this.getBorderStyles(event.type, event.color),
        };
      });
  }

  private getBorderStyles(eventType: string, color: string): Object {
    if (eventType === 'mouseover') {
      return {
        barBorderWidth: 5,
        barBorderColor: color,
      };
    } else {
      return {
        barBorderWidth: 0,
      };
    }
  }

  private mapSeries(config: ChartConfig, colorConfig: { [colorName: string]: string }): SeriesBar[] {
    const chartData: DataObject[] = (config.data[0]?.data as DataObject[]) || [];

    return chartData.map((dataItem: DataObject) => {
      const data: number[] = [dataItem.value] as number[];

      return {
        data,
        type: 'bar',
        coordinateSystem: 'polar',
        stack: 'a',
        roundCap: true,
        name: dataItem.name as string,
        color: colorConfig[dataItem.color as string],
        itemStyle: {
          barBorderWidth: 0,
          barBorderColor: colorConfig[dataItem.color as string],
        },
      };
    });
  }
}
