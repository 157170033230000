<nb-layout withScroll>
  <nb-layout-column [style.padding]="paddings | responsive">
    <ub-slot name="root">
      <ub-space class="Space"
                [flexContainer]="flexContainer"
                [flex]="flex"
                [visible]="true"
                [size]="size"
                [overflow]="overflow"
                backgroundColor="unset"
                backgroundImage=""
                [showLoading]="false">
        <ub-space class="BoiteConnexion"
                  [flexContainer]="flexContainer1"
                  [visible]="true"
                  [spacing]="spacing"
                  [size]="size1"
                  [overflow]="overflow1"
                  backgroundColor="unset"
                  backgroundImage=""
                  [showLoading]="false">
          <ub-card class="ContainerConnexion"
                   [visible]="true"
                   [spacing]="spacing1 | responsive"
                   [headerPadding]="true"
                   [bodyPadding]="true"
                   [footerPadding]="true"
                   [showHeader]="true"
                   [showFooter]="true"
                   status="default"
                   accent="primary"
                   backgroundColor="var(--background-basic-color-1)"
                   backgroundImage=""
                   [size]="size2"
                   [showLoading]="false"
                   (init)="cardInit($event)">
            <ub-card-header class="ContainerConnexionHeader">
              <ub-space class="Space3"
                        [flexContainer]="flexContainer2"
                        [flex]="flex1"
                        [visible]="true"
                        [size]="size3"
                        [overflow]="overflow2"
                        backgroundColor="unset"
                        backgroundImage=""
                        [showLoading]="false">
                <ub-space class="Space5"
                          [flexContainer]="flexContainer3"
                          [visible]="true"
                          [size]="size4"
                          [overflow]="overflow3"
                          backgroundColor="var(--color-primary-default)"
                          backgroundImage=""
                          [showLoading]="false">
                  <ub-image class="LogoSDC2"
                            [visible]="visible | responsive"
                            [size]="size5 | responsive"
                            [src]="src | responsive"
                            radius="0px"
                            [fit]="fit | responsive"></ub-image>
                </ub-space>
                <ub-space class="Space6"
                          [flexContainer]="flexContainer4"
                          [visible]="true"
                          [size]="size6"
                          [overflow]="overflow4"
                          backgroundColor="unset"
                          backgroundImage=""
                          [showLoading]="false">
                  <ub-heading class="Heading"
                              [visible]="true"
                              [spacing]="spacing2"
                              text="Se connecter"
                              type="h5"
                              fontSize="22px"
                              color="basic"></ub-heading>
                </ub-space>
              </ub-space>
            </ub-card-header>
            <ub-card-body class="ContainerConnexionBody">
              <ub-space class="SignUpForm"
                        [flexContainer]="flexContainer5"
                        [flex]="flex2"
                        [visible]="true"
                        [size]="size7"
                        [overflow]="overflow5"
                        backgroundColor="unset"
                        backgroundImage=""
                        [showLoading]="false">
                <ub-text class="Text"
                         [visible]="true"
                         [spacing]="spacing3"
                         text="Nom d'utilisateur"
                         type="label"
                         fontSize="12px"
                         [textStyle]="textStyle"
                         color="basic"></ub-text>
                <ub-input class="Username"
                          [visible]="true"
                          value=""
                          status="basic"
                          type="text"
                          formFieldSize="medium"
                          [size]="size8"
                          shape="rectangle"
                          [disabled]="false"
                          placeholder="..."
                          iconPlacement="none"
                          icon="star-outline"
                          uiPropertyName="updateUiUsername"
                          [uiProperty]="{
                          	value: 'uiValue'
                          }"></ub-input>
                <ub-text class="Text1"
                         [visible]="true"
                         [spacing]="spacing4"
                         text="Mot de passe"
                         type="label"
                         fontSize="12px"
                         color="basic"></ub-text>
                <ub-input class="Secret"
                          [visible]="true"
                          value=""
                          [spacing]="spacing5"
                          status="basic"
                          type="password"
                          formFieldSize="medium"
                          [size]="size9"
                          shape="rectangle"
                          [disabled]="false"
                          placeholder=""
                          iconPlacement="none"
                          icon="star"
                          uiPropertyName="updateUiSecret"
                          [uiProperty]="{
                          	value: 'uiValue'
                          }"></ub-input>
                <ub-link class="Link"
                         [visible]="true"
                         text="Vous n'avez pas de compte ? Communiquez avec votre agent."
                         [linkInfo]="linkInfo"
                         [queryParams]="queryParams"
                         [openInNewTab]="false"
                         fontSize="15px"
                         [textStyle]="textStyle1"
                         decoration="underline"
                         color="primary"></ub-link>
              </ub-space>
            </ub-card-body>
            <ub-card-footer class="ContainerConnexionFooter">
              <ub-space class="Space4"
                        [flexContainer]="flexContainer6"
                        [flex]="flex3"
                        [visible]="true"
                        [size]="size10"
                        [overflow]="overflow6"
                        backgroundColor="unset"
                        backgroundImage=""
                        [showLoading]="false">
                <ub-button class="BoutonConnexion"
                           [visible]="true"
                           text="Connexion"
                           status="success"
                           formFieldSize="medium"
                           [size]="size11"
                           appearance="filled"
                           [disabled]="false"
                           iconPlacement="none"
                           icon="star"
                           (buttonClick)="buttonButtonClick($event)"></ub-button>
              </ub-space>
            </ub-card-footer>
          </ub-card>
        </ub-space>
      </ub-space>
    </ub-slot>
  </nb-layout-column>
</nb-layout>
