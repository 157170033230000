// @ts-nocheck
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SidebarComponent implements OnInit {

  spacing = {
        	margins: '',
        	paddings: '16px 16px 16px 16px'
        };

  size = {
        	width: '256px',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  flexContainer = {
        	flexDirection: 'column',
        	alignItems: 'flex-start',
        	justifyContent: 'flex-start',
        	flexWrap: 'wrap',
        	alignContent: 'flex-start'
        };

  size1 = {
        	width: '100%',
        	height: '100%',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow = {
        	overflowX: 'auto',
        	overflowY: 'auto'
        };

  size2 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  items = [
          {
            title: 'Se connecter',
            link: '/login'
          },
          {
            title: 'Accueil',
            link: '/home2'
          },
          {
            title: 'Tableau de bord',
            link: '/Tableau-de-bord'
          },
          {
            title: 'Accueil des archives',
            link: '/archives',
            children: [
              {
                title: 'Liste des incidents',
                link: '/archives/archives-incidents',
                children: [
                  {
                    title: 'Consultation incident',
                    link: '/archives/archives-incidents/consult-incident'
                  }
                ]
              },
              {
                title: 'Consultation des archives interventions',
                link: '/archives/archives-consultation-interventions'
              },
              {
                title: 'Archives Interventions',
                link: '/archives/archives-p1-interventions'
              }
            ]
          },
          {
            title: 'Interventions',
            link: '/interventions',
            children: [
              {
                title: 'Saisie intervention',
                link: '/interventions/soumission'
              },
              {
                title: 'Modification Interventions',
                link: '/interventions/modification'
              }
            ]
          },
          {
            title: 'Support',
            link: '/Support'
          }
        ];
  constructor() {}

  ngOnInit(): void {}
}
