import { Injectable, Optional } from '@angular/core';
import { defer, iif, NEVER, noop, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppMode, AppModeProvider } from './app-mode-provider';

@Injectable({ providedIn: 'root' })
export class ComponentInitService {
  /**
   * This init$ is required to allow us to refire init in components when the user enters 'preview' mode.
   * When we have generated an app this dependency will still exist, however, we'll not have AppModeProvider.
   * So, we're keeping it Optional and doing a subscription to the appModeProvider through the 'defer' operator.
   * * */
  readonly init$: Observable<void> = iif(
    () => !!this.appModeProvider,
    defer(() =>
      this.appModeProvider.mode$.pipe(
        filter((mode: AppMode) => mode === AppMode.Preview),
        map(noop),
      ),
    ),
    NEVER,
  );

  constructor(@Optional() private appModeProvider: AppModeProvider) {}
}
