import { Directive, ElementRef, OnDestroy, OnInit, Optional, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppMode, AppModeProvider } from './app-mode-provider';

@Directive({ selector: '[ubComponentShield]' })
export class ComponentShieldDirective implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject<void>();

  private coverDiv!: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    @Optional() private renderer: Renderer2,
    @Optional() private appModeProvider: AppModeProvider,
  ) {}

  private get parentElement(): HTMLElement {
    return this.elementRef.nativeElement.parentElement;
  }

  ngOnInit(): void {
    this.appModeProvider?.mode$.pipe(takeUntil(this.destroyed$)).subscribe((appMode: AppMode) => {
      if (appMode === AppMode.Builder) {
        this.addCover();
      } else {
        this.removeCover();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.removeCover();
  }

  private addCover(): void {
    const div: HTMLDivElement = this.renderer.createElement('div');

    div.style.top = '0';
    div.style.right = '0';
    div.style.bottom = '0';
    div.style.left = '0';
    div.style.position = 'absolute';

    this.parentElement.style.position = 'relative';

    this.renderer.appendChild(this.parentElement, div);

    this.coverDiv = div;
  }

  private removeCover(): void {
    if (this.coverDiv) {
      this.renderer.removeChild(this.parentElement, this.coverDiv);
      this.coverDiv = null!;
    }
  }
}
