import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { CardBodyComponent } from './card-body.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [CardBodyComponent],
  exports: [CardBodyComponent],
})
export class CardBodyModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: CardBodyComponent,
    schema: require('./card-body.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./card-body.calculator.svg').default,
        componentTree: require('!!raw-loader?!./card-body.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./card-body.component-tree-active.svg').default,
      },
    },
  };
}
