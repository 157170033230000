import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { SmartTableColumn } from '@uibakery/fields-types';

import { SmartTableRow, WithEdit } from '../smart-table.models';

@Component({
  selector: 'ub-smart-table-body-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cell.scss', './smart-table-body-cell.component.scss'],
  template: `
    <span *ngIf="!showInput"> {{ value }} </span>
    <input
      *ngIf="showInput"
      fullWidth
      fieldSize="small"
      (click)="$event.stopPropagation()"
      (dblclick)="$event.stopPropagation()"
      (keyup.enter)="onInputEnter()"
      nbInput
      type="text"
      [formControl]="valueControl"
    />
  `,
})
export class SmartTableBodyCellComponent {
  private _row!: SmartTableRow;
  editMode: boolean = false;

  @Input() editModeActive: boolean = false;

  get showInput(): boolean {
    return this.editMode && this.editModeActive;
  }

  @Input() column!: SmartTableColumn;

  @Input() set rowWithEdit(rowWithEdit: WithEdit<SmartTableRow>) {
    this._row = rowWithEdit.__SMART_TABLE_ROW_EDITED_DATA__;
    this.editMode = rowWithEdit.__SMART_TABLE_ROW_EDIT_STATUS__;
    this.valueControl.setValue(this._row[this.column?.prop], { emitEvent: false });
  }

  readonly valueControl: FormControl = new FormControl('');

  get value(): string {
    return this.valueControl.value;
  }

  @Output() valueChange: Observable<string> = this.valueControl.valueChanges;
  @Output() editConfirm: EventEmitter<string> = new EventEmitter<string>();

  onInputEnter(): void {
    this.editConfirm.emit(this.valueControl.value);
  }
}
