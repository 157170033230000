import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbLayoutModule, NbSidebarModule } from '@nebular/theme';
import { ComponentsModule } from '@uibakery/components';
import { ComponentRendererFactory, CoreModule } from '@uibakery/core';

import { UIPropertyDirective } from './directives/ui-property.directive';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { ColorPipe } from './pipes/color.pipe';
import { ResponsivePipe } from './pipes/responsive.pipe';
import { AppComponentRendererFactory } from './renderer-factorty.service';

@NgModule({
  declarations: [ResponsivePipe, ColorPipe, LayoutComponent, HeaderComponent, SidebarComponent, UIPropertyDirective],
  imports: [NbLayoutModule, NbSidebarModule, CommonModule, CoreModule, RouterModule, ComponentsModule],
  providers: [{ provide: ComponentRendererFactory, useClass: AppComponentRendererFactory }],
  exports: [
    ColorPipe,
    ResponsivePipe,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    UIPropertyDirective,
    CoreModule,
    ComponentsModule,
  ],
})
export class SharedModule {}
