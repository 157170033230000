import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { BaseComponent, ComponentInitService, CREATE_REASON, RenderComponent, SlotComponent } from '@uibakery/core';
import { AlignSelfValue, FlexContainerProperty, FlexProperty, SizeProperty } from '@uibakery/fields-types';

import { appearAnimation } from './appear-animation';

@Component({
  selector: 'ub-space',
  styleUrls: ['./space.component.scss'],
  template: `
    <ub-slot
      cdkScrollable
      name="content"
      [nbSpinner]="showLoading"
      [style.overflowX]="overflow?.overflowX"
      [style.overflowY]="overflow?.overflowY"
      [style.flexDirection]="flexDirection"
      [style.flexWrap]="flexWrap"
      [style.justifyContent]="justifyContent"
      [style.alignContent]="alignContent"
      [style.alignItems]="alignItems"
      [content]="content"
      (click)="spaceClick.emit()"
    >
      <ng-content></ng-content>
    </ub-slot>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [appearAnimation],
})
export class SpaceComponent extends BaseComponent implements AfterViewInit {
  @Input() content?: RenderComponent[];

  @Input() visible: boolean = true;

  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };

  private _size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  @Input() set size(size: SizeProperty) {
    this._size = size;
  }
  get size(): SizeProperty {
    return this.rootSpace ? {} : this._size;
  }

  @Input() overflow: { overflowX: string; overflowY: string } = {
    overflowX: 'visible',
    overflowY: 'visible',
  };

  @Input() backgroundColor: string = 'unset';

  @Input() backgroundImage: string = '';

  @Input() flexContainer: FlexContainerProperty = {
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  };

  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @Input() showLoading: boolean = false;

  @Output() spaceClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(SlotComponent, { read: ElementRef, static: true }) slotElement!: ElementRef;

  private rootSpace: boolean = false;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    cd: ChangeDetectorRef,
    initService: ComponentInitService,
  ) {
    super(cd, initService);
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.padding')
  get padding(): string | undefined {
    return this.spacing?.paddings;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return this.size?.width;
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return this.size?.height;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @HostBinding('class.has-width')
  get hasWidth(): boolean {
    return !!this.size?.width && this.size?.width !== 'auto';
  }

  @HostBinding('class.has-height')
  get hasHeight(): boolean {
    return !!this.size?.height && this.size?.height !== 'auto';
  }

  @HostBinding('style.background')
  get backgroundColorStyle(): string {
    return this.backgroundImage || this.backgroundColor;
  }

  @HostBinding('style.margin')
  get margins(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.padding')
  get paddings(): string | undefined {
    return this.spacing?.paddings;
  }

  @HostBinding('@appearAnimation')
  get appearAnimation(): 'animate' | undefined {
    return this[CREATE_REASON] === 'paste' ? 'animate' : undefined;
  }

  /**
   * https://akveo.myjetbrains.com/youtrack/issue/UIB_DEV_UB-3553
   * https://akveo.myjetbrains.com/youtrack/issue/UIB_DEV_UB-3591
   * https://akveo.myjetbrains.com/youtrack/issue/UIB_DEV_UB-3568
   * https://akveo.myjetbrains.com/youtrack/issue/UIB_DEV_UB-3573
   * Without HostBinding flex properties on host, height of children is broken.
   * Other possible solution is replace `<ub-slot></ub-slot>`
   * with `<ng-template ubSlot></ng-template>`.
   * But currently it is impossible, because Angular has limitations
   * with setting directive on host element. And we use `[cdkScrollable]`.
   */
  @HostBinding('style.flexDirection')
  get flexDirection(): string | undefined {
    return this.flexContainer?.flexDirection;
  }

  @HostBinding('style.flexWrap')
  get flexWrap(): string | undefined {
    return this.flexContainer?.flexWrap;
  }

  @HostBinding('style.justifyContent')
  get justifyContent(): string | undefined {
    return this.flexContainer?.justifyContent;
  }

  @HostBinding('style.alignContent')
  get alignContent(): string | undefined {
    return this.flexContainer?.alignContent;
  }

  @HostBinding('style.alignItems')
  get alignItems(): string | undefined {
    return this.flexContainer?.alignItems;
  }

  ngAfterViewInit(): void {
    this.updateRootClass();
  }

  /**
    Why don't just inject parent slot and use HostBinding?

    1. Angular content projection do not allow inject parent slot:
    <ub-slot name='root'>
       <ub-space>
         <ub-space></ub-space>
       </ub-space>
    </ub-slot>
    Both spaces will get root slot if try to inject UbSlotComponent.

    2. HostBinding doesn't triggered by cd.detectChanges(), because it belongs to the host.
  */
  private updateRootClass(): void {
    const parent: HTMLElement = this.elRef.nativeElement.parentElement;
    const isRoot: boolean = parent?.tagName === 'UB-SLOT' && parent?.getAttribute('name') === 'root';
    if (isRoot) {
      this.rootSpace = true;
      this.renderer.addClass(this.elRef.nativeElement, 'root-space');
    }
  }
}
