import { NgModule } from '@angular/core';

import { ComponentModule } from '@uibakery/core';

import { AccordionItemComponent } from './accordion/accordion-item/accordion-item.component';
import { AccordionItemModule } from './accordion/accordion-item/accordion-item.module';
import { AccordionComponent } from './accordion/accordion/accordion.component';
import { AccordionModule } from './accordion/accordion/accordion.module';
import { BubbleMapComponent } from './bubble-map/bubble-map.component';
import { BubbleMapModule } from './bubble-map/bubble-map.module';
import { ButtonComponent } from './button/button.component';
import { ButtonModule } from './button/button.module';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule } from './calendar/calendar.module';
import { CardBodyComponent } from './card/card-body/card-body.component';
import { CardBodyModule } from './card/card-body/card-body.module';
import { CardFooterComponent } from './card/card-footer/card-footer.component';
import { CardFooterModule } from './card/card-footer/card-footer.module';
import { CardHeaderComponent } from './card/card-header/card-header.component';
import { CardHeaderModule } from './card/card-header/card-header.module';
import { CardComponent } from './card/card/card.component';
import { CardModule } from './card/card/card.module';
import { ChartComponent } from './chart/chart.component';
import { ChartModule } from './chart/chart.module';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxModule } from './checkbox/checkbox.module';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatepickerModule } from './datepicker/datepicker.module';
import { DividerComponent } from './divider/divider.component';
import { DividerModule } from './divider/divider.module';
import { HeadingComponent } from './heading/heading.component';
import { HeadingModule } from './heading/heading.module';
import { IconComponent } from './icon/icon.component';
import { IconModule } from './icon/icon.module';
import { IframeComponent } from './iframe/iframe.component';
import { IframeModule } from './iframe/iframe.module';
import { ImageComponent } from './image/image.component';
import { ImageModule } from './image/image.module';
import { InputComponent } from './input/input.component';
import { InputModule } from './input/input.module';
import { LayoutHeaderComponent } from './layout/layout-header/layout-header.component';
import { LayoutHeaderModule } from './layout/layout-header/layout-header.module';
import { LayoutSidebarComponent } from './layout/layout-sidebar/layout-sidebar.component';
import { LayoutSidebarModule } from './layout/layout-sidebar/layout-sidebar.module';
import { LinkComponent } from './link/link.component';
import { LinkModule } from './link/link.module';
import { ListItemComponent } from './list/list-item/list-item.component';
import { ListItemModule } from './list/list-item/list-item.module';
import { ListComponent } from './list/list/list.component';
import { ListModule } from './list/list/list.module';
import { MapComponent } from './map/map.component';
import { MapModule } from './map/map.module';
import { MenuComponent } from './menu/menu.component';
import { MenuModule } from './menu/menu.module';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressBarModule } from './progress-bar/progress-bar.module';
import { RadioComponent } from './radio/radio.component';
import { RadioModule } from './radio/radio.module';
import { SelectComponent } from './select/select.component';
import { SelectModule } from './select/select.module';
import { SmartTableComponent } from './smart-table/smart-table.component';
import { SmartTableModule } from './smart-table/smart-table.module';
import { SpaceComponent } from './space/space.component';
import { SpaceModule } from './space/space.module';
import { StepperItemComponent } from './stepper/stepper-item/stepper-item.component';
import { StepperItemModule } from './stepper/stepper-item/stepper-item.module';
import { StepperComponent } from './stepper/stepper/stepper.component';
import { StepperModule } from './stepper/stepper/stepper.module';
import { TableHeaderCellComponent } from './table/table-header-cell/table-header-cell.component';
import { TableHeaderCellModule } from './table/table-header-cell/table-header-cell.module';
import { TableHeaderComponent } from './table/table-header/table-header.component';
import { TableHeaderModule } from './table/table-header/table-header.module';
import { TableRowCellComponent } from './table/table-row-cell/table-row-cell.component';
import { TableRowCellModule } from './table/table-row-cell/table-row-cell.module';
import { TableRowComponent } from './table/table-row/table-row.component';
import { TableRowModule } from './table/table-row/table-row.module';
import { TableComponent } from './table/table/table.component';
import { TableModule } from './table/table/table.module';
import { TabComponent } from './tabset/tab/tab.component';
import { TabModule } from './tabset/tab/tab.module';
import { TabsComponent } from './tabset/tabs/tabs.component';
import { TabsModule } from './tabset/tabs/tabs.module';
import { TextComponent } from './text/text.component';
import { TextModule } from './text/text.module';
import { TextareaComponent } from './textarea/textarea.component';
import { TextareaModule } from './textarea/textarea.module';
import { ToggleComponent } from './toggle/toggle.component';
import { ToggleModule } from './toggle/toggle.module';

export const components: ComponentModule[] = [
  AccordionModule,
  AccordionItemModule,
  ButtonModule,
  CardModule,
  CardHeaderModule,
  CardBodyModule,
  CardFooterModule,
  SpaceModule,
  TabsModule,
  TabModule,
  TextModule,
  LayoutHeaderModule,
  LayoutSidebarModule,
  MenuModule,
  SmartTableModule,
  HeadingModule,
  LinkModule,
  IconModule,
  ImageModule,
  ChartModule,
  InputModule,
  TableModule,
  TableRowModule,
  TableRowCellModule,
  TableHeaderModule,
  TableHeaderCellModule,
  ProgressBarModule,
  TextareaModule,
  CheckboxModule,
  ToggleModule,
  SelectModule,
  IframeModule,
  RadioModule,
  MapModule,
  ListModule,
  ListItemModule,
  StepperItemModule,
  StepperModule,
  DividerModule,
  DatepickerModule,
  CalendarModule,
  BubbleMapModule,
];

@NgModule({
  imports: components,
  exports: components,
})
export class ComponentsModule {}

// Re-Export Modules
export {
  AccordionModule,
  AccordionItemModule,
  ButtonModule,
  CardModule,
  CardHeaderModule,
  CardBodyModule,
  CardFooterModule,
  SpaceModule,
  TabsModule,
  TabModule,
  TextModule,
  LayoutHeaderModule,
  LayoutSidebarModule,
  MenuModule,
  SmartTableModule,
  HeadingModule,
  LinkModule,
  IconModule,
  ImageModule,
  ChartModule,
  InputModule,
  TableModule,
  TableRowModule,
  TableRowCellModule,
  TableHeaderModule,
  TableHeaderCellModule,
  ProgressBarModule,
  TextareaModule,
  CheckboxModule,
  ToggleModule,
  SelectModule,
  IframeModule,
  RadioModule,
  MapModule,
  ListModule,
  ListItemModule,
  StepperItemModule,
  StepperModule,
  DividerModule,
  DatepickerModule,
  CalendarModule,
  BubbleMapModule,
};

// Re-Export Components
export {
  AccordionComponent,
  AccordionItemComponent,
  ButtonComponent,
  CardComponent,
  CardHeaderComponent,
  CardBodyComponent,
  CardFooterComponent,
  SpaceComponent,
  TabsComponent,
  TabComponent,
  TextComponent,
  LayoutHeaderComponent,
  LayoutSidebarComponent,
  MenuComponent,
  SmartTableComponent,
  HeadingComponent,
  LinkComponent,
  IconComponent,
  ImageComponent,
  ChartComponent,
  InputComponent,
  TableComponent,
  TableRowComponent,
  TableRowCellComponent,
  TableHeaderComponent,
  TableHeaderCellComponent,
  ProgressBarComponent,
  TextareaComponent,
  CheckboxComponent,
  ToggleComponent,
  SelectComponent,
  IframeComponent,
  RadioComponent,
  MapComponent,
  ListComponent,
  ListItemComponent,
  StepperItemComponent,
  StepperComponent,
  DividerComponent,
  DatepickerComponent,
  CalendarComponent,
  BubbleMapComponent,
};
