import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbMenuModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { MenuComponent } from './menu.component';

@NgModule({
  imports: [CoreModule, CommonModule, NbMenuModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
})
export class MenuModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: MenuComponent,
    schema: require('./menu.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./menu.calculator.svg').default,
        componentTree: require('!!raw-loader?!./menu.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./menu.component-tree-active.svg').default,
      },
    },
  };
}
