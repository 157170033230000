import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { CardFooterComponent } from './card-footer.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [CardFooterComponent],
  exports: [CardFooterComponent],
})
export class CardFooterModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: CardFooterComponent,
    schema: require('./card-footer.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./card-footer.calculator.svg').default,
        componentTree: require('!!raw-loader?!./card-footer.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./card-footer.component-tree-active.svg').default,
      },
    },
  };
}
