import { AfterViewInit, Directive, Inject, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BAKERY_COMPONENT, BaseComponent } from '@uibakery/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AppActions from '../../state/app.actions';
import * as fromApp from '../../state/app.reducer';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[uiProperty]',
})
export class UIPropertyDirective implements AfterViewInit, OnDestroy {
  @Input() uiPropertyName: string;
  @Input('uiProperty') properties: Record<string, string>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store<fromApp.State>, @Inject(BAKERY_COMPONENT) private component: BaseComponent) {}

  ngAfterViewInit() {
    for (const [propertyName, outputName] of Object.entries(this.properties)) {
      this.listen(propertyName, outputName);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private listen(propertyName: string, outputName: string): void {
    this.component?.[outputName]?.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.store.dispatch(AppActions[this.uiPropertyName]({ param: { [propertyName]: value } }));
    });
  }
}
