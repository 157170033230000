import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { ImageComponent } from './image.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  exports: [ImageComponent],
  declarations: [ImageComponent],
})
export class ImageModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: ImageComponent,
    schema: require('./image.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./image.calculator.svg').default,
        componentTree: require('!!raw-loader?!./image.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./image.component-tree-active.svg').default,
      },
    },
  };
}
