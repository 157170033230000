<ng-container *ngIf="variation === 'raw'">
  <ng-container *ngTemplateOutlet="smartTable"></ng-container>
</ng-container>

<ng-container *ngIf="variation === 'card'">
  <nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <ng-container *ngTemplateOutlet="smartTable"></ng-container>
    </nb-card-body>
  </nb-card>
</ng-container>

<ng-template #smartTable>
  <ng-container *ngIf="smartTableData$ | async as data">
    <ngx-datatable
      ubComponentShield
      [columnMode]="ColumnMode.force"
      [rows]="data.rows"
      [externalPaging]="true"
      [scrollbarH]="true"
      [footerHeight]="'auto'"
      [headerHeight]="'auto'"
      [rowHeight]="'100%'"
      [summaryRow]="totalRowConfig?.show"
      [summaryPosition]="totalRowConfig?.position"
      [ngClass]="totalRowClasses"
      [rowIdentity]="rowIdentity"
      [count]="data.pageInfo.count"
      [sortType]="SortType.single"
      [externalSorting]="true"
      (sort)="onColumnSort($event)"
      [selectionType]="SelectionType.single"
      [selected]="data.selectedRows"
      [selectCheck]="selectCheck"
      (select)="onRowSelect($event)"
      [messages]="{
        emptyMessage: 'No data found'
      }"
    >
      <ngx-datatable-column
        *ngFor="let columnWithAdditionInfo of data.columns; let index = index; trackBy: trackByForColumns"
        [name]="columnWithAdditionInfo.column.name"
        [prop]="columnWithAdditionInfo.column.prop"
        [width]="columnWithAdditionInfo.column.width"
        [sortable]="true"
        [summaryTemplate]="summaryTemplate"
      >
        <ng-template
          ngx-datatable-header-template
          let-tableColumn="column"
          let-sortFunction="sortFn"
          let-sortDirection="sortDir"
        >
          <ub-smart-table-header-cell
            [class.with-left-border]="withLeftBorder(index)"
            [class.with-right-border]="withRightBorder(index, data.columns)"
            [sortDirection]="sortDirection"
            [column]="columnWithAdditionInfo.column"
            [tableColumn]="tableColumn"
            (changeSort)="sortFunction()"
          >
          </ub-smart-table-header-cell>
          <ub-smart-table-header-data-cell
            [class.with-left-border]="withLeftBorder(index)"
            [class.with-right-border]="withRightBorder(index, data.columns)"
            [withAdditionRow]="(data.showAdditionRow && tableActions.showFilter) || tableActions.add"
            [withFilter]="tableActions.showFilter"
            [addModeActive]="tableActions.add && addModeActive"
            [value]="headerValues[columnWithAdditionInfo.column.prop]"
            [column]="columnWithAdditionInfo.column"
            (valueChange)="onHeaderValueChange($event, columnWithAdditionInfo.column)"
            (addConfirm)="onNewRowConfirm($event, columnWithAdditionInfo.column)"
          >
          </ub-smart-table-header-data-cell>
        </ng-template>

        <ng-template ngx-datatable-cell-template let-rowWithEdit="row" let-rowIndex="rowIndex" let-tableColumn="column">
          <ub-smart-table-body-cell
            [class.with-left-border]="withLeftBorder(index)"
            [class.with-right-border]="withRightBorder(index, data.columns)"
            [editModeActive]="tableActions.edit"
            [column]="columnWithAdditionInfo.column"
            [rowWithEdit]="rowWithEdit"
            (valueChange)="onRowValueChanged($event, columnWithAdditionInfo.column, rowWithEdit[ROW_DATA_KEY])"
            (editConfirm)="onRowEditConfirm($event, columnWithAdditionInfo.column, rowWithEdit[ROW_DATA_KEY])"
          ></ub-smart-table-body-cell>
        </ng-template>

        <ng-template #summaryTemplate>
          <div
            class="summary-content"
            [class.with-left-border]="withLeftBorder(index)"
            [class.with-right-border]="withRightBorder(index, data.columns)"
          >
            {{ columnWithAdditionInfo.summary || 'n/a' }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        *ngIf="showActionColumn"
        [width]="108"
        [maxWidth]="108"
        [minWidth]="108"
        [sortable]="false"
        [frozenRight]="true"
      >
        <ng-template ngx-datatable-header-template let-tableColumn="column">
          <ub-smart-table-header-cell class="action-column"></ub-smart-table-header-cell>
          <ub-smart-table-header-action-cell
            [withAdditionRow]="(data.showAdditionRow && tableActions.showFilter) || tableActions.add"
            [addModeActive]="tableActions.add && addModeActive"
            [withAdd]="tableActions.add"
            (action)="onAddAction($event)"
          ></ub-smart-table-header-action-cell>
        </ng-template>

        <ng-template ngx-datatable-cell-template let-rowWithEdit="row">
          <ub-smart-table-body-action-cell
            [removeModeActive]="tableActions.delete"
            [editModeActive]="tableActions.edit"
            [editMode]="rowWithEdit[ROW_EDIT_STATUS_KEY]"
            (action)="onRowAction($event, rowWithEdit[ROW_DATA_KEY])"
          ></ub-smart-table-body-action-cell>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
          <ub-smart-table-paginator
            [offset]="data.pageInfo.offset"
            [count]="data.pageInfo.count"
            [limit]="data.pageInfo.limit"
            (pageChange)="onPageChanged($event)"
          ></ub-smart-table-paginator>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </ng-container>
</ng-template>
