import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SmartTableRowAction } from '../smart-table.models';

@Component({
  selector: 'ub-smart-table-body-action-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cell.scss', './smart-table-body-action-cell.component.scss'],
  template: `
    <div class="column">
      <button nbButton appearance="ghost" *ngIf="showEditButton" (click)="emitAction($event, SmartTableRowAction.EDIT)">
        <nb-icon icon="edit"></nb-icon>
      </button>
      <button
        nbButton
        *ngIf="showEditSubButtons"
        appearance="ghost"
        (click)="emitAction($event, SmartTableRowAction.EDIT_SAVE)"
      >
        <nb-icon icon="checkmark"></nb-icon>
      </button>
    </div>

    <div class="column with-margin-left" *ngIf="isWithRemove || showEditSubButtons">
      <button nbButton appearance="ghost" *ngIf="isWithRemove" (click)="emitAction($event, SmartTableRowAction.REMOVE)">
        <nb-icon icon="trash-2"></nb-icon>
      </button>
      <button
        nbButton
        appearance="ghost"
        *ngIf="showEditSubButtons"
        (click)="emitAction($event, SmartTableRowAction.EDIT_CANCEL)"
      >
        <nb-icon icon="close"></nb-icon>
      </button>
    </div>
  `,
})
export class SmartTableBodyActionCellComponent {
  readonly SmartTableRowAction: typeof SmartTableRowAction = SmartTableRowAction;

  @Input() removeModeActive: boolean = false;
  @Input() editModeActive: boolean = false;
  @Input() editMode: boolean = false;

  get showEditButton(): boolean {
    return this.editModeActive && !this.editMode;
  }

  get showEditSubButtons(): boolean {
    return this.editModeActive && this.editMode;
  }

  get isWithRemove(): boolean {
    return !this.showEditSubButtons && this.removeModeActive;
  }

  @Output() action: EventEmitter<SmartTableRowAction> = new EventEmitter<SmartTableRowAction>();

  emitAction(event: Event, action: SmartTableRowAction): void {
    event.stopPropagation();
    this.action.emit(action);
  }
}
