import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbToggleModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { ToggleComponent } from './toggle.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbToggleModule, ReactiveFormsModule],
  declarations: [ToggleComponent],
  exports: [ToggleComponent],
})
export class ToggleModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: ToggleComponent,
    schema: require('./toggle.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./toggle.calculator.svg').default,
        componentTree: require('!!raw-loader?!./toggle.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./toggle.component-tree-active.svg').default,
      },
    },
  };
}
