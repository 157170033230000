import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbSelectModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { SelectComponent } from './select.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbSelectModule, ReactiveFormsModule],
  declarations: [SelectComponent],
  exports: [SelectComponent],
})
export class SelectModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: SelectComponent,
    schema: require('./select.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./select.calculator.svg').default,
        componentTree: require('!!raw-loader?!./select.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./select.component-tree-active.svg').default,
      },
    },
  };
}
