import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';

export interface TabComponentInputs {
  name: string;
  title: string;
  icon: string;
}

@Component({
  selector: 'ub-tab',
  template: `
    <ng-content></ng-content>
    <ub-slot name="content" [content]="content"></ub-slot>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent extends BaseComponent implements TabComponentInputs {
  @Input() content?: RenderComponent[];

  @Input() name: string = '';
  @Input() title: string = 'Tab';
  @Input() icon: string = '';
}
