import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { ExternalUrlModule } from '../shared/external-url/external-url.module';
import { InternalUrlModule } from '../shared/internal-url/internal-url.module';
import { LinkComponent } from './link.component';

@NgModule({
  imports: [CommonModule, CoreModule, RouterModule, ExternalUrlModule, InternalUrlModule],
  declarations: [LinkComponent],
  exports: [LinkComponent],
})
export class LinkModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: LinkComponent,
    schema: require('./link.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./link.calculator.svg').default,
        componentTree: require('!!raw-loader?!./link.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./link.component-tree-active.svg').default,
      },
    },
  };
}
