import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NbButtonModule,
  NbCardModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbSelectModule,
} from '@nebular/theme';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { COMPONENT_METADATA, ComponentMetadata, CoreModule } from '@uibakery/core';

import { SmartTableComponent } from './smart-table.component';
import { SmartTableBodyActionCellComponent } from './table-components/smart-table-body-action-cell.component';
import { SmartTableBodyCellComponent } from './table-components/smart-table-body-cell.component';
import { SmartTableHeaderActionCellComponent } from './table-components/smart-table-header-action-cell.component';
import { SmartTableHeaderCellComponent } from './table-components/smart-table-header-cell.component';
import { SmartTableHeaderDataCellComponent } from './table-components/smart-table-header-data-cell.component';
import { SmartTablePaginatorComponent } from './table-components/smart-table-paginator.component';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    NgxDatatableModule,
    NbButtonModule,
    NbInputModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbFormFieldModule,
    NbIconModule,
    NbCardModule,
  ],
  declarations: [
    SmartTableComponent,
    SmartTableBodyActionCellComponent,
    SmartTableBodyCellComponent,
    SmartTableHeaderActionCellComponent,
    SmartTableHeaderCellComponent,
    SmartTableHeaderDataCellComponent,
    SmartTablePaginatorComponent,
  ],
  exports: [SmartTableComponent],
})
export class SmartTableModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: SmartTableComponent,
    schema: require('./smart-table.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./smart-table.calculator.svg').default,
        componentTree: require('!!raw-loader?!./smart-table.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./smart-table.component-tree-active.svg').default,
      },
    },
  };
}
