import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbComponentShape, NbComponentSize, NbComponentStatus } from '@nebular/theme';
import { BehaviorSubject, merge, Observable } from 'rxjs';

import { BAKERY_COMPONENT, BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Component({
  selector: 'ub-textarea',
  styleUrls: ['./textarea.component.scss'],
  template: `
    <textarea
      nbInput
      fullWidth
      [fieldSize]="formFieldSize"
      [formControl]="formControl"
      [rows]="rows"
      [placeholder]="placeholder"
      [status]="status"
      [shape]="shape"
      [class.resize]="resize"
      (keyup.enter)="enter.emit($event.target.value)"
    ></textarea>
  `,
  providers: [{ provide: BAKERY_COMPONENT, useExisting: TextareaComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent extends BaseComponent implements WithVisibleComponent, WithSizeComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };
  @Input() placeholder: string = '';
  @Input() rows: number = 8;
  @Input() resize: boolean = false;
  @Input() formFieldSize: NbComponentSize = 'medium';
  @Input() status: NbComponentStatus = 'basic';
  @Input() shape: NbComponentShape = 'rectangle';
  @Input() size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  formControl: FormControl = new FormControl('');
  @Output() textareaChange: Observable<string> = this.formControl.valueChanges;
  private _uiValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() uiValue: Observable<string> = merge(this._uiValue.asObservable(), this.formControl.valueChanges);
  @Output() enter: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('style.margin')
  get margins(): string {
    return this.spacing?.margins;
  }

  get disabled(): boolean {
    return this.formControl.disabled;
  }

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  get value(): string {
    return this.formControl.value;
  }

  @Input() set value(value: string) {
    this.formControl.setValue(value, { emitEvent: false });
    this._uiValue.next(value);
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return this.size?.width;
  }

  get height(): string | undefined {
    return this.size?.height;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue {
    return this.flex?.alignSelf!;
  }

  @HostBinding('style.order')
  get flexOrder(): number {
    return this.flex?.order!;
  }

  @HostBinding('style.flex')
  get flexChild(): string {
    return this.flex?.flex!;
  }
}
