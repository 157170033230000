import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbCardModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { SharedModule } from '../shared/shared.module';
import { BubbleMapComponent } from './bubble-map.component';

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule, NbCardModule],
  declarations: [BubbleMapComponent],
  exports: [BubbleMapComponent],
})
export class BubbleMapModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: BubbleMapComponent,
    schema: require('./bubble-map.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./bubble-map.calculator.svg').default,
        componentTree: require('!!raw-loader?!./bubble-map.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./bubble-map.component-tree-active.svg').default,
      },
    },
  };
}
