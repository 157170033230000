import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbProgressBarModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { ProgressBarComponent } from './progress-bar.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbProgressBarModule],
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent],
})
export class ProgressBarModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: ProgressBarComponent,
    schema: require('./progress-bar.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./progress-bar.calculator.svg').default,
        componentTree: require('!!raw-loader?!./progress-bar.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./progress-bar.component-tree-active.svg').default,
      },
    },
  };
}
