import { ChangeDetectionStrategy, Component, HostBinding, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { NbTabComponent } from '@nebular/theme';
import { Subject } from 'rxjs';

import { BAKERY_COMPONENT, BaseComponent, RenderComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  TabsVariation,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../../compute-size';
import { TabComponentInputs } from '../tab/tab.component';

@Component({
  selector: 'ub-tabs',
  styleUrls: ['./tabs.component.scss'],
  providers: [{ provide: BAKERY_COMPONENT, useExisting: TabsComponent }],
  template: `
    <ng-container *ngIf="variation === 'raw'">
      <ng-container *ngTemplateOutlet="tabsContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="variation === 'card'">
      <nb-card>
        <nb-card-header *ngIf="title">{{ title }}</nb-card-header>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="tabsContent"></ng-container>
        </nb-card-body>
      </nb-card>
    </ng-container>

    <ng-template #tabsContent>
      <nb-tabset cdkScrollable [style.width.%]="100" [style.height.%]="100" (changeTab)="onTabChanged($event)">
        <nb-tab
          *ngFor="let tab of tabs; trackBy: trackByName"
          [style.padding]="spacing?.paddings"
          [tabTitle]="tab.inputs.title"
          [tabIcon]="tab.inputs.icon"
        >
          <ub-slot [content]="[tab]"></ub-slot>
        </nb-tab>
      </nb-tabset>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent extends BaseComponent implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() tabs?: RenderComponent<TabComponentInputs>[];

  @Input() title: string = 'Tabs';
  @Input() variation: TabsVariation = 'card';

  @Input() visible: boolean = true;
  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };

  @Output() selectedIndexChange: Subject<number> = new Subject<number>();
  @Output() lengthChange: Subject<number> = new Subject<number>();
  @Output() tabsChangeTab: Subject<{ selectedIndex: number; length: number }> = new Subject<{
    selectedIndex: number;
    length: number;
  }>();

  private _tabsetTabs!: QueryList<NbTabComponent>;
  @ViewChildren(NbTabComponent) set tabsetTabs(tabs: QueryList<NbTabComponent>) {
    this._tabsetTabs = tabs;
    this.lengthChange.next(tabs.length);
  }

  get tabsetTabs(): QueryList<NbTabComponent> {
    return this._tabsetTabs;
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  trackByName(index: number, item: RenderComponent<TabComponentInputs>): string {
    return item.inputs.name;
  }

  onTabChanged(tab: NbTabComponent): void {
    const index: number = !tab ? undefined : this.tabsetTabs.toArray().indexOf(tab);
    this.selectedIndexChange.next(index);
    this.tabsChangeTab.next({
      length: this._tabsetTabs.length,
      selectedIndex: index,
    });
  }
}
