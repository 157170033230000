import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbCardModule, NbTabsetModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { TabsComponent } from './tabs.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbTabsetModule, ScrollingModule, NbCardModule],
  declarations: [TabsComponent],
  exports: [TabsComponent],
})
export class TabsModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: TabsComponent,
    schema: require('./tabs.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./tabs.calculator.svg').default,
        componentTree: require('!!raw-loader?!./tabs.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./tabs.component-tree-active.svg').default,
      },
    },
  };
}
