import { EChartOption, graphic } from 'echarts';

import {
  ChartConfig,
  ChartDataFormatType,
  ChartDataItem,
  ChartLegacyDataItem,
  ChartLegend,
  ChartVariation,
  DataObject,
} from '@uibakery/fields-types';

import { getRandomChartColor, to2DArray } from '../chart-config';
import { ChartDataMapper } from '../chart.types';
import { getGrid, getLegendDefaults, getTitleDefaults } from './settings';

import Format = echarts.EChartOption.Tooltip.Format;

const initOptions: EChartOption = {
  tooltip: {
    trigger: 'axis',
    position: 'top',
    backgroundColor: '#223345',
    extraCssText: 'margin-top: -5px;',
    formatter: (config: Format | Format[]) => {
      return (config as Format[])
        .map((item: Format) => `${item.seriesName}: <b>${(item.value as string[])[1]}</b>`)
        .join('</br>');
    },
  },
};

export class LineChartDataMapper implements ChartDataMapper {
  computeOptions(
    config: ChartConfig,
    legend: ChartLegend,
    colorConfig: { [colorName: string]: string },
    variation: ChartVariation,
  ): EChartOption {
    return {
      ...initOptions,
      grid: getGrid(variation),
      series: this.mapSeries(config, colorConfig),
      title: {
        ...getTitleDefaults(variation),
        text: config.title,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
      backgroundColor: colorConfig.bg,
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: colorConfig.axisLineColor,
          },
        },
        axisLabel: {
          color: colorConfig.textColor,
        },
        axisPointer: {
          label: {
            show: true,
            color: colorConfig[(<ChartDataItem>config.data[0])?.color] || colorConfig.primary,
            backgroundColor: 'none',
          },
        },
      },
      yAxis: {
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: colorConfig.splitLineColor,
            type: 'dashed',
          },
        },
        axisLabel: {
          color: colorConfig.textColor,
        },
      },
      legend: {
        ...getLegendDefaults(variation),
        show: legend.visible,
        orient: legend.orientation,
        left: legend.horizontal,
        top: legend.vertical,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
    };
  }

  private mapSeries(config: ChartConfig, colorConfig: { [colorName: string]: string }): DataObject[] {
    return config.data.map((dataItem: ChartDataItem | ChartLegacyDataItem) => {
      let formatDependentOptions: DataObject;

      if (config.dataFormatType === ChartDataFormatType.KEY_VALUE_OBJECT) {
        const newFormatData: ChartDataItem = dataItem as ChartDataItem;
        formatDependentOptions = this.getDataFormatDependentOptions(
          colorConfig[newFormatData.color],
          newFormatData.title,
          to2DArray(newFormatData) as DataObject[] | (number | string)[][],
        );
      } else {
        const legacyFormatData: ChartLegacyDataItem = dataItem as ChartLegacyDataItem;
        formatDependentOptions = this.getDataFormatDependentOptions(
          colorConfig[getRandomChartColor()],
          legacyFormatData.name,
          legacyFormatData.data,
        );
      }

      return {
        ...formatDependentOptions,
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 2.5,
        },
        symbol: 'circle',
        showSymbol: false,
        symbolSize: 5,
      };
    });
  }

  private getDataFormatDependentOptions(
    color: string,
    title: string,
    data: DataObject[] | (number | string)[][],
  ): DataObject {
    return {
      name: title,
      color,
      data,
      itemStyle: {
        borderWidth: 3,
        borderColor: 'white',
        color,
      },
      areaStyle: {
        color: this.getGradient(color),
        opacity: 0.2,
      },
    };
  }

  private getGradient(color: string): DataObject {
    return new graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: color,
      },
      {
        offset: 1,
        color: 'white',
      },
    ]);
  }
}
