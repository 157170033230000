import { NgModule } from '@angular/core';
import { NbSidebarModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { LayoutSidebarComponent } from './layout-sidebar.component';

@NgModule({
  imports: [CoreModule, NbSidebarModule],
  declarations: [LayoutSidebarComponent],
  exports: [LayoutSidebarComponent],
})
export class LayoutSidebarModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: LayoutSidebarComponent,
    schema: require('./layout-sidebar.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./layout-sidebar.calculator.svg').default,
        componentTree: require('!!raw-loader?!./layout-sidebar.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./layout-sidebar.component-tree-active.svg').default,
      },
    },
  };
}
