export interface OldSmartTableColumn {
  title: string;
  filter?: boolean | SmartTableColumnHeaderParam;
  index: number;
}

export interface OldSmartTableColumns {
  [key: string]: OldSmartTableColumn;
}

export interface SmartTableHeaderParamListItem {
  value: string;
  title: string;
}

export interface SmartTableColumnHeaderParamConfig {
  selectText: string;
  list: SmartTableHeaderParamListItem[];
}

export enum SmartTableColumnHeaderParamType {
  NONE = '',
  LIST = 'list',
  INPUT = 'input',
}

export function getColumnHeaderParamType(
  param: SmartTableColumnHeaderParam | boolean,
): SmartTableColumnHeaderParamType {
  if (typeof param === 'boolean') {
    return param ? SmartTableColumnHeaderParamType.INPUT : SmartTableColumnHeaderParamType.NONE;
  }
  if (!!param && !param.type) {
    return SmartTableColumnHeaderParamType.INPUT;
  }
  if (Object.values(SmartTableColumnHeaderParamType).includes(param?.type)) {
    return param.type;
  }
  return SmartTableColumnHeaderParamType.NONE;
}

export interface SmartTableColumnHeaderParam {
  type: SmartTableColumnHeaderParamType;
  config?: SmartTableColumnHeaderParamConfig;
}

export interface SmartTableColumn {
  prop: string;
  name: string;
  primaryKey: boolean;
  filter?: boolean | SmartTableColumnHeaderParam;
  add?: boolean | SmartTableColumnHeaderParam;
  width: number;
}

export enum SmartTableActionsEnum {
  ADD = 'add',
  DELETE = 'delete',
  EDIT = 'edit',
  SHOW_FILTER = 'showFilter',
}

export interface SmartTableActions {
  add: boolean;
  delete: boolean;
  edit: boolean;
  showFilter: boolean;
}

export enum SmartTableTotalRowPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface SmartTableTotalRowConfig {
  show: boolean;
  position: SmartTableTotalRowPosition;
  rowColor: string;
  textStyle: string[];
  textColor: string;
  updateWhenFiltering: boolean;
}
