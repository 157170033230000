import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NbJSThemeOptions, NbThemeService } from '@nebular/theme';
import { EChartOption } from 'echarts';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { BaseComponent, ComponentInitService } from '@uibakery/core';
import {
  ChartConfig,
  ChartDataFormatType,
  ChartDataItem,
  ChartLegacyDataItem,
  ChartLegend,
  ChartType,
  ChartVariation,
  DataObject,
  SizeProperty,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';
import { ChartDataMapper } from './chart.types';
import { BarChartDataMapper } from './mappers/bar-chart-mapper';
import { DoughnutChartDataMapper } from './mappers/doughnut-chart-mapper';
import { LineChartDataMapper } from './mappers/line-chart-mapper';
import { PieChartDataMapper } from './mappers/pie-chart-mapper';

import ECharts = echarts.ECharts;

const DATA_MAPPERS: { [type: string]: ChartDataMapper } = {
  [ChartType.BAR_CHART]: new BarChartDataMapper(),
  [ChartType.LINE_CHART]: new LineChartDataMapper(),
  [ChartType.DOUGHNUT_CHART]: new DoughnutChartDataMapper(),
  [ChartType.PIE_CHART]: new PieChartDataMapper(),
};

@Component({
  selector: 'ub-chart',
  styleUrls: ['./chart.component.scss'],
  template: `
    <ng-container *ngIf="variation === 'raw'">
      <ng-container *ngTemplateOutlet="chart"></ng-container>
    </ng-container>

    <ng-container *ngIf="variation === 'card'">
      <nb-card>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="chart"></ng-container>
        </nb-card-body>
      </nb-card>
    </ng-container>

    <ng-template #chart>
      <div
        echarts
        *ngIf="hasData$ | async; else showState"
        [options]="options$ | async"
        [merge]="mergeOptions$ | async"
        (chartInit)="onChartInit($event)"
      ></div>

      <ng-template #showState>
        <div class="no-data-found">
          <span><nb-icon icon="pie-chart-outline"></nb-icon> No data to display</span>
        </div>
      </ng-template>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent extends BaseComponent
  implements OnInit, OnDestroy, WithSizeComponent, WithVisibleComponent {
  @Input() variation: ChartVariation = 'card';
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() size: SizeProperty = {
    width: '100%',
    height: '400px',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  @Input() set title(title: string) {
    this._title = title;
    this.updateData({ title });
  }

  @Input() set data(data: (ChartDataItem | ChartLegacyDataItem)[]) {
    const nextData: (ChartDataItem | ChartLegacyDataItem)[] = (data ?? []).filter(
      (item: ChartDataItem | ChartLegacyDataItem | undefined) => !!item,
    );

    this.updateData({
      data: nextData,
      dataFormatType: this.getDataFormatType(nextData),
    });
  }

  @Input() set type(type: ChartType) {
    this._type.next(type);
  }

  @Input() set legend(value: ChartLegend) {
    this._legend.next(value);
  }

  constructor(private theme: NbThemeService, public cd: ChangeDetectorRef, initService: ComponentInitService) {
    super(cd, initService);
  }

  private echartsIntance!: ECharts;

  private destroyed$: Subject<void> = new Subject<void>();

  private _title: string = 'Chart';

  private _type: BehaviorSubject<ChartType> = new BehaviorSubject<ChartType>(ChartType.LINE_CHART);
  private type$: Observable<ChartType> = this._type.asObservable();

  private _data: BehaviorSubject<ChartConfig> = new BehaviorSubject<ChartConfig>({
    title: 'Chart',
    dataFormatType: ChartDataFormatType.KEY_VALUE_OBJECT,
    data: [
      {
        title: 'Item 1',
        data: [
          { value: 10, name: 'Germany' },
          { value: 40, name: 'France' },
          { value: 10, name: 'Canada' },
          { value: 65, name: 'Russia' },
          { value: 5, name: 'USA' },
        ],
        color: 'primary',
        xProp: 'name',
        yProp: 'value',
      },
      {
        title: 'Item 2',
        data: [
          { value: 5, name: 'Germany' },
          { value: 25, name: 'France' },
          { value: 95, name: 'Canada' },
          { value: 20, name: 'Russia' },
          { value: 90, name: 'USA' },
        ],
        color: 'info',
        xProp: 'name',
        yProp: 'value',
      },
    ],
  });

  private data$: Observable<ChartConfig> = this._data.asObservable();

  private _legend: BehaviorSubject<ChartLegend> = new BehaviorSubject<ChartLegend>({
    visible: true,
    orientation: 'horizontal',
    horizontal: 'center',
    vertical: 'top',
  });

  private legend$: Observable<ChartLegend> = this._legend.asObservable();

  options$: BehaviorSubject<EChartOption> = new BehaviorSubject<EChartOption>({});
  mergeOptions$: BehaviorSubject<EChartOption> = new BehaviorSubject<EChartOption>({});

  hasData$: Observable<boolean> = this.data$.pipe(
    map((data: ChartConfig) => {
      const noChildren: boolean = !data.data?.length;
      const eachChildMissData: boolean = data.data?.every(
        (item: ChartDataItem | ChartLegacyDataItem) => (item.data?.length ?? 0) === 0,
      );
      return !(noChildren || eachChildMissData);
    }),
  );

  get legend(): ChartLegend {
    return this._legend.getValue();
  }

  get title(): string {
    return this._title;
  }

  get type(): ChartType {
    return this._type.getValue();
  }

  get data(): (ChartDataItem | ChartLegacyDataItem)[] {
    return this._data.getValue().data;
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.display')
  get display(): string {
    return this.visible ? 'block' : 'none';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeOnDataChange();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed$.next();
  }

  onChartInit($event: ECharts): void {
    this.echartsIntance = $event;
    this.subscribeToDoughnutHover();
  }

  private computeChartOptions(
    config: ChartConfig,
    type: ChartType,
    legend: ChartLegend,
    colorConfig: { [colorName: string]: string },
  ): EChartOption {
    return DATA_MAPPERS[type].computeOptions(config, legend, colorConfig, this.variation);
  }

  private updateData(config: Partial<ChartConfig>): void {
    this._data.next({ ...this._data.getValue(), ...config });
  }

  private subscribeOnDataChange(): void {
    combineLatest([this.data$, this.type$, this.legend$, this.theme.getJsTheme()])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([data, type, legend, config]: [ChartConfig, ChartType, ChartLegend, NbJSThemeOptions]) => {
        this.options$.next(
          this.computeChartOptions(data, type, legend, config?.variables?.charts as { [colorName: string]: string }),
        );
      });
  }

  // listen for doughnut `bars` events to increase part size on hover
  private subscribeToDoughnutHover(): void {
    merge(fromEvent(this.echartsIntance, 'mouseover'), fromEvent(this.echartsIntance, 'mouseout'))
      .pipe(
        withLatestFrom(this.type$, this.options$),
        filter(([event, type, options]: [unknown, ChartType, EChartOption]) => type === ChartType.DOUGHNUT_CHART),
        takeUntil(this.destroyed$),
      )
      .subscribe(([event, type, options]: [unknown, ChartType, EChartOption]) => {
        const series: DataObject[] = DATA_MAPPERS[ChartType.DOUGHNUT_CHART].getHoverSeriesStyles!(
          event as { [key: string]: string },
          options,
        );
        this.mergeOptions$.next({ series: series });
        this.cd.detectChanges();
      });
  }

  private getDataFormatType(data: (ChartDataItem | ChartLegacyDataItem)[]): ChartDataFormatType {
    if (!data[0]) {
      return ChartDataFormatType.KEY_VALUE_OBJECT;
    }
    return 'name' in data[0] ? ChartDataFormatType.TUPLE : ChartDataFormatType.KEY_VALUE_OBJECT;
  }
}
