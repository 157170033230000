import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  InjectionToken,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Base } from '../base';
import { ComponentInitService } from '../component-init.service';
import { Slot } from '../slot';
import { SlotComponent } from '../slot/slot.component';

// Special token for components with uiProperties in schema.
// Provides ability for injecting instance in directive without type
export const BAKERY_COMPONENT: InjectionToken<BaseComponent> = new InjectionToken<BaseComponent>('BAKERY_COMPONENT');

@Component({ selector: 'ub-base', template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class BaseComponent extends Base implements OnInit, OnDestroy {
  @ViewChildren(SlotComponent) slots!: QueryList<Slot>;

  private readonly _init$: Subject<void> = new Subject<void>();
  private readonly _destroyed$: Subject<void> = new Subject<void>();
  @Output() init: Observable<void> = merge(this._init$, this.initService.init$).pipe(takeUntil(this._destroyed$));

  constructor(public cd: ChangeDetectorRef, private initService: ComponentInitService) {
    super();
  }

  ngOnInit(): void {
    this._init$.next();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }
}
