import { Pipe, PipeTransform } from '@angular/core';
import { ThemeService } from '@uibakery/core';

@Pipe({
  name: 'color',
})
export class ColorPipe implements PipeTransform {
  constructor(private themeService: ThemeService) {}

  // logic in color compiler must be exact the same as in ColorPipe (codegen)
  transform(value: any): any {
    return this.themeService.getColorVariable(value);
  }
}
