import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbComponentShape, NbComponentSize, NbComponentStatus, NbInputDirective } from '@nebular/theme';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BAKERY_COMPONENT, BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Component({
  selector: 'ub-input',
  styleUrls: ['./input.component.scss'],
  template: `
    <nb-form-field ubComponentShield>
      <nb-icon *ngIf="showStartIcon" nbPrefix pack="eva" [icon]="icon"></nb-icon>
      <input
        nbInput
        fullWidth
        [fieldSize]="formFieldSize"
        [placeholder]="placeholder"
        [type]="type"
        [status]="status"
        [shape]="shape"
        [formControl]="formControl"
        [style.height]="height"
        (keyup.enter)="enter.emit($event.target.value)"
      />
      <nb-icon *ngIf="showEndIcon" nbSuffix pack="eva" [icon]="icon"></nb-icon>
    </nb-form-field>
  `,
  providers: [{ provide: BAKERY_COMPONENT, useExisting: InputComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends BaseComponent implements WithVisibleComponent, WithSizeComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() status: NbComponentStatus = 'basic';
  @Input() shape: NbComponentShape = 'rectangle';
  @Input() formFieldSize: NbComponentSize = 'medium';
  @Input() icon: string = 'star-outline';
  @Input() iconPlacement: 'start' | 'end' | 'none' = 'none';
  @Input() size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  formControl: FormControl = new FormControl('');
  private _uiValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() inputChange: Observable<string> = this.formControl.valueChanges.pipe(
    map((value: string) => this.mapOutputValue(value)),
  );
  @Output() uiValue: Observable<string> = merge(this._uiValue.asObservable(), this.inputChange);
  @Output() enter: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(NbInputDirective, { read: ElementRef }) input: ElementRef;

  @HostBinding('style.margin')
  get margins(): string {
    return this.spacing?.margins;
  }

  get disabled(): boolean {
    return this.formControl.disabled;
  }

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  get value(): string {
    return this.formControl.value;
  }

  @Input() set value(value: string) {
    this.formControl.setValue(value, { emitEvent: false });
    this._uiValue.next(value);
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return this.size?.width;
  }

  get height(): string | undefined {
    return this.size?.height;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue {
    return this.flex?.alignSelf!;
  }

  @HostBinding('style.order')
  get flexOrder(): number {
    return this.flex?.order!;
  }

  @HostBinding('style.flex')
  get flexChild(): string {
    return this.flex?.flex!;
  }

  get showStartIcon(): boolean {
    return this.iconPlacement === 'start';
  }

  get showEndIcon(): boolean {
    return this.iconPlacement === 'end';
  }

  private mapOutputValue(value: string): any {
    if (this.type === 'file') {
      return this.input.nativeElement.files;
    }
    return value;
  }
}
