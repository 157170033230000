import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { HeadingComponent } from './heading.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [HeadingComponent],
  exports: [HeadingComponent],
})
export class HeadingModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: HeadingComponent,
    schema: require('./heading.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./heading.calculator.svg').default,
        componentTree: require('!!raw-loader?!./heading.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./heading.component-tree-active.svg').default,
      },
    },
  };
}
