import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';

const ICONS: Record<string, string> = {
  back: `<svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M13.052 16a.847.847 0 01-.6-.244l-3.219-3.2a.778.778 0 01.011-1.121l3.334-3.2a.857.857 0 011.178 0 .778.778 0 010 1.13l-2.754 2.645 2.649 2.634c.32.318.31.825-.02 1.131a.85.85 0 01-.58.225" id="backa"/></defs><use fill="currentColor" xlink:href="#backa" fill-rule="evenodd"/></svg>`,
  backx2: `<svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.052 16a.847.847 0 01-.6-.244l-3.219-3.2a.778.778 0 01.011-1.121l3.334-3.2a.857.857 0 011.178 0 .778.778 0 010 1.13l-2.754 2.645 2.649 2.634c.32.318.31.825-.02 1.131a.85.85 0 01-.58.225zm-5 0a.847.847 0 01-.6-.244l-3.219-3.2a.778.778 0 01.011-1.121l3.334-3.2a.857.857 0 011.178 0 .778.778 0 010 1.13L8.002 12.01l2.649 2.634c.32.318.31.825-.02 1.131a.85.85 0 01-.58.225z" fill="currentColor" fill-rule="evenodd"/></svg>`,
  forward: `<svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path d="M14.052 16a.847.847 0 01-.6-.244l-3.219-3.2a.778.778 0 01.011-1.121l3.334-3.2a.857.857 0 011.178 0 .778.778 0 010 1.13l-2.754 2.645 2.649 2.634c.32.318.31.825-.02 1.131a.85.85 0 01-.58.225" id="forwarda"/></defs><use fill="currentColor" transform="matrix(-1 0 0 1 25 0)" xlink:href="#forwarda" fill-rule="evenodd"/></svg>`,
  forwardx2: `<svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7.948 16a.847.847 0 00.6-.244l3.218-3.2a.778.778 0 00-.01-1.121l-3.334-3.2a.857.857 0 00-1.178 0 .778.778 0 000 1.13l2.754 2.645-2.65 2.634a.777.777 0 00.02 1.131.85.85 0 00.58.225zm5 0a.847.847 0 00.6-.244l3.218-3.2a.778.778 0 00-.01-1.121l-3.334-3.2a.857.857 0 00-1.178 0 .778.778 0 000 1.13l2.754 2.645-2.65 2.634a.777.777 0 00.02 1.131.85.85 0 00.58.225z" fill="currentColor" fill-rule="evenodd"/></svg>`,
};

@Component({
  selector: 'ub-smart-table-paginator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./smart-table-paginator.component.scss'],
  template: `
    <ng-container *ngIf="isActive">
      <button status="primary" ghost [disabled]="!canPrevious" nbButton (click)="pageChange.emit(0)">
        <nb-icon icon="backx2" pack="ub-smart-table"></nb-icon>
      </button>
      <button status="primary" ghost [disabled]="!canPrevious" nbButton (click)="rollPage(-1)">
        <nb-icon icon="back" pack="ub-smart-table"></nb-icon>
      </button>

      <button
        nbButton
        status="primary"
        class="page-button"
        [appearance]="page === offset ? 'filled' : 'ghost'"
        *ngFor="let page of pagesToDisplay"
        (click)="pageChange.emit(page)"
      >
        {{ page + 1 }}
      </button>

      <button status="primary" ghost [disabled]="!canNext" nbButton (click)="rollPage(1)">
        <nb-icon icon="forward" pack="ub-smart-table"></nb-icon>
      </button>
      <button status="primary" ghost [disabled]="!canNext" nbButton (click)="pageChange.emit(maxPage)">
        <nb-icon icon="forwardx2" pack="ub-smart-table"></nb-icon>
      </button>
    </ng-container>
  `,
})
export class SmartTablePaginatorComponent {
  private readonly PAGE_INDENT: number = 2;
  private readonly AMOUNT_OF_DISPLAYED_PAGES: number = 5;

  @Input() limit: number = 0;
  @Input() count: number = 0;
  @Input() offset: number = 0;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @HostBinding('class.active')
  get isActive(): boolean {
    return this.pageSize > 1;
  }

  get pageSize(): number {
    return this.limit > 0 ? Math.max(0, Math.ceil(this.count / this.limit)) : 0;
  }

  get maxPage(): number {
    return this.pageSize - 1;
  }

  get canPrevious(): boolean {
    return this.offset > 0;
  }

  get canNext(): boolean {
    return this.offset < this.maxPage;
  }

  get pagesToDisplay(): number[] {
    const offset: number = this.offset;
    const itemsToRightBorder: number = this.maxPage - offset;
    const staticPageIndent: number = this.PAGE_INDENT;
    const flexPageIndent: number = staticPageIndent + (staticPageIndent - itemsToRightBorder);
    const pageIndent: number =
      itemsToRightBorder < 2 && this.pageSize > this.AMOUNT_OF_DISPLAYED_PAGES ? flexPageIndent : staticPageIndent;
    const minThreshold: number = Math.max(0, offset - pageIndent);
    const pages: number[] = [];

    for (let currPage: number = minThreshold; currPage < minThreshold + this.AMOUNT_OF_DISPLAYED_PAGES; currPage++) {
      if (currPage === this.pageSize) {
        break;
      }
      pages.push(currPage);
    }

    return pages;
  }

  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerSvgPack('ub-smart-table', ICONS);
  }

  rollPage(direction: number): void {
    this.pageChange.emit(Math.max(0, Math.min(this.maxPage, this.offset + direction)));
  }
}
