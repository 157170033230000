import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NbButtonAppearance, NbComponentSize, NbComponentStatus } from '@nebular/theme';

import { BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  LinkInfo,
  QueryParamsProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Component({
  selector: 'ub-button',
  styleUrls: ['./button.component.scss'],
  template: `
    <button
      *ngIf="!shouldRenderLink"
      nbButton
      [status]="status"
      [size]="formFieldSize"
      [appearance]="appearance"
      [disabled]="disabled"
      (click)="buttonClick.emit()"
      ubComponentShield
    >
      <nb-icon *ngIf="showLeftIcon" [icon]="icon"></nb-icon>
      <ng-container *ngIf="showText">{{ text }}</ng-container>
      <nb-icon *ngIf="showRightIcon" [icon]="icon"></nb-icon>
    </button>

    <ng-container *ngIf="shouldRenderLink">
      <a
        *ngIf="!internalPath"
        nbButton
        [href]="link | externalUrl: queryParams"
        [target]="openInNewTab ? '_blank' : '_top'"
        [status]="status"
        [size]="formFieldSize"
        [appearance]="appearance"
        [disabled]="disabled"
        [class.disabled]="disabled"
        (click)="buttonClick.emit()"
        ubComponentShield
      >
        <nb-icon *ngIf="showLeftIcon" [icon]="icon"></nb-icon>
        <ng-container *ngIf="showText">{{ text }}</ng-container>
        <nb-icon *ngIf="showRightIcon" [icon]="icon"></nb-icon>
      </a>

      <a
        *ngIf="internalPath"
        nbButton
        [routerLink]="link | internalUrl"
        [queryParams]="queryParams"
        [status]="status"
        [size]="formFieldSize"
        [appearance]="appearance"
        [disabled]="disabled"
        [class.disabled]="disabled"
        (click)="buttonClick.emit()"
        ubComponentShield
      >
        <nb-icon *ngIf="showLeftIcon" [icon]="icon"></nb-icon>
        <ng-container *ngIf="showText">{{ text }}</ng-container>
        <nb-icon *ngIf="showRightIcon" [icon]="icon"></nb-icon>
      </a>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends BaseComponent
  implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() linkInfo: LinkInfo = {
    active: 'internalPath',
    internalPath: '',
    externalPath: '',
  };

  get internalPath(): boolean {
    return this.linkInfo.active === 'internalPath';
  }

  get link(): string {
    return this.linkInfo[this.linkInfo.active] || '';
  }

  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() status: NbComponentStatus = 'primary';
  @Input() text: string = 'Button';
  @Input() formFieldSize: NbComponentSize = 'medium';
  @Input() appearance: NbButtonAppearance = 'hero';
  @Input() disabled: boolean = false;
  @Input() icon: string = 'star-outline';
  @Input() iconPlacement: 'left' | 'center' | 'right' | 'none' = 'none';
  @Input() size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: 'auto',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Input() queryParams: QueryParamsProperty = { '': '' };
  @Input() openInNewTab: boolean = false;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter();

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return this.size?.width;
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return this.size?.height;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  get showLeftIcon(): boolean {
    return this.iconPlacement === 'left' || this.iconPlacement === 'center';
  }

  get showRightIcon(): boolean {
    return this.iconPlacement === 'right';
  }

  get showText(): boolean {
    return this.iconPlacement !== 'center';
  }

  get shouldRenderLink(): boolean {
    const queryParamsExist: boolean =
      !!this.queryParams && !!Object.keys(this.queryParams).filter((key: string) => !!key).length;

    return !!this.link || queryParamsExist;
  }
}
