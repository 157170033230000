import { EChartOption } from 'echarts';

import {
  ChartConfig,
  ChartDataFormatType,
  ChartDataItem,
  ChartLegacyDataItem,
  ChartLegend,
  ChartVariation,
  DataObject,
} from '@uibakery/fields-types';

import { toDataObjects, toDataObjectsFromLegacyFormat } from '../chart-config';
import { ChartDataMapper } from '../chart.types';
import { getGrid, getLegendDefaults, getTitleDefaults } from './settings';

const initOptions: EChartOption = {
  tooltip: {
    trigger: 'item',
    formatter: '<span id="tooltip">{b} : <b>{c}</b></span>',
    position: (
      point: (number | string)[],
      params: object | object[],
      element: HTMLElement,
      rect: object,
      size: object,
    ) => {
      const pointsArray: number[] = point as number[];
      const tooltipView: { contentSize: number[] } = size as { contentSize: number[] };
      return [pointsArray[0] - tooltipView.contentSize[0] / 2, pointsArray[1] - tooltipView.contentSize[1] - 10];
    },
    backgroundColor: '#223345',
    extraCssText: 'margin-top: -5px;',
  },
};

export class PieChartDataMapper implements ChartDataMapper {
  computeOptions(
    config: ChartConfig,
    legend: ChartLegend,
    colorConfig: { [colorName: string]: string },
    variation: ChartVariation,
  ): EChartOption {
    return {
      ...initOptions,
      grid: getGrid(variation),
      series: this.mapData(config, colorConfig),
      title: {
        ...getTitleDefaults(variation),
        text: config.title,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
      backgroundColor: colorConfig.bg,
      color: [colorConfig.warning, colorConfig.info, colorConfig.danger, colorConfig.success, colorConfig.primary],
      legend: {
        ...getLegendDefaults(variation),
        show: legend.visible,
        orient: legend.orientation,
        left: legend.horizontal,
        top: legend.vertical,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
    };
  }

  private mapData(config: ChartConfig, colorConfig: { [colorName: string]: string }): DataObject[] {
    const data: DataObject[] = this.getDataFormatDependentOptions(config);

    return [
      {
        data,
        avoidLabelOverlap: true,
        name: '',
        type: 'pie',
        radius: '75%',
        center: ['50%', '50%'],
        itemStyle: {
          normal: {
            label: { show: false },
            labelLine: { show: false },
          },
          emphasis: {
            label: { show: false },
            labelLine: { show: false },
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: colorConfig.itemHoverShadowColor,
          },
        },
        label: {
          normal: {
            label: { show: false },
            labelLine: { show: false },
            textStyle: {
              fontFamily: colorConfig.fontMain,
              color: colorConfig.textColor,
            },
          },
        },
        labelLine: {
          normal: {
            label: { show: false },
            labelLine: { show: false },
            lineStyle: {
              color: colorConfig.axisLineColor,
            },
          },
        },
      },
    ];
  }

  private getDataFormatDependentOptions(config: ChartConfig): DataObject[] {
    const newFormatData: ChartDataItem[] = config.data as ChartDataItem[];
    const legacyFormatData: ChartLegacyDataItem[] = config.data as ChartLegacyDataItem[];

    return config.dataFormatType === ChartDataFormatType.KEY_VALUE_OBJECT
      ? toDataObjects(newFormatData[0])
      : (legacyFormatData[0].data as DataObject[]);
  }
}
