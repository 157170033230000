// @ts-nocheck
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import * as fromApp from '../../state/app.reducer';
import * as AppActions from '../../state/app.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-se-connecter',
  templateUrl: './se-connecter.component.html',
  styleUrls: ['./se-connecter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeConnecterComponent implements OnInit {


  flexContainer = {
        	flexDirection: 'row',
        	alignItems: 'flex-start',
        	justifyContent: 'flex-start',
        	flexWrap: 'wrap',
        	alignContent: 'flex-start'
        };

  flex = {
        	flex: '1 1 0%',
        	alignSelf: 'auto',
        	order: 0
        };

  size = {
        	width: 'auto',
        	height: '100%',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  flexContainer1 = {
        	flexDirection: 'row',
        	alignItems: 'flex-start',
        	justifyContent: 'flex-start',
        	flexWrap: 'wrap',
        	alignContent: 'flex-start'
        };

  spacing = {
        	margins: '',
        	paddings: ''
        };

  size1 = {
        	width: '100%',
        	height: '100%',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow1 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  spacing1 = {
        	xl: {
          	margins: '0 0 0 0',
          	paddings: ''
          },
        	xs: {
          	margins: '30% 0 0 0',
          	paddings: ''
          }
        };

  size2 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  flexContainer2 = {
        	flexDirection: 'row',
        	alignItems: 'stretch',
        	justifyContent: 'flex-start',
        	flexWrap: 'wrap',
        	alignContent: 'stretch'
        };

  flex1 = {
        	flex: '1 1 0%',
        	alignSelf: 'auto',
        	order: 0
        };

  size3 = {
        	width: 'auto',
        	height: '100%',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow2 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  flexContainer3 = {
        	flexDirection: 'row',
        	alignItems: 'center',
        	justifyContent: 'center',
        	flexWrap: 'wrap',
        	alignContent: 'center'
        };

  size4 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow3 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  visible = {
        	xl: true,
        	xs: true
        };

  size5 = {
        	xl: {
          	width: 'auto',
          	height: '48px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	lg: {
          	width: '40%',
          	height: '48px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	xs: {
          	width: '100%',
          	height: '48px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          }
        };

  src = {
        	xl: 'https://sauibakeryprod.blob.core.windows.net/assets-container/assets/e8thp56mtg/images/XzMyXdI1HD',
        	lg: 'https://sauibakeryprod.blob.core.windows.net/assets-container/assets/e8thp56mtg/images/ONfWNgY07t',
        	xs: 'https://sauibakeryprod.blob.core.windows.net/assets-container/assets/e8thp56mtg/images/w9Axky51r3'
        };

  fit = {
        	xl: 'contain',
        	lg: 'contain',
        	xs: 'contain'
        };

  flexContainer4 = {
        	flexDirection: 'row',
        	alignItems: 'center',
        	justifyContent: 'center',
        	flexWrap: 'wrap',
        	alignContent: 'center'
        };

  size6 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow4 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  spacing2 = {
        	margins: '0 0 0 0',
        	paddings: ''
        };

  flexContainer5 = {
        	flexDirection: 'column',
        	alignItems: 'flex-start',
        	justifyContent: 'flex-start',
        	flexWrap: 'wrap',
        	alignContent: 'flex-start'
        };

  flex2 = {
        	flex: '1 1 0%',
        	alignSelf: 'auto',
        	order: 0
        };

  size7 = {
        	width: 'auto',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow5 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  spacing3 = {
        	margins: '0 0 4px 0',
        	paddings: ''
        };

  textStyle = {
        	transform: {
        		dataValue: ''
        	}
        };

  size8 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  spacing4 = {
        	margins: '0 0 4px 0',
        	paddings: ''
        };

  spacing5 = {
        	margins: '0 0 20px 0',
        	paddings: ''
        };

  size9 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  linkInfo = {
        	active: 'internalPath',
        	internalPath: '',
        	externalPath: ''
        };

  queryParams = {
        	'': ''
        };

  textStyle1 = {
        	transform: 'none'
        };

  flexContainer6 = {
        	flexDirection: 'column',
        	alignItems: 'center',
        	justifyContent: 'flex-start',
        	flexWrap: 'wrap',
        	alignContent: 'center'
        };

  flex3 = {
        	flex: '1 1 0%',
        	alignSelf: 'auto',
        	order: 0
        };

  size10 = {
        	width: 'auto',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow6 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  size11 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: 'auto',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  paddings = {
        	xl: '16px 16px 16px 16px',
        	sm: '16px 16px 16px 16px',
        	xs: '8px 8px 8px 8px'
        };
  constructor(private store: Store<fromApp.State>) { }


  cardInit ($event) {
    this.store.dispatch(AppActions.listeDesUtilisateurs({ param: $event }));
  }

  buttonButtonClick ($event) {
    this.store.dispatch(AppActions.checkUserValid({ param: $event }));
  }
  ngOnInit(): void {
  }

}
