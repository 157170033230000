import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import { SlotBaseDirective } from './slot.base';

@Component({
  selector: 'ub-slot',
  template: `
    <ng-template></ng-template>
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlotComponent extends SlotBaseDirective {
  @ViewChild(TemplateRef, { read: ViewContainerRef, static: true }) vcr!: ViewContainerRef;
}
