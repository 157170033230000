import { Injectable } from '@angular/core';

import { ComponentMetadataAssets, ComponentSchemaWithAssets } from './component-metadata';
import { ComponentFactory } from './component-type';
import { ComponentInput, ComponentMetaInfo, Schema, SchemaResolver } from './schema';

export interface ComponentDefinition {
  componentFactory: ComponentFactory;
  schema: Schema;
  assets: ComponentMetadataAssets;
}

@Injectable({ providedIn: 'root' })
export class ComponentRegistry implements SchemaResolver {
  private definitions: ComponentDefinition[] = [];

  getSchemaByDefinitionId(definitionId: string): Schema {
    return this.getComponentSchema(definitionId);
  }

  install(definitions: ComponentDefinition[]): void {
    this.definitions.push(...definitions);
  }

  getComponentFactory(id: string): ComponentFactory {
    return this.getDefinition(id).componentFactory;
  }

  getComponentSchema<T>(id: string): Schema {
    return this.getDefinition(id).schema;
  }

  getComponentsSchemas(): Schema[] {
    return this.definitions.map((definition: ComponentDefinition): Schema => definition.schema);
  }

  getComponentsSchemasWithAssets(): ComponentSchemaWithAssets[] {
    return this.definitions.map((definition: ComponentDefinition) => ({
      schema: definition.schema,
      assets: definition.assets,
    }));
  }

  getComponentsMetaInfo(): ComponentMetaInfo[] {
    return this.definitions.map(
      (definition: ComponentDefinition): ComponentMetaInfo => ({ ...definition.schema.metaInfo }),
    );
  }

  getPropsWithData(definitionId: string): ComponentInput[] {
    const schema: Schema = this.getComponentSchema(definitionId);
    return schema.inputs.filter((prop: ComponentInput) => prop.data);
  }

  private getDefinition(id: string): ComponentDefinition {
    const definition: ComponentDefinition | undefined = this.definitions.find(
      (d: ComponentDefinition): boolean => d.schema.id === id,
    );

    if (!definition) {
      throw new Error(`Can't find definition for definitionId: ${id}`);
    }

    return definition;
  }

  getAll(): Schema[] {
    return this.definitions.map((d: ComponentDefinition) => d.schema);
  }
}
