import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';

@Component({
  selector: 'ub-list-item',
  template: ` <ub-slot [content]="content">
    <ng-content></ng-content>
  </ub-slot>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent extends BaseComponent {
  @Input() content: RenderComponent[] = [];
  @Input() name: string = '';
}
