import { NgModule } from '@angular/core';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { TableRowCellComponent } from './table-row-cell.component';

@NgModule({
  imports: [CoreModule],
  declarations: [TableRowCellComponent],
  exports: [TableRowCellComponent],
})
export class TableRowCellModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: TableRowCellComponent,
    schema: require('./table-row-cell.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!../table/table.calculator.svg').default,
        componentTree: require('!!raw-loader?!../table/table.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!../table/table.component-tree-active.svg').default,
      },
    },
  };
}
