import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbIconModule, NbTooltipModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { IconComponent } from './icon.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbIconModule, NbTooltipModule],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class IconModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: IconComponent,
    schema: require('./icon.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./icon.calculator.svg').default,
        componentTree: require('!!raw-loader?!./icon.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./icon.component-tree-active.svg').default,
      },
    },
  };
}
