import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';

@Component({
  selector: 'ub-image',
  template: `
    <img
      draggable="false"
      [src]="src || 'assets/images/default.svg'"
      [style.border-radius]="radius"
      [style.object-fit]="fit"
      [style.width]="imageWidth"
      [style.height.%]="100"
      (click)="imageClick.emit()"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent extends BaseComponent
  implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() src: string | undefined;
  @Input() radius: string = '0px';
  @Input() fit: string = 'fill';
  @Input() size: SizeProperty = {
    width: '200px',
    height: '200px',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 0 auto', alignSelf: 'auto', order: 0 };

  @Output() imageClick: EventEmitter<void> = new EventEmitter();

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  get imageWidth(): string | undefined {
    return this.width === 'auto' ? 'auto' : '100%';
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }
}
