import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';

export interface StepperItemInputs {
  title: string;
  name: string;
}

@Component({
  selector: 'ub-stepper-item',
  template: `
    <ng-content></ng-content>
    <ub-slot name="content" [content]="content"></ub-slot>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperItemComponent extends BaseComponent implements StepperItemInputs {
  @Input() content: RenderComponent[] = [];
  @Input() title: string = 'Step';
  @Input() name: string = '';
}
