import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbComponentShape, NbComponentSize, NbComponentStatus } from '@nebular/theme';
import isValid from 'date-fns/isValid';
import { BehaviorSubject, merge, Observable } from 'rxjs';

import { BAKERY_COMPONENT, BaseComponent, ComponentInitService } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Component({
  selector: 'ub-datepicker',
  styleUrls: ['./datepicker.component.scss'],
  template: `
    <nb-form-field ubComponentShield>
      <input
        fullWidth
        nbInput
        [nbDatepicker]="datepicker"
        [fieldSize]="formFieldSize"
        [placeholder]="placeholder"
        [status]="status"
        [shape]="shape"
        [formControl]="formControl"
      />
      <nb-datepicker #datepicker> </nb-datepicker>
    </nb-form-field>
  `,
  providers: [{ provide: BAKERY_COMPONENT, useExisting: DatepickerComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent extends BaseComponent implements WithVisibleComponent, WithSizeComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };
  @Input() placeholder: string = 'Enter Date';
  @Input() status: NbComponentStatus = 'basic';
  @Input() shape: NbComponentShape = 'rectangle';
  @Input() formFieldSize: NbComponentSize = 'medium';
  @Input() size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  formControl: FormControl = new FormControl(undefined);

  @Output() change: Observable<string> = this.formControl.valueChanges;
  private _uiValue: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  @Output() uiValue: Observable<string> = merge(this._uiValue.asObservable(), this.formControl.valueChanges);

  @HostBinding('style.margin')
  get margins(): string {
    return this.spacing?.margins;
  }

  get disabled(): boolean {
    return this.formControl.disabled;
  }

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  get value(): Date | number | string {
    return this.formControl.value;
  }

  @Input() set value(value: Date | number | string) {
    const date: Date = new Date(value);

    if (isValid(date)) {
      this.formControl.setValue(date, { emitEvent: false });
    } else {
      this.formControl.setValue(undefined, { emitEvent: false });
    }
    this._uiValue.next(this.formControl.value);
  }

  constructor(cd: ChangeDetectorRef, initService: ComponentInitService) {
    super(cd, initService);

    /**
     * FormControl(undefined) still has null value
     * This code is required for component to pass default field value test
     * */
    this.formControl.setValue(undefined);
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return this.size?.width;
  }

  get height(): string | undefined {
    return this.size?.height;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue {
    return this.flex?.alignSelf!;
  }

  @HostBinding('style.order')
  get flexOrder(): number {
    return this.flex?.order!;
  }

  @HostBinding('style.flex')
  get flexChild(): string {
    return this.flex?.flex!;
  }
}
