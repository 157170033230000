import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NbCardModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { MapComponent } from './map.component';

@NgModule({
  imports: [CommonModule, CoreModule, LeafletModule, NbCardModule],
  declarations: [MapComponent],
  exports: [MapComponent],
})
export class MapModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: MapComponent,
    schema: require('./map.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./map.calculator.svg').default,
        componentTree: require('!!raw-loader?!./map.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./map.component-tree-active.svg').default,
      },
    },
  };
}
