import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbStepperModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { StepperItemComponent } from './stepper-item.component';

@NgModule({
  imports: [CommonModule, NbStepperModule, CoreModule],
  declarations: [StepperItemComponent],
  exports: [StepperItemComponent],
})
export class StepperItemModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: StepperItemComponent,
    schema: require('./stepper-item.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!../stepper/stepper.calculator.svg').default,
        componentTree: require('!!raw-loader?!../stepper/stepper.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!../stepper/stepper.component-tree-active.svg').default,
      },
    },
  };
}
