import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbButtonModule, NbCardModule, NbStepperModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { StepperComponent } from './stepper.component';

@NgModule({
  imports: [CommonModule, ScrollingModule, NbStepperModule, NbButtonModule, CoreModule, NbCardModule],
  declarations: [StepperComponent],
  exports: [StepperComponent],
})
export class StepperModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: StepperComponent,
    schema: require('./stepper.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./stepper.calculator.svg').default,
        componentTree: require('!!raw-loader?!./stepper.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./stepper.component-tree-active.svg').default,
      },
    },
  };
}
