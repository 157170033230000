import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  TextStyleProperty,
  WithFlexComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Component({
  selector: 'ub-heading',
  styleUrls: ['./heading.component.scss'],
  template: `
    <ng-container [ngSwitch]="type">
      <h1
        *ngSwitchCase="'h1'"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      >
        {{ text }}
      </h1>
      <h2
        *ngSwitchCase="'h2'"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      >
        {{ text }}
      </h2>
      <h3
        *ngSwitchCase="'h3'"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      >
        {{ text }}
      </h3>
      <h4
        *ngSwitchCase="'h4'"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      >
        {{ text }}
      </h4>
      <h5
        *ngSwitchCase="'h5'"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      >
        {{ text }}
      </h5>
      <h6
        *ngSwitchCase="'h6'"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      >
        {{ text }}
      </h6>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent extends BaseComponent implements WithFlexComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() text: string = 'Text Heading';
  @Input() type: string = 'h1';
  @Input() fontSize: string = '36px';
  @Input() alignment: string = 'left';
  @Input() textStyle: TextStyleProperty = {
    transform: 'none',
  };
  @Input() decoration: string = '';
  @Input() color: string = 'basic';
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  get classes(): string {
    let classString: string = this.type ? `${this.type}` : '';
    classString = classString + ` text-${this.color}`;
    classString = classString + (this.textStyle?.styles?.includes('italic') ? ' text-italic' : '');
    classString = classString + (this.textStyle?.styles?.includes('bold') ? ' text-bold' : '');
    classString = classString + (this.textStyle?.transform ? ` text-transform-${this.textStyle.transform}` : '');
    classString = classString + (this.alignment ? ` text-alignment-${this.alignment}` : '');
    classString = classString + (this.decoration ? ` text-decoration-${this.decoration}` : '');

    return classString;
  }
}
