import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbCardModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { TableComponent } from './table.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbCardModule],
  declarations: [TableComponent],
  exports: [TableComponent],
})
export class TableModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: TableComponent,
    schema: require('./table.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./table.calculator.svg').default,
        componentTree: require('!!raw-loader?!./table.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./table.component-tree-active.svg').default,
      },
    },
  };
}
