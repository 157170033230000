// @ts-nocheck
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {

  paddings = {
        	xl: '16px 16px 16px 16px',
        	sm: '16px 16px 16px 16px',
        	xs: '8px 8px 8px 8px'
        };
  constructor() {}

  ngOnInit(): void {}
}
