import { ViewContainerRef } from '@angular/core';

import { RenderComponent } from './render-component.model';

export abstract class ComponentRenderer {
  abstract render(components: RenderComponent[], vcr: ViewContainerRef): void;
}

export abstract class ComponentRendererFactory {
  abstract create(): ComponentRenderer;
}
