<ub-layout-header class="Header"
                  [spacing]="spacing"
                  [size]="size | responsive"
                  [fixed]="true"
                  [backgroundColor]="backgroundColor | responsive">
  <ub-space class="Space1"
            [flexContainer]="flexContainer"
            [visible]="true"
            [size]="size1"
            [overflow]="overflow"
            backgroundColor="unset"
            backgroundImage=""
            [showLoading]="false"
            (init)="spaceInit($event)">
    <ub-space class="Space64"
              [flexContainer]="flexContainer1 | responsive"
              [visible]="true"
              [size]="size2"
              [overflow]="overflow1"
              backgroundColor="unset"
              backgroundImage=""
              [showLoading]="false">
      <ub-space class="Space65"
                [flexContainer]="flexContainer2"
                [flex]="flex | responsive"
                [visible]="true"
                [spacing]="spacing1"
                [size]="size3 | responsive"
                [overflow]="overflow2"
                backgroundColor="unset"
                backgroundImage=""
                [showLoading]="false">
        <ub-icon class="icon"
                 icon="question-mark-circle-outline"
                 color="warning"
                 [size]="size4"></ub-icon>
      </ub-space>
      <ub-image class="LogoSDC"
                [visible]="visible | responsive"
                [size]="size5 | responsive"
                [src]="src | responsive"
                radius="0px"
                [fit]="fit | responsive"></ub-image>
      <ub-space class="Space66"
                [flexContainer]="flexContainer3"
                [flex]="flex1 | responsive"
                [visible]="true"
                [spacing]="spacing2"
                [size]="size6 | responsive"
                [overflow]="overflow3"
                backgroundColor="unset"
                backgroundImage=""
                [showLoading]="false"></ub-space>
    </ub-space>
  </ub-space>
</ub-layout-header>