import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BaseComponent } from '@uibakery/core';
import { AlignSelfValue, FlexProperty, WithFlexComponent, WithVisibleComponent } from '@uibakery/fields-types';

@Component({
  selector: 'ub-icon',
  styleUrls: ['./icon.component.scss'],
  template: `
    <ng-container>
      <nb-icon
        [icon]="icon"
        [nbTooltip]="tooltip"
        [nbTooltipTrigger]="tooltip ? 'hover' : 'noop'"
        [class]="classes"
        [style.font-size]="size.custom ? size.customValue : ''"
        (click)="iconClick.emit()"
      ></nb-icon>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent extends BaseComponent implements WithFlexComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() icon: string = 'star-outline';
  @Input() tooltip: string = '';
  @Input() color: string = 'basic';
  @Input() size: { custom: boolean; customValue: string; predefinedValue: string } = {
    custom: false,
    customValue: '1.25rem',
    predefinedValue: 'medium',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Output() iconClick: EventEmitter<void> = new EventEmitter();

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  get classes(): string {
    let classString: string = this.color ? `${this.color} ` : '';
    classString = classString + (this.size?.custom ? '' : this.size.predefinedValue);

    return classString;
  }
}
