import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

import { BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';

@Component({
  selector: 'ub-menu',
  template: `<nb-menu [items]="items" [ngClass]="getMenuColorClass(color)" ubComponentShield></nb-menu>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends BaseComponent implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Input() color: string = 'basic';

  @Input() autoSyncItems: boolean = true;

  /**
   * Menu breaks down and never works again if some item is undefined:
   * [item1,undefined,item2]
   */
  private _items: NbMenuItem[] = [];
  @Input() set items(items: NbMenuItem[]) {
    this._items = items?.filter(Boolean) || [];
  }
  get items(): NbMenuItem[] {
    return this._items;
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.margin')
  get margins(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  getMenuColorClass(color: string): string {
    if (color === 'basic') {
      return '';
    }

    return `menu-text-${color}`;
  }
}
