import { Directive, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ɵa as NgxEchartsDirective } from 'ngx-echarts';
import ResizeObserver from 'resize-observer-polyfill';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import ECharts = echarts.ECharts;
import EChartOption = echarts.EChartOption;

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[echarts]' })
export class EchartsDirective extends NgxEchartsDirective implements OnDestroy, OnInit {
  private alive: boolean = true;
  private resizeObserver!: ResizeObserver;
  private resize$: Subject<void> = new Subject<void>();
  private echartsIntance!: ECharts;
  private responsiveConfig!: { [name: string]: EChartOption };

  constructor(private element: ElementRef, ngZone: NgZone) {
    super(element, ngZone);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.autoResize = false;
    this.chartInit.pipe(filter(() => this.alive)).subscribe((chart: ECharts) => {
      this.echartsIntance = chart;
      this.createResizeObserver();
    });

    this.resize$
      .pipe(
        filter(() => this.alive),
        debounceTime(100),
      )
      .subscribe(() => this.checkResize());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.alive = false;
    this.resizeObserver?.disconnect();
    this.resizeObserver = null!;
  }

  /**
   * Create resize observer to detect resize current container
   * We use this approach because of OnPush detection strategy
   * and not using `DoCheck` logic
   */
  private createResizeObserver(): void {
    if (this.resizeObserver) {
      return;
    }
    this.resizeObserver = new ResizeObserver(() => this.resize$.next());
    this.resizeObserver.observe(this.element.nativeElement);
  }

  private checkResize(): void {
    const clientWidth: number = this.element.nativeElement.clientWidth;
    if (this.echartsIntance && this.alive && clientWidth) {
      this.updateResponsiveOptions(clientWidth);
      this.echartsIntance.resize();
    }
  }

  /**
   * Set new options in according with `responsiveConfig` ([pixelMinWidth]: [options])
   */
  private updateResponsiveOptions(clientWidth: number): void {
    const config: { [name: string]: EChartOption } = this.getResponsiveConfig();
    if (!config) {
      return;
    }

    // Go through responsive config and set new options
    for (const widthKey of Object.keys(config)) {
      if (widthKey !== 'default' && clientWidth < +widthKey) {
        this.echartsIntance.setOption(config[widthKey], false);
        return;
      }
    }

    this.echartsIntance.setOption(config['default'], false);
  }

  private getResponsiveConfig(): { [name: string]: EChartOption } {
    if (this.responsiveConfig) {
      return this.responsiveConfig;
    }

    const options: EChartOption = this.echartsIntance.getOption();
    if (!options) {
      return null!;
    }

    return (options as { responsiveConfig: { [name: string]: EChartOption } }).responsiveConfig;
  }
}
