import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbAccordionModule, NbCardModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { AccordionComponent } from './accordion.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbAccordionModule, ScrollingModule, NbCardModule],
  declarations: [AccordionComponent],
  exports: [AccordionComponent],
})
export class AccordionModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: AccordionComponent,
    schema: require('./accordion.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./accordion.calculator.svg').default,
        componentTree: require('!!raw-loader?!./accordion.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./accordion.component-tree-active.svg').default,
      },
    },
  };
}
