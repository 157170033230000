import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import { NbIconDefinition, NbIconLibraries } from '@nebular/theme';
import * as leaflet from 'leaflet';
import ResizeObserver from 'resize-observer-polyfill';
import { asapScheduler, BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, observeOn, takeUntil, tap } from 'rxjs/operators';

import { BaseComponent, ComponentInitService } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  MapVariation,
  SizeProperty,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';

export interface MapPoint {
  longitude: string | number;
  latitude: string | number;
  icon?: string;
  text?: string;
}

const defaultLargeMarkerIcon: string = `<svg class="default-marker" width="100%" height="100%" viewBox="0 0 48 65" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><filter x="-17.5%" y="-8.8%" width="135%" height="124.6%" filterUnits="objectBoundingBox" id="largea"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.133333333 0 0 0 0 0.168627451 0 0 0 0 0.270588235 0 0 0 0.32 0" in="shadowBlurOuter1"/></filter><path d="M39.98 20.81c.013-.3.02-.602.02-.905C40 8.912 31.046 0 20 0S0 8.912 0 19.905c0 .303.007.605.02.905H0C0 31.88 13.333 49.549 20 57c6.667-7.451 20-25.12 20-36.19h-.02z" id="largeb"/></defs><g transform="translate(4 2)" fill="none" fill-rule="evenodd"><use fill="currentColor" filter="url(#largea)" xlink:href="#largeb"/><path stroke="#FFF" d="M20 .5c5.385 0 10.26 2.172 13.79 5.684 3.528 3.512 5.71 8.363 5.71 13.72 0 .296-.007.59-.02.883.02 10.8-12.763 27.854-19.482 35.458C13.28 48.638.503 31.59.5 20.815c.004-.203.003-.406.002-.607C.5 14.552 2.68 9.698 6.21 6.184A19.487 19.487 0 0120 .5z" stroke-linejoin="square" fill="#36F"/></g></svg>`;
const defaultSmallMarkerIcon: string = `<svg class="default-marker" width="100%" height="100%" viewBox="0 0 40 54" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><filter x="-21.9%" y="-10.9%" width="143.8%" height="130.4%" filterUnits="objectBoundingBox" id="smalla"><feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.133333333 0 0 0 0 0.168627451 0 0 0 0 0.270588235 0 0 0 0.32 0" in="shadowBlurOuter1"/></filter><path d="M31.984 16.794c.01-.242.016-.486.016-.73C32 7.191 24.837 0 16 0S0 7.192 0 16.063c0 .245.005.489.016.73H0C0 25.728 10.666 39.988 16 46c5.333-6.013 16-20.273 16-29.206h-.016z" id="smallb"/></defs><g transform="translate(4 2)" fill="none" fill-rule="evenodd"><use fill="currentColor" filter="url(#smalla)" xlink:href="#smallb"/><path stroke="#FFF" d="M16 .5a15.42 15.42 0 0110.96 4.558 15.547 15.547 0 014.524 11.713C31.5 25.432 21.384 39.074 16 45.243 10.616 39.074.5 25.43.5 16.793c0-5.012 1.73-8.913 4.54-11.735A15.42 15.42 0 0116 .5z" stroke-linejoin="square" fill="#36F"/><circle stroke="#274BDB" fill="#FFF" cx="16" cy="16" r="6"/></g></svg>`;

@Component({
  selector: 'ub-map',
  styleUrls: ['./map.component.scss'],
  template: `
    <ng-container *ngIf="variation === 'raw'">
      <ng-container *ngTemplateOutlet="map"></ng-container>
    </ng-container>

    <ng-container *ngIf="variation === 'card'">
      <nb-card>
        <nb-card-header>{{ title }}</nb-card-header>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="map"></ng-container>
        </nb-card-body>
      </nb-card>
    </ng-container>

    <ng-template #map>
      <div leaflet [leafletOptions]="options"></div>
    </ng-template>
  `,

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent extends BaseComponent
  implements WithVisibleComponent, OnInit, OnDestroy, AfterViewInit, WithSizeComponent {
  @Input() visible: boolean = true;
  @Input() title: string = 'Map';

  private _variation: MapVariation = 'card';
  @Input() set variation(variation: MapVariation) {
    this._variation = variation;
    if (Array.isArray(this.points)) {
      this._points$.next([...this.points]);
    }
  }

  get variation(): MapVariation {
    return this._variation;
  }

  @HostBinding('class.with-card')
  get isWithCard(): boolean {
    return this.variation === 'card';
  }

  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };
  @Input() size: SizeProperty = {
    width: '100%',
    height: '100%',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  private _markersGroup: leaflet.FeatureGroup;
  private _points$: BehaviorSubject<MapPoint[]> = new BehaviorSubject<MapPoint[]>([
    {
      latitude: '46.023221627810884',
      longitude: '9.256035495890233',
      text: 'Lake Como',
    },
  ]);

  @Input() set points(points: MapPoint[]) {
    this._points$.next(points);
  }

  get points(): MapPoint[] {
    return this._points$.value;
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @ViewChild(LeafletDirective) map?: LeafletDirective;

  options: leaflet.MapOptions = {
    layers: [
      leaflet.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' }),
    ],
    zoom: 5,
    center: leaflet.latLng({ lat: 46.023221627810884, lng: 9.256035495890233 }),
  };

  private resizeObserver: ResizeObserver | null = null;
  private resize$: Subject<void> = new Subject<void>();
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(cd: ChangeDetectorRef, initService: ComponentInitService, private nbIconLibraries: NbIconLibraries) {
    super(cd, initService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.resize$.pipe(debounceTime(100), takeUntil(this.destroyed$)).subscribe(() => {
      this.map?.getMap().invalidateSize();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed$.next();
    this.resizeObserver?.disconnect();
    this.resizeObserver = null;
  }

  ngAfterViewInit(): void {
    this.createResizeObserver();
    this.updateMapPoints();
  }

  private updateMapPoints(): void {
    this._points$
      .pipe(
        observeOn(asapScheduler),
        filter(() => !!this.map),
        tap(() => this._markersGroup?.removeFrom(this.map.getMap())),
        filter((points: MapPoint[]) => !!points),
        takeUntil(this.destroyed$),
      )
      .subscribe((points: MapPoint[]) => {
        const map: leaflet.Map = this.map.getMap();
        const markersArray: leaflet.Marker[] = points
          .map((point: MapPoint) => {
            const longitude: number = Number.parseFloat(point.longitude as string);
            const latitude: number = Number.parseFloat(point.latitude as string);
            if (isNaN(longitude) || isNaN(latitude)) {
              return null;
            }
            return leaflet.marker([latitude, longitude], {
              icon: this.getIconForMarker(point),
            });
          })
          .filter((marker: leaflet.Marker) => !!marker);

        this._markersGroup = leaflet.featureGroup(markersArray);
        this._markersGroup.addTo(map);
        if (markersArray.length > 1) {
          map.fitBounds(this._markersGroup.getBounds(), { padding: [10, 10] });
        } else if (markersArray.length === 1) {
          map.flyTo(markersArray[0].getLatLng(), 12);
        }
      });
  }

  private getIconForMarker(mapPoint: MapPoint): leaflet.DivIcon {
    const nbIcon: NbIconDefinition | null = this.nbIconLibraries.getIcon(mapPoint.icon);
    const defaultMarkerIcon: string = nbIcon ? defaultLargeMarkerIcon : defaultSmallMarkerIcon;
    const markerLabel: string = nbIcon ? nbIcon.icon.getContent() : '';
    const text: string = mapPoint.text ? `<div class="marker-text">${mapPoint.text}</div>` : '';
    const iconWidth: number = nbIcon ? 64 : 48;
    const iconHeight: number = nbIcon ? 88 : 65;
    const iconSize: [number, number] = [null, iconHeight];
    const html: string = `<div style="width: ${iconWidth}px;" class="marker-icon">${defaultMarkerIcon}${markerLabel}</div>${text}`;
    const iconAnchor: [number, number] = nbIcon ? [32, 79] : [24, 59];
    return leaflet.divIcon({ html, iconSize, iconAnchor });
  }

  /**
   * Create resize observer to detect resize of current container
   */
  private createResizeObserver(): void {
    if (this.resizeObserver || !this.map) {
      return;
    }

    this.resizeObserver = new ResizeObserver(() => this.resize$.next());
    this.resizeObserver.observe(this.map.getMap().getContainer());
  }
}
