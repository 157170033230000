const blacklistValues: unknown[] = ['0', 'auto', undefined, null, ''];

export function computeSize(
  size: string | undefined,
  margin: string | undefined,
  sizeFor: 'block' | 'inline' = 'block',
): string | undefined {
  let resultSize: string | undefined = size;
  const isPercentSize: boolean = !!size?.trim().endsWith('%');
  if (isPercentSize) {
    const [blockStart, inlineEnd = blockStart, blockEnd = blockStart, inlineStart = inlineEnd]: string[] =
      margin?.split(' ').filter((segment: string) => !!segment.trim()) ?? [];

    const segmentsMap: Record<string, string> = {
      blockStart,
      inlineEnd,
      blockEnd,
      inlineStart,
    };

    const start: string = segmentsMap[`${sizeFor}Start`];
    const end: string = segmentsMap[`${sizeFor}End`];
    let marginExpression: string = '';
    if (!blacklistValues.includes(start)) {
      marginExpression += ` - ${start}`;
    }
    if (!blacklistValues.includes(end)) {
      marginExpression += ` - ${end}`;
    }
    if (marginExpression) {
      resultSize = `calc(${size}${marginExpression})`;
    }
  }

  return resultSize;
}
