import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import {
  SmartTableColumn,
  SmartTableColumnHeaderParamConfig,
  SmartTableColumnHeaderParamType,
} from '@uibakery/fields-types';

import { SmartTableService } from '../smart-table.service';

@Component({
  selector: 'ub-smart-table-header-data-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cell.scss', './smart-table-header-data-cell.component.scss'],
  template: `
    <div class="container" *ngIf="withAdditionRow">
      <nb-form-field *ngIf="showInput">
        <nb-icon *ngIf="!addModeActive" nbPrefix icon="funnel"></nb-icon>
        <input
          nbInput
          fullWidth
          fieldSize="small"
          class="filter-input"
          type="text"
          (keyup.enter)="onInputEnter()"
          [placeholder]="column?.name"
          [formControl]="valueControl"
        />
      </nb-form-field>

      <nb-select *ngIf="showSelect" fullWidth [placeholder]="listSelectText" [formControl]="valueControl">
        <nb-option *ngFor="let option of listOptions" [value]="option.value">
          {{ option.title }}
        </nb-option>
      </nb-select>
    </div>
  `,
})
export class SmartTableHeaderDataCellComponent {
  public readonly valueControl: FormControl = new FormControl('');

  @Input() set value(value: string) {
    this.valueControl.setValue((value ?? '').toString(), { emitEvent: false });
  }

  @Input() withFilter: boolean = false;

  @HostBinding('class.add-mode-active')
  @Input()
  addModeActive: boolean = false;
  @Input() column?: SmartTableColumn;
  @Output() valueChange: Observable<string> = this.valueControl.valueChanges;
  @Output() addConfirm: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('class.with-addition-row')
  @Input()
  withAdditionRow!: boolean;

  get activeMode(): 'filter' | 'add' {
    return this.addModeActive ? 'add' : 'filter';
  }

  get activeParamType(): SmartTableColumnHeaderParamType {
    if (this.activeMode === 'filter' && !this.withFilter) {
      return SmartTableColumnHeaderParamType.NONE;
    }
    return this.smService.getColumnHeaderParamType(this.column, this.activeMode);
  }

  get activeParamConfig(): SmartTableColumnHeaderParamConfig {
    return this.smService.getColumnHeaderParamConfig(this.column, this.activeMode);
  }

  get showInput(): boolean {
    if (this.activeMode === 'add') {
      return true;
    }
    return this.activeParamType === SmartTableColumnHeaderParamType.INPUT;
  }

  get showSelect(): boolean {
    if (this.activeMode === 'add') {
      return false;
    }
    return this.activeParamType === SmartTableColumnHeaderParamType.LIST;
  }

  get listOptions(): { value: string; title: string }[] {
    if (!this.showSelect) {
      return [];
    }
    return this.activeParamConfig?.list || [];
  }

  get listSelectText(): string {
    if (!this.showSelect) {
      return '';
    }
    return this.activeParamConfig?.selectText;
  }

  constructor(private smService: SmartTableService) {}

  onInputEnter(): void {
    if (this.addModeActive) {
      this.addConfirm.emit(this.valueControl.value);
    }
  }
}
