import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { Base, ComponentRenderer, RenderComponent } from '@uibakery/core';

export class AppComponentRenderer implements ComponentRenderer {
  constructor(private cfr: ComponentFactoryResolver) {}

  render(components: RenderComponent[], vcr: ViewContainerRef): void {
    vcr.clear();
    for (let i: number = 0; i < components?.length; i++) {
      const component: RenderComponent = components[i];
      const factory: ComponentFactory<Base> = this.cfr.resolveComponentFactory(component.componentType);
      const componentRef: ComponentRef<Base> = vcr.createComponent(factory, i);
      Object.assign(componentRef.instance, component.inputs);
    }
  }
}
