// @ts-nocheck
import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, asapScheduler, of, asyncScheduler, combineLatest, iif } from 'rxjs';
import * as AppActions from './app.actions';
import { switchMap, withLatestFrom, map, observeOn, mapTo, tap, catchError, mergeMap, share, take } from 'rxjs/operators';
import * as fromApp from './app.reducer';
import * as appSelectors from './app.selectors';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AppEffects {


  private listeDesUtilisateurs$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.listeDesUtilisateurs.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_accounts?api_key=d2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386')),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var listusers = data
              var listusersfiltered = listusers.resource
            
            return listusersfiltered
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateUsers2({ stateUsers2: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private checkUserValid$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.checkUserValid.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiSecret))),
              map(([{ data, error }, uiSecret]) => {  
            var MD5 = function (string) {
            
               function RotateLeft(lValue, iShiftBits) {
                       return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
               }
            
               function AddUnsigned(lX,lY) {
                       var lX4,lY4,lX8,lY8,lResult;
                       lX8 = (lX & 0x80000000);
                       lY8 = (lY & 0x80000000);
                       lX4 = (lX & 0x40000000);
                       lY4 = (lY & 0x40000000);
                       lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);
                       if (lX4 & lY4) {
                               return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
                       }
                       if (lX4 | lY4) {
                               if (lResult & 0x40000000) {
                                       return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
                               } else {
                                       return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
                               }
                       } else {
                               return (lResult ^ lX8 ^ lY8);
                       }
               }
            
               function F(x,y,z) { return (x & y) | ((~x) & z); }
               function G(x,y,z) { return (x & z) | (y & (~z)); }
               function H(x,y,z) { return (x ^ y ^ z); }
               function I(x,y,z) { return (y ^ (x | (~z))); }
            
               function FF(a,b,c,d,x,s,ac) {
                       a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
                       return AddUnsigned(RotateLeft(a, s), b);
               };
            
               function GG(a,b,c,d,x,s,ac) {
                       a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
                       return AddUnsigned(RotateLeft(a, s), b);
               };
            
               function HH(a,b,c,d,x,s,ac) {
                       a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
                       return AddUnsigned(RotateLeft(a, s), b);
               };
            
               function II(a,b,c,d,x,s,ac) {
                       a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
                       return AddUnsigned(RotateLeft(a, s), b);
               };
            
               function ConvertToWordArray(string) {
                       var lWordCount;
                       var lMessageLength = string.length;
                       var lNumberOfWords_temp1=lMessageLength + 8;
                       var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;
                       var lNumberOfWords = (lNumberOfWords_temp2+1)*16;
                       var lWordArray=Array(lNumberOfWords-1);
                       var lBytePosition = 0;
                       var lByteCount = 0;
                       while ( lByteCount < lMessageLength ) {
                               lWordCount = (lByteCount-(lByteCount % 4))/4;
                               lBytePosition = (lByteCount % 4)*8;
                               lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));
                               lByteCount++;
                       }
                       lWordCount = (lByteCount-(lByteCount % 4))/4;
                       lBytePosition = (lByteCount % 4)*8;
                       lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);
                       lWordArray[lNumberOfWords-2] = lMessageLength<<3;
                       lWordArray[lNumberOfWords-1] = lMessageLength>>>29;
                       return lWordArray;
               };
            
               function WordToHex(lValue) {
                       var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;
                       for (lCount = 0;lCount<=3;lCount++) {
                               lByte = (lValue>>>(lCount*8)) & 255;
                               WordToHexValue_temp = "0" + lByte.toString(16);
                               WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);
                       }
                       return WordToHexValue;
               };
            
               function Utf8Encode(string) {
                       string = string.replace(/\r\n/g,"\n");
                       var utftext = "";
            
                       for (var n = 0; n < string.length; n++) {
            
                               var c = string.charCodeAt(n);
            
                               if (c < 128) {
                                       utftext += String.fromCharCode(c);
                               }
                               else if((c > 127) && (c < 2048)) {
                                       utftext += String.fromCharCode((c >> 6) | 192);
                                       utftext += String.fromCharCode((c & 63) | 128);
                               }
                               else {
                                       utftext += String.fromCharCode((c >> 12) | 224);
                                       utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                                       utftext += String.fromCharCode((c & 63) | 128);
                               }
            
                       }
            
                       return utftext;
               };
            
               var x=Array();
               var k,AA,BB,CC,DD,a,b,c,d;
               var S11=7, S12=12, S13=17, S14=22;
               var S21=5, S22=9 , S23=14, S24=20;
               var S31=4, S32=11, S33=16, S34=23;
               var S41=6, S42=10, S43=15, S44=21;
            
               string = Utf8Encode(string);
            
               x = ConvertToWordArray(string);
            
               a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;
            
               for (k=0;k<x.length;k+=16) {
                       AA=a; BB=b; CC=c; DD=d;
                       a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);
                       d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);
                       c=FF(c,d,a,b,x[k+2], S13,0x242070DB);
                       b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);
                       a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);
                       d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);
                       c=FF(c,d,a,b,x[k+6], S13,0xA8304613);
                       b=FF(b,c,d,a,x[k+7], S14,0xFD469501);
                       a=FF(a,b,c,d,x[k+8], S11,0x698098D8);
                       d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);
                       c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);
                       b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);
                       a=FF(a,b,c,d,x[k+12],S11,0x6B901122);
                       d=FF(d,a,b,c,x[k+13],S12,0xFD987193);
                       c=FF(c,d,a,b,x[k+14],S13,0xA679438E);
                       b=FF(b,c,d,a,x[k+15],S14,0x49B40821);
                       a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);
                       d=GG(d,a,b,c,x[k+6], S22,0xC040B340);
                       c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);
                       b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);
                       a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);
                       d=GG(d,a,b,c,x[k+10],S22,0x2441453);
                       c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);
                       b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);
                       a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);
                       d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);
                       c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);
                       b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);
                       a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);
                       d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);
                       c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);
                       b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);
                       a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);
                       d=HH(d,a,b,c,x[k+8], S32,0x8771F681);
                       c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);
                       b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);
                       a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);
                       d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);
                       c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);
                       b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);
                       a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);
                       d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);
                       c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);
                       b=HH(b,c,d,a,x[k+6], S34,0x4881D05);
                       a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);
                       d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);
                       c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);
                       b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);
                       a=II(a,b,c,d,x[k+0], S41,0xF4292244);
                       d=II(d,a,b,c,x[k+7], S42,0x432AFF97);
                       c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);
                       b=II(b,c,d,a,x[k+5], S44,0xFC93A039);
                       a=II(a,b,c,d,x[k+12],S41,0x655B59C3);
                       d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);
                       c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);
                       b=II(b,c,d,a,x[k+1], S44,0x85845DD1);
                       a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);
                       d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);
                       c=II(c,d,a,b,x[k+6], S43,0xA3014314);
                       b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);
                       a=II(a,b,c,d,x[k+4], S41,0xF7537E82);
                       d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);
                       c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);
                       b=II(b,c,d,a,x[k+9], S44,0xEB86D391);
                       a=AddUnsigned(a,AA);
                       b=AddUnsigned(b,BB);
                       c=AddUnsigned(c,CC);
                       d=AddUnsigned(d,DD);
               		}
            
               	var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);
            
               	return temp.toLowerCase();
            }
            
            return MD5(MD5(uiSecret.value));
                    
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateMD5Users({ stateMD5Users: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiUsername))),
              map(([{ data, error }, uiUsername]) => {  
            var x = uiUsername.value
            var y = "@sdc.nsinetwork.ca"
            return x+y
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateUsernameAPI({ stateUsernameAPI: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => combineLatest([of({ data, error }), 
          this.store.pipe(select(appSelectors.getStateUsernameAPI)),
          this.store.pipe(select(appSelectors.getUiSecret))
        ]).pipe(take(1))),
        switchMap(([{ data, error }, stateUsernameAPI, uiSecret]: any) => this.http.post('https://api.mafierte.ca/api/v2/user/session', `{
      "email": "${stateUsernameAPI}",
      "password": "${uiSecret.value}",
      "duration": 0
    }`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        
        mergeMap(({ data, error }) => {
          const emitter = of({ data, error });
          return iif(
            () => {
              return !!data;
            },
            emitter.pipe(
              tap(({ data, error }) => this.router.navigateByUrl(`${'/home2'}`)),
            ),emitter,
          )}),
      )
    ),
    share(),
  ), { dispatch: false });

  private interventionsGotosubmit$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.interventionsGotosubmit.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/interventions/soumission'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private interventionsBackTo$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.interventionsBackTo.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/interventions'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesIncidentsGoto$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesIncidentsGoto.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/archives/archives-incidents'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesInterventionsGOTO$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesInterventionsGOTO.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/archives/archives-p1-interventions'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private dashboardCount$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.dashboardCount.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_1?filter=(date_intervention%20like%202021-01%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIntJan({ stateDash_Int_Jan: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_1?filter=(date_intervention%20like%202021-02%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIntFeb({ stateDash_int_Feb: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_1?filter=(date_intervention%20like%202021-03%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIntMar({ stateDash_int_Mar: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_1?filter=(date_intervention%20like%202021-04%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIntApr({ stateDash_Int_Apr: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_2?filter=(date_incident%20like%202021-01%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIncJan({ stateDash_Inc_Jan: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_2?filter=(date_incident%20like%202021-02%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIncFeb({ stateDash_inc_feb: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_2?filter=(date_incident%20like%202021-03%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIncMar({ stateDash_inc_mar: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_2?filter=(date_incident%20like%202021-03%)&count_only=true', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDashIncApr({ stateDash_inc_apr: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesIncidentsConsult$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesIncidentsConsult.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/archives/archives-incidents/consult-incident'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private getBackHome$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.getBackHome.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/home2'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesIncidentsList$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesIncidentsList.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_2?api_key=d2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386', {
          headers: {
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateListevenement({ stateListevenement: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            function countObjectKeys(obj) { 
                return Object.keys(obj).length; 
            }
            
            return countObjectKeys(data)
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateAmoutofEvent({ stateAmoutofEvent: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesIncidentsGetData$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesIncidentsGetData.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiSmartTable))),
              map(([{ data, error }, uiSmartTable]) => {  
            var x = uiSmartTable.selectedRow
            return x.data.submission_id
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateTempSelectedIDEvent({ stateTempSelectedIDEvent: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        withLatestFrom(this.store.pipe(select(appSelectors.getStateTempSelectedIDEvent))),
        switchMap(([{ data, error }, stateTempSelectedIDEvent]) => this.http.get(`https://api.mafierte.ca/api/v2/sdc/_table/ft_form_2/${stateTempSelectedIDEvent}`, {
          headers: {
            'Content-Type': 'application/json',
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.adresse_civ
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDAdresse({ stateDataFromEventID_Adresse: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.rue_transversale
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDTransversale({ stateDataFromEventID_transversale: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_incident
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDNatureincident({ stateDataFromEventID_natureincident: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.heure_incident
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDHeureincident({ stateDataFromEventID_heureincident: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.date_incident
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDDateincident({ stateDataFromEventID_dateincident: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.deroulement_event
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDDeroulement({ stateDataFromEventID_deroulement: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.description_individu1
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDIndividu1({ stateDataFromEventID_individu1: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.description_individu2
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDIndividu2({ stateDataFromEventID_individu2: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.description_individu3
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDIndividu3({ stateDataFromEventID_individu3: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.description_individu4
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDIndividu4({ stateDataFromEventID_individu4: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.description_individu5
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDIndividu5({ stateDataFromEventID_individu5: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.instance_nom
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDInstanceNom({ stateDataFromEventID_instance_nom: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.instance_nom_contact
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDInstanceNomContact({ stateDataFromEventID_instance_nom_contact: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.instance_tel
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDInstanceTel({ stateDataFromEventID_instance_tel: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.instance_heure
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDInstanceHeure({ stateDataFromEventID_instance_heure: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.instance_suivi
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDInstanceSuivi({ stateDataFromEventID_instance_suivi: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.instance_suivi_num
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDInstanceSuiviNum({ stateDataFromEventID_instance_suivi_num: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.dateutc
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromEventIDDateutc({ stateDataFromEventID_dateutc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private printInt$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.printInt.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap(({ data, error }) => combineLatest([of({ data, error }), 
          this.store.pipe(select(appSelectors.getStateTempSelectedIDIntervention)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionAgent1)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionAgent2)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionDateintervention)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionHeure)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionTemps)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionLieu)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionSecteur)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionType)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionDescription)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionResolutionDetail)),
          this.store.pipe(select(appSelectors.getStateDataFromInterventionComments)),
          this.store.pipe(select(appSelectors.getStateUsernameAPI))
        ]).pipe(take(1))),
        map(([{ data, error }, stateTempSelectedIDIntervention, stateDataFromIntervention_agent1, stateDataFromIntervention_agent2, stateDataFromIntervention_dateintervention, stateDataFromIntervention_heure, stateDataFromIntervention_temps, stateDataFromIntervention_lieu, stateDataFromIntervention_secteur, stateDataFromIntervention_type, stateDataFromIntervention_description, stateDataFromIntervention_resolution_detail, stateDataFromIntervention_comments, stateUsernameAPI]: any) => {
          function printPage() {
              var w = window.open();
              var headers =  "";
              var field= "";
              var field2= "";
            
          
              var html = "<!DOCTYPE HTML>";
              html += '<html lang="en-us">';
              html += '<head><style></style></head>';
              html += "<body>";
          
          html +='<img src="https://sdc.nsinetwork.ca/assets/images/logoSDC.png" alt="" width="254" height="31" />';
          html +='<table style="height: 16px; width: 714px;">';
          html +='<tbody>';
          html +='<tr>';
          html +='<td style="width: 252px;">';
          html +='<h2>Intervention #</h2>';
          html +='</td>';
          html +='<td style="width: 92px;">';
          html +="<h2>";
          html += stateTempSelectedIDIntervention;
          html +="</h2>";
          html +='</td>';
          html +='<td style="width: 101px;">';
          html +='<h2>Agents:</h2>';
          html +='</td>';
          html +='<td style="width: 441px;">';
          html +='<h2>';
          html += stateDataFromIntervention_agent1;
            html+="&";
            html+=stateDataFromIntervention_agent2;
          html+= '</h2>';
          html +='</td>';
          html +='</tr>';
          html +='</tbody>';
          html +='</table>';
          html +='<table style="height: 69px; width: 599px;">';
          html +="<tbody>";
          html +='<tr>';
          html +='<td style="width: 48px;"><strong>Date</strong></td>';
          html +='<td style="width: 147px;">';
          html += stateDataFromIntervention_dateintervention;
          html +='</td>';
          html +='<td style="width: 62px;"><strong>Heure</strong></td>';
          html +='<td style="width: 82px;">'
          html+= stateDataFromIntervention_heure;
          html+='</td>';
          html +='<td style="width: 98px;"><strong>Temp&eacute;rature</strong></td>';
          html +='<td style="width: 122px;">';
          html += stateDataFromIntervention_temps;
          html+='</td>';
          html +='</tr>';
          html +='<tr>';
          html +='<td style="width: 48px;"><strong>Lieu</strong></td>';
          html +='<td style="width: 147px;">';
            html+=stateDataFromIntervention_lieu;
              html+='</td>';
          html +='<td style="width: 62px;"><strong>Secteur</strong></td>';
          html +='<td style="width: 82px;">';
            html+=stateDataFromIntervention_secteur;
              html+='</td>';
          html +='<td style="width: 98px;"><strong>Type</strong></td>';
          html +='<td style="width: 122px;">';
            html+=stateDataFromIntervention_type;
              html+='</td>';
          html +='</tr>';
          html +='</tbody>';
          html +='</table>';
          html +='<hr />';
          html +='<h2 style="font-size: 1.5em;"><span style="text-decoration: underline;">Description de l&apos;&eacute;v&eacute;nement</span></h2>';
          html +='<p>';
            html+=stateDataFromIntervention_description;
              html+='</p>';
          html +='<p>&nbsp;</p>';
          html +='<hr />';
          html +='<h2><span style="text-decoration: underline;">R&eacute;solution</span></h2>';
          html +='<p>';
            html+=stateDataFromIntervention_resolution_detail;
              html+='</p>';
          html +='<p>&nbsp;</p>';
          html +='<hr />';
          html +='<h2><span style="text-decoration: underline;">Commentaires</span></h2>';
          html +='<p>';
            html+=stateDataFromIntervention_comments;
              html+='</p>';
          html +='<p>&nbsp;</p>';
          
          html +='<hr />';
          html +='<table style="height: 16px; width: 728px;">';
          html +='<tbody>';
          html +='<tr>';
          html +='<td style="width: 79px;">G&eacute;n&eacute;r&eacute; le:</td>';
          html +='<td style="width: 149px;">';
            html += Date();
            html+='</td>';
          html +='<td style="width: 115px;">Intervention #</td>';
          html +='<td style="width: 115px;">';
            html+=stateTempSelectedIDIntervention;
              html+='</td>';
          html +='<td style="width: 115px;">G&eacute;n&eacute;r&eacute; par :</td>';
          html +='<td style="width: 115px;">';
            html+=stateUsernameAPI;
              html+='</td>';
          html +='</tr>';
          html +='</tbody>';
          html +='</table>';
          
           
          
              //check to see if they are null so "undefined" doesnt print on the page. <br>s optional, just to give space
          
          
              html += "</body>";
              w.document.write(html);
              w.window.print();
            	w.document.close();
            
          }
          printPage();
          return data;
        }),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesInterventionsConsult$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesInterventionsConsult.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/archives/archives-consultation-interventions'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesInterventionsList$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesInterventionsList.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_1?api_key=d2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386', {
          headers: {
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x =data;
            x = x.resource;
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateListinc({ stateListinc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/ft_form_1?api_key=d2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386&limit=1000&offset=1000', {
          headers: {
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x =data;
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateListinc2({ stateListinc2: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          switchMap(({ data, error }) => combineLatest([of({ data, error }), 
                this.store.pipe(select(appSelectors.getStateListinc)),
                this.store.pipe(select(appSelectors.getStateListinc2))
              ]).pipe(take(1))),
              map(([{ data, error }, stateListinc, stateListinc2]: any) => {  
            var x = [];
            x = stateListinc.concat(stateListinc2);
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateListconcat({ stateListconcat: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getStateListconcat))),
              map(([{ data, error }, stateListconcat]) => {  
            function countObjectKeys(obj) { 
                return Object.keys(obj).length; 
            }
            
            return countObjectKeys(stateListconcat)
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateAMoutofINTCONCAT({ stateAMoutofINTCONCAT: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private archivesInterventionsGetData$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.archivesInterventionsGetData.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiSmartTableIAInter))),
              map(([{ data, error }, uiSmartTableIA_Inter]) => {  
            var x = uiSmartTableIA_Inter.selectedRow
            return x.data.submission_id
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateTempSelectedIDIntervention({ stateTempSelectedIDIntervention: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        withLatestFrom(this.store.pipe(select(appSelectors.getStateTempSelectedIDIntervention))),
        switchMap(([{ data, error }, stateTempSelectedIDIntervention]) => this.http.get(`https://api.mafierte.ca/api/v2/sdc/_table/ft_form_1/${stateTempSelectedIDIntervention}`, {
          headers: {
            'Content-Type': 'application/json',
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.horaire
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionHoraire({ stateDataFromIntervention_horaire: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.temp
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionTemps({ stateDataFromIntervention_temps: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.lieu
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionLieu({ stateDataFromIntervention_lieu: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.secteur
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionSecteur({ stateDataFromIntervention_secteur: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.heure_intervention
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionHeure({ stateDataFromIntervention_heure: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.type_event
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionType({ stateDataFromIntervention_type: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.detail_resolution
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionResolutionDetail({ stateDataFromIntervention_resolution_detail: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.desc_event
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionDescription({ stateDataFromIntervention_description: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.comments
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionComments({ stateDataFromIntervention_comments: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.agent_1
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionAgent1({ stateDataFromIntervention_agent1: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.agent_2
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionAgent2({ stateDataFromIntervention_agent2: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.date_intervention
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionDateintervention({ stateDataFromIntervention_dateintervention: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.dateutc
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDataFromInterventionDateinterventionUTC({ stateDataFromIntervention_dateinterventionUTC: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private interventionsSeeResults$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.interventionsSeeResults.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        tap(({ data, error }) => this.router.navigateByUrl(`${'/interventions/modification'}`)),
      )
    ),
    share(),
  ), { dispatch: false });

  private interventionsList$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.interventionsList.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/interventions?api_key=d2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386', {
          headers: {
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            return data
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateListInterventions({ stateListInterventions: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            function countObjectKeys(obj) { 
                return Object.keys(obj).length; 
            }
            
            return countObjectKeys(data)
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateAmountofInterventions({ stateAmountofInterventions: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private getData$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.getData.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiTableInterventions))),
              map(([{ data, error }, uiTableInterventions]) => {  
            var x = uiTableInterventions.selectedRow;
            return x.data.submission_id
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateTempSelectedIDIntervention({ stateTempSelectedIDIntervention: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        withLatestFrom(this.store.pipe(select(appSelectors.getStateTempSelectedIDIntervention))),
        switchMap(([{ data, error }, stateTempSelectedIDIntervention]) => this.http.get(`https://api.mafierte.ca/api/v2/sdc/_table/interventions/${stateTempSelectedIDIntervention}`, {
          headers: {
            'Content-Type': 'application/json',
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.date_intervention
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDDateintervention({ stateEventID_dateintervention: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.dateutc
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDDateutc({ stateEventID_dateutc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.heure_intervention
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDHeureintervention({ stateEventID_heureintervention: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.agent_1
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDAgent1({ stateEventID_agent1: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.agent_2
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDAgent2({ stateEventID_agent2: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.agent_remplacant
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDAgentremplacant({ stateEventID_agentremplacant: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.horaire
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDHoraire({ stateEventID_horaire: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.temp
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDTemperature({ stateEventID_temperature: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.lieu
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDLieu({ stateEventID_lieu: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.secteur
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDSecteur({ stateEventID_secteur: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_referencement
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureReferencement({ stateEventID_nature_referencement: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_311
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNature311({ stateEventID_nature_311: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_911
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNature911({ stateEventID_nature_911: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_contact_citoyen
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureContactCitoyen({ stateEventID_nature_contact_citoyen: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_contact_population
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureContactPopulation({ stateEventID_nature_contact_population: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_contact_organisme
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureContactOrganisme({ stateEventID_nature_contact_organisme: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_intervention
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureIntervention({ stateEventID_nature_intervention: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_dechet
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureDechet({ stateEventID_nature_dechet: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_materiel_donne
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureMaterielDonne({ stateEventID_nature_materiel_donne: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_materiel_recupere
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureMaterielRecupere({ stateEventID_nature_materiel_recupere: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_appelrecu
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureAppelrecu({ stateEventID_nature_appelrecu: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_nuisible
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureNuisible({ stateEventID_nature_nuisible: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_premiersoins
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNaturePremiersoins({ stateEventID_nature_premiersoins: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_observation
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureObservation({ stateEventID_nature_observation: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nature_autres
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNatureAutres({ stateEventID_nature_autres: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.description_situation
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDDescriptionSituation({ stateEventID_description_situation: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nb_personnes
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNbpersonnes({ stateEventID_nbpersonnes: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.nb_vehicules
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDNbvehicules({ stateEventID_nbvehicules: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne1_statut
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne1Statut({ stateEventID_personne1_statut: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne1_nom
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne1Nom({ stateEventID_personne1_nom: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne1_matricule
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne1Matricule({ stateEventID_personne1_matricule: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne1_description
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne1Description({ stateEventID_personne1_description: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne2_statut
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne2Statut({ stateEventID_personne2_statut: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne2_nom
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne2Nom({ stateEventID_personne2_nom: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne2_matricule
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne2Matricule({ stateEventID_personne2_matricule: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne2_description
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne2Description({ stateEventID_personne2_description: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne3_statut
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne3Statut({ stateEventID_personne3_statut: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne3_nom
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne3Nom({ stateEventID_personne3_nom: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne3_matricule
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne3Matricule({ stateEventID_personne3_matricule: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne3_description
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne3Description({ stateEventID_personne3_description: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne4_statut
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne4Statut({ stateEventID_personne4_statut: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne4_nom
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne4Nom({ stateEventID_personne4_nom: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne4_matricule
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne4Matricule({ stateEventID_personne4_matricule: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.personne4_description
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDPersonne4Description({ stateEventID_personne4_description: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule1_type
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule1Type({ stateEventID_vehicule1_type: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule1_plaque
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule1Plaque({ stateEventID_vehicule1_plaque: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule1_modele
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule1Modele({ stateEventID_vehicule1_modele: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule1_couleur
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule1Couleur({ stateEventID_vehicule1_couleur: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule1_signes
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule1Signes({ stateEventID_vehicule1_signes: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule2_type
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule2Type({ stateEventID_vehicule2_type: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule2_plaque
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule2Plaque({ stateEventID_vehicule2_plaque: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule2_modele
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule2Modele({ stateEventID_vehicule2_modele: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule2_signes
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule2Signes({ stateEventID_vehicule2_signes: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x=data
                return x.vehicule2_couleur
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateEventIDVehicule2Couleur({ stateEventID_vehicule2_couleur: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });

  private interventionsNew$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.interventionsNew.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            function getRandomInt(max) {
              return Math.floor(Math.random() * Math.floor(max));
            }
            return(getRandomInt(56851))
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateIntIDIntervention({ stateIntIDIntervention: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiDescriptionIntervention))),
              map(([{ data, error }, uiDescriptionIntervention]) => {  
            var x = uiDescriptionIntervention.value
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsConvertDescription({ stateInterventions_convert_description: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiPersonne1Description))),
              map(([{ data, error }, uiPersonne1_description]) => {  
            var x = uiPersonne1_description.value
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne1Desc({ stateInterventions_personne1_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiPersonne2Description))),
              map(([{ data, error }, uiPersonne2_description]) => {  
            var x = uiPersonne2_description.value
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne2Desc({ stateInterventions_personne2_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiPersonne3Description))),
              map(([{ data, error }, uiPersonne3_description]) => {  
            var x = uiPersonne3_description.value;
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne3Desc({ stateInterventions_personne3_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiPersonne4Description))),
              map(([{ data, error }, uiPersonne4_description]) => {  
            var x = uiPersonne4_description.value;
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne4Desc({ stateInterventions_personne4_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiDateIntervention))),
              map(([{ data, error }, uiDateIntervention]) => {  
            var x = uiDateIntervention.value;
            x = x.toLocaleDateString('sv-SE');
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDateConvert({ stateDateConvert: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => combineLatest([of({ data, error }), 
          this.store.pipe(select(appSelectors.getStateIntIDIntervention)),
          this.store.pipe(select(appSelectors.getStateDateConvert)),
          this.store.pipe(select(appSelectors.getUiDateIntervention)),
          this.store.pipe(select(appSelectors.getUiHeureIntervention)),
          this.store.pipe(select(appSelectors.getUiNomAgent1)),
          this.store.pipe(select(appSelectors.getUiNomAgent2)),
          this.store.pipe(select(appSelectors.getUiNomAgentRemplacant)),
          this.store.pipe(select(appSelectors.getUiHoraire)),
          this.store.pipe(select(appSelectors.getUiTemperature)),
          this.store.pipe(select(appSelectors.getUiLieuIntervention)),
          this.store.pipe(select(appSelectors.getUiNumSecteur)),
          this.store.pipe(select(appSelectors.getUiCheckReferencement)),
          this.store.pipe(select(appSelectors.getUiCheckAppel311)),
          this.store.pipe(select(appSelectors.getUiCheckAppel911)),
          this.store.pipe(select(appSelectors.getUiCheckContactCitoyens)),
          this.store.pipe(select(appSelectors.getUiCheckContactMarginalisees)),
          this.store.pipe(select(appSelectors.getUiCheckContactOrganisme)),
          this.store.pipe(select(appSelectors.getUiCheckInterventionCommercant)),
          this.store.pipe(select(appSelectors.getUiCheckRamassageDechet)),
          this.store.pipe(select(appSelectors.getUiCheckMaterielDonne)),
          this.store.pipe(select(appSelectors.getUiCheckMaterielRecupere)),
          this.store.pipe(select(appSelectors.getUiCheckAppelRecu)),
          this.store.pipe(select(appSelectors.getUiCheckInterventionNuisible)),
          this.store.pipe(select(appSelectors.getUiCheckPremierssoins)),
          this.store.pipe(select(appSelectors.getUiCheckObservation)),
          this.store.pipe(select(appSelectors.getUiCheckAutres)),
          this.store.pipe(select(appSelectors.getStateInterventionsConvertDescription)),
          this.store.pipe(select(appSelectors.getUiNbPersonnes)),
          this.store.pipe(select(appSelectors.getUiNbVehicule)),
          this.store.pipe(select(appSelectors.getUiPersonne1Statut)),
          this.store.pipe(select(appSelectors.getUiPersonne1Nom)),
          this.store.pipe(select(appSelectors.getUiPersonne1Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne1Desc)),
          this.store.pipe(select(appSelectors.getUiPersonne2Statut)),
          this.store.pipe(select(appSelectors.getUiPersonne2Nom)),
          this.store.pipe(select(appSelectors.getUiPersonne2Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne2Desc)),
          this.store.pipe(select(appSelectors.getUiPersonne3Statut)),
          this.store.pipe(select(appSelectors.getUiPersonne3Nom)),
          this.store.pipe(select(appSelectors.getUiPersonne3Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne3Desc)),
          this.store.pipe(select(appSelectors.getUiPersonne4Statut)),
          this.store.pipe(select(appSelectors.getUiPersonne4Nom)),
          this.store.pipe(select(appSelectors.getUiPersonne4Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne4Desc)),
          this.store.pipe(select(appSelectors.getUiVehicule1Type)),
          this.store.pipe(select(appSelectors.getUiVehicule1Plaque)),
          this.store.pipe(select(appSelectors.getUiVehicule1Modele)),
          this.store.pipe(select(appSelectors.getUiVehicule1Couleur)),
          this.store.pipe(select(appSelectors.getUiVehicule1Signes)),
          this.store.pipe(select(appSelectors.getUiVehicule2Type)),
          this.store.pipe(select(appSelectors.getUiVehicule2Plaque)),
          this.store.pipe(select(appSelectors.getUiVehicule2Modele)),
          this.store.pipe(select(appSelectors.getUiVehicule2Couleur)),
          this.store.pipe(select(appSelectors.getUiVehicule2Signes))
        ]).pipe(take(1))),
        switchMap(([{ data, error }, stateIntIDIntervention, stateDateConvert, uiDateIntervention, uiHeureIntervention, uiNomAgent1, uiNomAgent2, uiNomAgentRemplacant, uiHoraire, uiTemperature, uiLieuIntervention, uiNumSecteur, uiCheck_Referencement, uiCheck_Appel311, uiCheck_Appel911, uiCheck_ContactCitoyens, uiCheck_ContactMarginalisees, uiCheck_ContactOrganisme, uiCheck_InterventionCommercant, uiCheck_RamassageDechet, uiCheck_MaterielDonne, uiCheck_MaterielRecupere, uiCheck_AppelRecu, uiCheck_InterventionNuisible, uiCheck_Premierssoins, uiCheck_Observation, uiCheck_Autres, stateInterventions_convert_description, uiNbPersonnes, uiNbVehicule, uiPersonne1_Statut, uiPersonne1_nom, uiPersonne1_matricule, stateInterventions_personne1_desc, uiPersonne2_Statut, uiPersonne2_nom, uiPersonne2_matricule, stateInterventions_personne2_desc, uiPersonne3_Statut, uiPersonne3_nom, uiPersonne3_matricule, stateInterventions_personne3_desc, uiPersonne4_Statut, uiPersonne4_nom, uiPersonne4_matricule, stateInterventions_personne4_desc, uiVehicule1_type, uiVehicule1_plaque, uiVehicule1_modele, uiVehicule1_Couleur, uiVehicule1_signes, uiVehicule2_type, uiVehicule2_plaque, uiVehicule2_modele, uiVehicule2_Couleur, uiVehicule2_signes]: any) => this.http.post('https://api.mafierte.ca/api/v2/sdc/_table/interventions', `{
      "resource": [
        { 
     "submission_id": ${stateIntIDIntervention},
        "date_intervention": "${stateDateConvert}",
        "dateutc": "${uiDateIntervention.value}",
        "heure_intervention": "${uiHeureIntervention.value}",
        "agent_1": "${uiNomAgent1.value}",
        "agent_2": "${uiNomAgent2.value}",
        "agent_remplacant": "${uiNomAgentRemplacant.value}",
        "horaire": "${uiHoraire.value}",
        "temp": "${uiTemperature.value}",
        "lieu": "${uiLieuIntervention.value}",
        "secteur": "${uiNumSecteur.value}",
        "nature_referencement": ${uiCheck_Referencement.value},
        "nature_311": ${uiCheck_Appel311.value},
        "nature_911": ${uiCheck_Appel911.value},
        "nature_contact_citoyen": ${uiCheck_ContactCitoyens.value},
        "nature_contact_population": ${uiCheck_ContactMarginalisees.value},
        "nature_contact_organisme": ${uiCheck_ContactOrganisme.value},
        "nature_intervention": ${uiCheck_InterventionCommercant.value},
        "nature_dechet": ${uiCheck_RamassageDechet.value},
        "nature_materiel_donne": ${uiCheck_MaterielDonne.value},
        "nature_materiel_recupere": ${uiCheck_MaterielRecupere.value},
        "nature_appelrecu": ${uiCheck_AppelRecu.value},
        "nature_nuisible": ${uiCheck_InterventionNuisible.value},
        "nature_premiersoins": ${uiCheck_Premierssoins.value},
        "nature_observation": ${uiCheck_Observation.value},
        "nature_autres": ${uiCheck_Autres.value},
        "description_situation": "${stateInterventions_convert_description}",
        "nb_personnes": "${uiNbPersonnes.value}",
        "nb_vehicules": "${uiNbVehicule.value}",
        "personne1_statut": "${uiPersonne1_Statut.value}",
        "personne1_nom": "${uiPersonne1_nom.value}",
        "personne1_matricule": "${uiPersonne1_matricule.value}",
        "personne1_description": "${stateInterventions_personne1_desc}",
        "personne2_statut": "${uiPersonne2_Statut.value}",
        "personne2_nom": "${uiPersonne2_nom.value}",
        "personne2_matricule": "${uiPersonne2_matricule.value}",
        "personne2_description": "${stateInterventions_personne2_desc}",
        "personne3_statut": "${uiPersonne3_Statut.value}",
        "personne3_nom": "${uiPersonne3_nom.value}",
        "personne3_matricule": "${uiPersonne3_matricule.value}",
        "personne3_description": "${stateInterventions_personne3_desc}",
        "personne4_statut": "${uiPersonne4_Statut.value}",
        "personne4_nom": "${uiPersonne4_nom.value}",
        "personne4_matricule": "${uiPersonne4_matricule.value}",
        "personne4_description": "${stateInterventions_personne4_desc}",
        "vehicule1_type": "${uiVehicule1_type.value}",
        "vehicule1_plaque": "${uiVehicule1_plaque.value}",
        "vehicule1_modele": "${uiVehicule1_modele.value}",
        "vehicule1_couleur": "${uiVehicule1_Couleur.value}",
        "vehicule1_signes": "${uiVehicule1_signes.value}",
        "vehicule2_type": "${uiVehicule2_type.value}",
        "vehicule2_plaque": "${uiVehicule2_plaque.value}",
        "vehicule2_modele": "${uiVehicule2_modele.value}",
        "vehicule2_couleur": "${uiVehicule2_Couleur.value}",
        "vehicule2_signes": "${uiVehicule2_signes.value}"
        }
      ],
      "ids": [
        0
      ],
      "filter": "string",
      "params": [
        "string"
      ]
    }`, {
          headers: {
            'Content-Type': 'application/json',
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        
        mergeMap(({ data, error }) => {
          const emitter = of({ data, error });
          return iif(
            () => {
              return !!data;
            },
            emitter.pipe(
              tap(({ data, error }) => this.router.navigateByUrl(`${'/interventions'}`)),
            ),emitter,
          )}),
      )
    ),
    share(),
  ), { dispatch: false });

  private updateInterventions$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.updateInterventions.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiCDescriptionSituation))),
              map(([{ data, error }, uiC_DescriptionSituation]) => {  
            var x = uiC_DescriptionSituation.value
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsConvertDescription({ stateInterventions_convert_description: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiCP1Description))),
              map(([{ data, error }, uiC_P1_description]) => {  
            var x = uiC_P1_description.value;
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne1Desc({ stateInterventions_personne1_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiCP2Description))),
              map(([{ data, error }, uiC_p2_description]) => {  
            var x = uiC_p2_description.value;
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne2Desc({ stateInterventions_personne2_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiCP3Description))),
              map(([{ data, error }, uiC_p3_description]) => {  
            var x = uiC_p3_description.value;
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne3Desc({ stateInterventions_personne3_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiCP4Description))),
              map(([{ data, error }, uiC_p4_description]) => {  
            var x = uiC_p4_description.value;
                x = x.replace(/(\r\n|\n|\r)/gm," ");
            x = x.replace(/"/g, "**");
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateInterventionsPersonne4Desc({ stateInterventions_personne4_desc: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          withLatestFrom(this.store.pipe(select(appSelectors.getUiCDateIntervention))),
              map(([{ data, error }, uiC_DateIntervention]) => {  
            var x = uiC_DateIntervention.value;
            x = x.toLocaleDateString('sv-SE');
            return x
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateDateConvert({ stateDateConvert: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap(({ data, error }) => combineLatest([of({ data, error }), 
          this.store.pipe(select(appSelectors.getStateTempSelectedIDIntervention)),
          this.store.pipe(select(appSelectors.getStateDateConvert)),
          this.store.pipe(select(appSelectors.getUiCDateIntervention)),
          this.store.pipe(select(appSelectors.getUiCHeureIntervention)),
          this.store.pipe(select(appSelectors.getUiCNomAgent1)),
          this.store.pipe(select(appSelectors.getUiCNomAgent2)),
          this.store.pipe(select(appSelectors.getUiCNomRemplacant)),
          this.store.pipe(select(appSelectors.getUiCHoraire)),
          this.store.pipe(select(appSelectors.getUiCTemperature)),
          this.store.pipe(select(appSelectors.getUiCLieu)),
          this.store.pipe(select(appSelectors.getUiCSecteur)),
          this.store.pipe(select(appSelectors.getUiCCheckReferencement)),
          this.store.pipe(select(appSelectors.getUiCCheck311)),
          this.store.pipe(select(appSelectors.getUiCCheck911)),
          this.store.pipe(select(appSelectors.getUiCCheckContactCitoyen)),
          this.store.pipe(select(appSelectors.getUiCCheckContactmarginalisee)),
          this.store.pipe(select(appSelectors.getUiCCheckContactOrganisme)),
          this.store.pipe(select(appSelectors.getUiCCheckInterventionCommercant)),
          this.store.pipe(select(appSelectors.getUiCCheckRamassagedechet)),
          this.store.pipe(select(appSelectors.getUiCCheckMaterieldonnee)),
          this.store.pipe(select(appSelectors.getUiCCheckMaterielRecupere)),
          this.store.pipe(select(appSelectors.getUiCCheckAppelRecu)),
          this.store.pipe(select(appSelectors.getUiCCheckNuisible)),
          this.store.pipe(select(appSelectors.getUiCCheckPremierssoins)),
          this.store.pipe(select(appSelectors.getUiCCheckObservation)),
          this.store.pipe(select(appSelectors.getUiCCheckAutres)),
          this.store.pipe(select(appSelectors.getStateInterventionsConvertDescription)),
          this.store.pipe(select(appSelectors.getUiCNbPersonnes)),
          this.store.pipe(select(appSelectors.getUiCNbvehicules)),
          this.store.pipe(select(appSelectors.getUiCP1Statut)),
          this.store.pipe(select(appSelectors.getUiCP1Nom)),
          this.store.pipe(select(appSelectors.getUiCP1Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne1Desc)),
          this.store.pipe(select(appSelectors.getUiCP2Statut)),
          this.store.pipe(select(appSelectors.getUiCP2Nom)),
          this.store.pipe(select(appSelectors.getUiCP2Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne2Desc)),
          this.store.pipe(select(appSelectors.getUiCP3Statut)),
          this.store.pipe(select(appSelectors.getUiCP3Nom)),
          this.store.pipe(select(appSelectors.getUiCP3Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne3Desc)),
          this.store.pipe(select(appSelectors.getUiCP4Statut)),
          this.store.pipe(select(appSelectors.getUiCP4Nom)),
          this.store.pipe(select(appSelectors.getUiCP4Matricule)),
          this.store.pipe(select(appSelectors.getStateInterventionsPersonne4Desc)),
          this.store.pipe(select(appSelectors.getUiCV1Type)),
          this.store.pipe(select(appSelectors.getUiCV1Plaque)),
          this.store.pipe(select(appSelectors.getUiCV1Modele)),
          this.store.pipe(select(appSelectors.getUiCV1Couleur)),
          this.store.pipe(select(appSelectors.getUiCV1Signes)),
          this.store.pipe(select(appSelectors.getUiCV2Type)),
          this.store.pipe(select(appSelectors.getUiCV2Plaque)),
          this.store.pipe(select(appSelectors.getUiCV2Modele)),
          this.store.pipe(select(appSelectors.getUiCV2Couleur)),
          this.store.pipe(select(appSelectors.getUiCV2Signes))
        ]).pipe(take(1))),
        switchMap(([{ data, error }, stateTempSelectedIDIntervention, stateDateConvert, uiC_DateIntervention, uiC_HeureIntervention, uiC_NomAgent1, uiC_NomAgent2, uiC_NomRemplacant, uiC_Horaire, uiC_Temperature, uiC_Lieu, uiC_Secteur, uiC_Check_Referencement, uiC_Check_311, uiC_Check_911, uiC_Check_ContactCitoyen, uiC_Check_contactmarginalisee, uiC_Check_ContactOrganisme, uiC_Check_InterventionCommercant, uiC_Check_ramassagedechet, uiC_Check_materieldonnee, uiC_Check_materielRecupere, uiC_Check_AppelRecu, uiC_Check_Nuisible, uiC_Check_premierssoins, uiC_Check_Observation, uiC_Check_autres, stateInterventions_convert_description, uiC_nbPersonnes, uiC_Nbvehicules, uiC_P1_statut, uiC_P1_nom, uiC_P1_matricule, stateInterventions_personne1_desc, uiC_P2_statut, uiC_P2_nom, uiC_P2_matricule, stateInterventions_personne2_desc, uiC_p3_statut, uiC_p3_nom, uiC_p3_matricule, stateInterventions_personne3_desc, uiC_p4_statut, uiC_p4_nom, uiC_p4_matricule, stateInterventions_personne4_desc, uiC_v1_type, uiC_v1_plaque, uiC_v1_modele, uiC_v1_couleur, uiC_v1_signes, uiC_v2_type, uiC_v2_plaque, uiC_v2_modele, uiC_v2_couleur, uiC_v2_signes]: any) => this.http.patch(`https://api.mafierte.ca/api/v2/sdc/_table/interventions/${stateTempSelectedIDIntervention}`, `
        { 
        "date_intervention": "${stateDateConvert}",
        "dateutc": "${uiC_DateIntervention.value}",
        "heure_intervention": "${uiC_HeureIntervention.value}",
        "agent_1": "${uiC_NomAgent1.value}",
        "agent_2": "${uiC_NomAgent2.value}",
        "agent_remplacant": "${uiC_NomRemplacant.value}",
        "horaire": "${uiC_Horaire.value}",
        "temp": "${uiC_Temperature.value}",
        "lieu": "${uiC_Lieu.value}",
        "secteur": "${uiC_Secteur.value}",
        "nature_referencement": ${uiC_Check_Referencement.value},
        "nature_311": ${uiC_Check_311.value},
        "nature_911": ${uiC_Check_911.value},
        "nature_contact_citoyen": ${uiC_Check_ContactCitoyen.value},
        "nature_contact_population": ${uiC_Check_contactmarginalisee.value},
        "nature_contact_organisme": ${uiC_Check_ContactOrganisme.value},
        "nature_intervention": ${uiC_Check_InterventionCommercant.value},
        "nature_dechet": ${uiC_Check_ramassagedechet.value},
        "nature_materiel_donne": ${uiC_Check_materieldonnee.value},
        "nature_materiel_recupere": ${uiC_Check_materielRecupere.value},
        "nature_appelrecu":${uiC_Check_AppelRecu.value},
        "nature_nuisible": ${uiC_Check_Nuisible.value},
        "nature_premiersoins": ${uiC_Check_premierssoins.value},
        "nature_observation": ${uiC_Check_Observation.value},
        "nature_autres": ${uiC_Check_autres.value},
        "description_situation": "${stateInterventions_convert_description}",
        "nb_personnes": "${uiC_nbPersonnes.value}",
        "nb_vehicules": "${uiC_Nbvehicules.value}",
        "personne1_statut": "${uiC_P1_statut.value}",
        "personne1_nom": "${uiC_P1_nom.value}",
        "personne1_matricule": "${uiC_P1_matricule.value}",
        "personne1_description": "${stateInterventions_personne1_desc}",
        "personne2_statut": "${uiC_P2_statut.value}",
        "personne2_nom": "${uiC_P2_nom.value}",
        "personne2_matricule": "${uiC_P2_matricule.value}",
        "personne2_description": "${stateInterventions_personne2_desc}",
        "personne3_statut": "${uiC_p3_statut.value}",
        "personne3_nom": "${uiC_p3_nom.value}",
        "personne3_matricule": "${uiC_p3_matricule.value}",
        "personne3_description": "${stateInterventions_personne3_desc}",
        "personne4_statut": "${uiC_p4_statut.value}",
        "personne4_nom": "${uiC_p4_nom.value}",
        "personne4_matricule": "${uiC_p4_matricule.value}",
        "personne4_description": "${stateInterventions_personne4_desc}",
        "vehicule1_type": "${uiC_v1_type.value}",
        "vehicule1_plaque": "${uiC_v1_plaque.value}",
        "vehicule1_modele": "${uiC_v1_modele.value}",
        "vehicule1_couleur": "${uiC_v1_couleur.value}",
        "vehicule1_signes": "${uiC_v1_signes.value}",
        "vehicule2_type": "${uiC_v2_type.value}",
        "vehicule2_plaque": "${uiC_v2_plaque.value}",
        "vehicule2_modele": "${uiC_v2_modele.value}",
        "vehicule2_couleur": "${uiC_v2_couleur.value}",
        "vehicule2_signes": "${uiC_v2_signes.value}"
        }
      `, {
          headers: {
            'Content-Type': 'application/json',
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        
        mergeMap(({ data, error }) => {
          const emitter = of({ data, error });
          return iif(
            () => {
              return !!data;
            },
            emitter.pipe(
              switchMap(({ data, error }) => {
                this.store.dispatch(AppActions.interventionsBackTo({ param: data }));
                return this.interventionsBackTo$
                  .pipe(
                    map((result) => result && result.data ? result : { data: result, error: null }),
                    catchError(error => of({ data: null, error })),
                    take(1),
                  );
              }),
            ),
            emitter.pipe(
              map(({ data, error }) => {
                alert("Une erreur est survenue dans l'envoi");
                return data;
              }),
              map(data => ({ data, error: null })),
              catchError(error => of({ data: null, error })),
            ),
          )}),
      )
    ),
    share(),
  ), { dispatch: false });

  private getAvis$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(AppActions.getAvis.type),
    mergeMap((action: { param: any }) => of({ data: action.param, error: null }, asapScheduler)
      .pipe(
        switchMap(({ data, error }) => this.http.get('https://api.mafierte.ca/api/v2/sdc/_table/avis/1', {
          headers: {
            'X-DreamFactory-API-Key': 'd2d259b55b9a8f6273c6a57427c1c963591fda937e08f9b7541c300e635cd386',
            'Content-Type': 'application/json',
          },
        })),
        map(data => ({ data, error: null })),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x = data;
            return x.message
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateAVISMessage({ stateAVIS_Message: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x = data;
            return x.titre
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateAVISTitre({ stateAVIS_Titre: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
        switchMap((data) => of(data).pipe(
          map(({ data, error }) => {  
            var x = data;
            return x.active
          }),
          tap((value) => this.store.dispatch(AppActions.updateStateAVISStatut({ stateAVIS_Statut: value }))),
          mapTo(data),
        )),
        observeOn(asyncScheduler),
        catchError(error => of({ data: null, error })),
      )
    ),
    share(),
  ), { dispatch: false });
  constructor(private router: Router,
    private store: Store<fromApp.State>,
    private http: HttpClient,
    private actions$: Actions) {
  }

}
