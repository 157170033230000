import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbRadioModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { RadioComponent } from './radio.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbRadioModule, ReactiveFormsModule],
  declarations: [RadioComponent],
  exports: [RadioComponent],
})
export class RadioModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: RadioComponent,
    schema: require('./radio.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./radio.calculator.svg').default,
        componentTree: require('!!raw-loader?!./radio.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./radio.component-tree-active.svg').default,
      },
    },
  };
}
