import { ChangeDetectorRef, Directive, Input, OnInit } from '@angular/core';

import { ComponentRenderer, ComponentRendererFactory } from '../component.renderer';
import { RenderComponent } from '../render-component.model';
import { Slot } from '../slot';

@Directive()
export abstract class SlotBaseDirective extends Slot implements OnInit {
  @Input() name: string = '';

  @Input() set content(components: RenderComponent[]) {
    this.componentRenderer.render(components, this.vcr);
  }

  protected componentRenderer: ComponentRenderer;

  constructor(componentRendererFactory: ComponentRendererFactory, public cd: ChangeDetectorRef) {
    super();
    this.componentRenderer = componentRendererFactory.create();
  }

  ngOnInit(): void {
    if (!this.name) {
      // throw new Error('Please provide a name for a slot');
    }
  }
}
