import { Injectable } from '@angular/core';

import { BreakpointWidth } from './component';

export type BreakpointMap<T = unknown> = Partial<Record<BreakpointWidth, T>>;

export type StyleEntry<Style = unknown> = [BreakpointWidth, Style];

export const breakpointsOrdering: Record<BreakpointWidth, number> = {
  [BreakpointWidth.Desktop]: 4,
  [BreakpointWidth.TabletLandscape]: 3,
  [BreakpointWidth.TabletPortrait]: 2,
  [BreakpointWidth.MobileLandscape]: 1,
  [BreakpointWidth.MobilePortrait]: 0,
};

@Injectable({ providedIn: 'root' })
export class StylesService {
  public getBreakpointOrder(breakpointWidth: BreakpointWidth): number {
    return breakpointsOrdering[breakpointWidth];
  }

  public getMostSuitableStyle<T>(styles: BreakpointMap<T>, breakpoint: BreakpointWidth): T | undefined {
    const activeStyles: T[] = this.createActiveStyles(styles, breakpoint);
    return activeStyles[activeStyles.length - 1];
  }

  public createActiveStyles<T>(styles: BreakpointMap<T>, breakpoint: BreakpointWidth): T[] {
    const entries: StyleEntry<T>[] = this.createStyleEntries(styles);
    return this.selectActiveStyles(entries, breakpoint);
  }

  private createStyleEntries<Style>(styles: BreakpointMap<Style>): StyleEntry<Style>[] {
    const entries: StyleEntry<Style>[] = Object.entries(styles || {}) as StyleEntry<Style>[];
    return entries.sort(([breakpoint1]: StyleEntry, [breakpoint2]: StyleEntry) => {
      return this.getBreakpointOrder(breakpoint2) - this.getBreakpointOrder(breakpoint1);
    });
  }

  private selectActiveStyles<Style>(entries: StyleEntry<Style>[], targetBreakpoint: BreakpointWidth): Style[] {
    return entries
      .filter(
        ([breakpoint]: StyleEntry<Style>) =>
          this.getBreakpointOrder(breakpoint) >= this.getBreakpointOrder(targetBreakpoint),
      )
      .map(([breakpoint, style]: StyleEntry<Style>) => style);
  }
}
