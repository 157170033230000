import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbButtonModule, NbIconModule } from '@nebular/theme';
import { COMPONENT_METADATA, ComponentMetadata, CoreModule } from '@uibakery/core';

import { IframeComponent } from './iframe.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbButtonModule, NbIconModule],
  declarations: [IframeComponent],
  exports: [IframeComponent],
})
export class IframeModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: IframeComponent,
    schema: require('./iframe.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./iframe.calculator.svg').default,
        componentTree: require('!!raw-loader?!./iframe.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./iframe.component-tree-active.svg').default,
      },
    },
  };
}
