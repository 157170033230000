import { ComponentType } from './component-type';
import { Schema } from './schema';

export const COMPONENT_METADATA: '__UI_BAKERY__COMPONENT_METADATA__' = '__UI_BAKERY__COMPONENT_METADATA__';

export interface ComponentModule extends ComponentType {
  [COMPONENT_METADATA]: ComponentMetadata;
}

export interface ComponentMetadata {
  componentType: ComponentType;
  schema: Schema;
  assets: ComponentMetadataAssets;
}

export interface ComponentMetadataAssets {
  icons: {
    calculator: string;
    componentTree: string;
    componentTreeActive: string;
  };
}

export interface ComponentSchemaWithAssets {
  schema: Schema;
  assets: ComponentMetadataAssets;
}
