import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { BaseComponent } from '@uibakery/core';
import { Direction, SizeProperty, WithSizeComponent, WithVisibleComponent } from '@uibakery/fields-types';

import { computeSize } from '../compute-size';

@Component({
  selector: 'ub-divider',
  styleUrls: ['./divider.component.scss'],
  template: ` <div class="divider" [ubDividerDragHandle]="direction"></div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent extends BaseComponent implements WithVisibleComponent, WithSizeComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { margins: string; paddings: '' } = { margins: '4px 0 4px 0', paddings: '' };
  @Input() direction: Direction = 'row';
  @Input() size: SizeProperty = {
    width: '100%',
    height: '2px',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  @HostBinding('style.background')
  @Input()
  backgroundColor: string = 'var(--bg-disabled-color)';

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size.height, this.spacing?.margins);
  }
}
