interface MergeFieldValueOptions {
  allowUndefined?: boolean;
}

export function mergeFieldValue<T>(
  value: T,
  defaults: T[],
  { allowUndefined = false }: MergeFieldValueOptions = {},
): T {
  let result: T = value;

  if (value !== null && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date)) {
    result = Object.assign({}, ...[...defaults].reverse(), value);
  } else if (value === undefined && !allowUndefined) {
    result = defaults.find((d: T) => d !== undefined)!;
  }

  return result;
}
