import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'td[ubTableRowCell]',
  template: `
    <ng-content></ng-content>
    <ub-slot name="content" [content]="content"></ub-slot>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableRowCellComponent extends BaseComponent {
  @Input() content?: RenderComponent[];
}
