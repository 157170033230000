import { Inject, Injectable } from '@angular/core';
import { NB_DOCUMENT } from '@nebular/theme';

const colorMap: { [key: string]: string } = {
  transparent: 'unset',
  primary: 'var(--color-primary-default)',
  success: 'var(--color-success-default)',
  warning: 'var(--color-warning-default)',
  danger: 'var(--color-danger-default)',
  info: 'var(--color-info-default)',
  alternate: 'var(--background-alternative-color-1)',
  disabled: 'var(--bg-disabled-color)',
  hint: 'var(--bg-hint-color)',
  default: 'var(--background-basic-color-1)',
};

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(@Inject(NB_DOCUMENT) private document: any) {}

  setVariable(name: string, value: string): void {
    this.document.body.style.setProperty(name, value);
  }

  getColorVariable(colorName: string | undefined): string {
    if (!colorName) {
      return '';
    }
    /**
     * `?? colorName` is need for passing CSS colors.
     * Like `rebeccapurple` which is valid CSS color,
     * but invalid color name in our theme.
     */
    return colorMap[colorName] ?? colorName;
  }
}
