import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { NbCalendarSize, NbCalendarViewMode } from '@nebular/theme';
import isValid from 'date-fns/isValid';
import { BehaviorSubject, merge, Observable } from 'rxjs';

import { BAKERY_COMPONENT, BaseComponent, ComponentInitService } from '@uibakery/core';
import { AlignSelfValue, FlexProperty, WithVisibleComponent } from '@uibakery/fields-types';

@Component({
  selector: 'ub-calendar',
  styleUrls: ['./calendar.component.scss'],
  template: `
    <nb-calendar
      [date]="value"
      (dateChange)="handleDateChange($event)"
      [startView]="startView"
      [showNavigation]="showNavigation"
      [size]="size"
      ubComponentShield
    ></nb-calendar>
  `,
  providers: [{ provide: BAKERY_COMPONENT, useExisting: CalendarComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent extends BaseComponent implements WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };
  @Input() size: NbCalendarSize = NbCalendarSize.MEDIUM;
  @Input() startView: NbCalendarViewMode = NbCalendarViewMode.DATE;
  @Input() showNavigation: boolean = true;
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @Output() change: EventEmitter<Date> = new EventEmitter<Date>();
  private _uiValue: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  @Output() uiValue: Observable<Date> = merge(this._uiValue.asObservable(), this.change);

  @HostBinding('style.margin')
  get margins(): string {
    return this.spacing?.margins;
  }

  get value(): Date | number | string | undefined {
    return this._value;
  }

  @Input() set value(value: Date | number | string | undefined) {
    if (value !== undefined && isValid(new Date(value))) {
      this._value = new Date(value);
    } else {
      this._value = undefined;
    }
    this._uiValue.next(this._value as Date);
  }
  private _value: Date | number | string | undefined = undefined;

  constructor(cd: ChangeDetectorRef, initService: ComponentInitService) {
    super(cd, initService);
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue {
    return this.flex?.alignSelf!;
  }

  @HostBinding('style.order')
  get flexOrder(): number {
    return this.flex?.order!;
  }

  @HostBinding('style.flex')
  get flexChild(): string {
    return this.flex?.flex!;
  }

  handleDateChange(date: Date): void {
    this.change.emit(date);

    /**
     * We have to set date in the _value prop here just because the calendar component
     * renders only data from its input - it has no internal state. That's why if the user didn't bind variable
     * to the calendar 'Date' input and didn't bind action that will update that variable calendar will
     * not change the selected value after clicks. So the user will conclude that it just doesn't work.
     * */
    this._value = date;
  }
}
