import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbInputModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { TextareaComponent } from './textarea.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CoreModule, NbInputModule],
  declarations: [TextareaComponent],
  exports: [TextareaComponent],
})
export class TextareaModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: TextareaComponent,
    schema: require('./textarea.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./textarea.calculator.svg').default,
        componentTree: require('!!raw-loader?!./textarea.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./textarea.component-tree-active.svg').default,
      },
    },
  };
}
