import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NbComponentSize, NbComponentStatus } from '@nebular/theme';

import { BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Component({
  selector: 'ub-progress-bar',
  styleUrls: ['./progress-bar.component.scss'],
  template: `
    <nb-progress-bar
      [status]="status"
      [size]="formFieldSize"
      [value]="value"
      [displayValue]="displayValue"
      [class.custom-height]="height !== 'auto'"
      [style.height]="height"
    >
    </nb-progress-bar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent extends BaseComponent
  implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() status: NbComponentStatus = 'primary';
  @Input() formFieldSize: NbComponentSize = 'medium';
  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '100px',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() displayValue: boolean = true;
  @Input() value: number = 50;
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return this.size?.width;
  }

  get height(): string | undefined {
    return this.size?.height;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }
}
