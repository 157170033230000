import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ub-no-data',
  styleUrls: ['./no-data.component.scss'],
  template: `
    <span>
      <nb-icon icon="bar-chart-outline"></nb-icon>
      No data to display
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {}
