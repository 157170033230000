import { Injectable } from '@angular/core';
import { SortDirection } from '@swimlane/ngx-datatable';

import {
  getColumnHeaderParamType,
  OldSmartTableColumn,
  OldSmartTableColumns,
  SmartTableColumn,
  SmartTableColumnHeaderParam,
  SmartTableColumnHeaderParamConfig,
  SmartTableColumnHeaderParamType,
  SmartTableTotalRowConfig,
} from '@uibakery/fields-types';

import {
  SmartTableColumnWithAdditionInfo,
  SmartTableData,
  SmartTablePageInfo,
  SmartTableRow,
  SmartTableSimpleData,
  SmartTableSortInfo,
  WithEdit,
} from './smart-table.models';

const DEFAULT_COLUMN_WIDTH: number = 250;

@Injectable()
export class SmartTableService {
  buildSmartTableSimpleData(
    allRows: SmartTableRow[],
    columns: SmartTableColumn[],
    filterValues: SmartTableRow,
    sortInfo: SmartTableSortInfo,
    updateTotalRowWhenFiltering: boolean,
    putEmptyValuesAtTheEnd: boolean,
  ): SmartTableSimpleData {
    let rows: SmartTableRow[] = allRows;

    if (this.isFilteringUsed(filterValues)) {
      rows = this.filterRows(rows, filterValues, columns);
    }

    if (this.isSortingUsed(sortInfo)) {
      rows = this.sortRows(rows, sortInfo, putEmptyValuesAtTheEnd);
    }

    const showAdditionRow: boolean = this.isNeedToShowAdditionRow(columns);

    const columnsWithAdditionInfos: SmartTableColumnWithAdditionInfo[] = this.addAdditionalInfoToColumns(
      columns,
      rows,
      allRows,
      updateTotalRowWhenFiltering,
    );

    return { columns: columnsWithAdditionInfos, rows, showAdditionRow };
  }

  buildSmartTableDataByPageInfo(
    pageInfo: SmartTablePageInfo,
    simpleData: SmartTableSimpleData,
    selectedRow: SmartTableRow | undefined,
    primaryKeys: string[],
  ): SmartTableData<SmartTableRow> {
    pageInfo = this.recountPageInfoByNewRowsAmount(pageInfo, simpleData.rows.length);
    if (pageInfo.offset >= pageInfo.pageSize) {
      pageInfo.offset = pageInfo.pageSize - 1;
    }

    let selectedRows: SmartTableRow[] = [];
    if (selectedRow) {
      const selectedRowIndex: number = this.findRowIndex(simpleData.rows, selectedRow, primaryKeys);
      const [startIndex, endIndex]: [number, number] = this.getPageRangeIndexes(pageInfo);
      if (this.isNumberInRange(selectedRowIndex, startIndex, endIndex - 1)) {
        selectedRows = [selectedRow];
      }
    }

    return {
      ...simpleData,
      pageInfo,
      selectedRows,
    };
  }

  updateSmartTableDataBySelectedRowIndex(
    selectedRowIndex: number,
    data: SmartTableData<SmartTableRow>,
    currentSelectedRow: SmartTableRow | undefined,
    primaryKeys: string[],
  ): [SmartTableData<SmartTableRow>, boolean] {
    const isIndexInRange: boolean = this.isNumberInRange(selectedRowIndex, 0, data.rows.length - 1);
    const rowInData: SmartTableRow = data.rows[selectedRowIndex];
    const isRowChanged: boolean = !this.isRowsEqual(rowInData, currentSelectedRow, primaryKeys);

    const newOffset: number = Math.max(
      0,
      Math.min(data.pageInfo.pageSize - 1, Math.floor(selectedRowIndex / data.pageInfo.limit)),
    );
    const isPageChanged: boolean = newOffset !== data.pageInfo.offset;

    data.selectedRows = isIndexInRange ? [rowInData] : [];
    data.pageInfo.offset = newOffset;

    return [data, isRowChanged || isPageChanged];
  }

  getRowsInPage(data: SmartTableData<SmartTableRow>): SmartTableRow[] {
    const [startIndex, endIndex]: [number, number] = this.getPageRangeIndexes(data.pageInfo);
    return data.rows.slice(startIndex, endIndex);
  }

  addWithEditToRows(
    smartTableData: SmartTableData<SmartTableRow>,
    editInfoMap: Map<SmartTableRow, SmartTableRow>,
    primaryKeys: string[],
  ): SmartTableData<WithEdit<SmartTableRow>> {
    const rowsWithEdit: WithEdit<SmartTableRow>[] = smartTableData.rows.map(
      (row: SmartTableRow): WithEdit<SmartTableRow> => {
        const editedRow: SmartTableRow | undefined = editInfoMap.get(row);
        const resultRow: SmartTableRow = editedRow || row;
        return {
          __SMART_TABLE_ROW_DATA__: row,
          __SMART_TABLE_ROW_EDITED_DATA__: resultRow,
          __SMART_TABLE_ROW_EDIT_STATUS__: !!editedRow,
          __SMART_TABLE_ROW_UNIQUE_KEY__: this.getUniqueRow(row, primaryKeys),
        };
      },
    );

    const uniqueSelectedRow: string | SmartTableRow | undefined = this.getUniqueRow(
      smartTableData.selectedRows[0],
      primaryKeys,
    );
    const selectedRow: WithEdit<SmartTableRow> | undefined = rowsWithEdit.find(
      (rowWithEdit: WithEdit<SmartTableRow>) => {
        return rowWithEdit.__SMART_TABLE_ROW_UNIQUE_KEY__ === uniqueSelectedRow;
      },
    );

    const selectedRows: WithEdit<SmartTableRow>[] = selectedRow ? [selectedRow] : [];

    return {
      ...smartTableData,
      rows: rowsWithEdit,
      selectedRows,
    };
  }

  buildNewPageInfo(current: SmartTablePageInfo, update: Partial<SmartTablePageInfo>): SmartTablePageInfo {
    return {
      ...current,
      ...update,
    };
  }

  countPageSize(itemsAmount: number, limit: number): number {
    const nextPageSize: number = Math.ceil(itemsAmount / limit);
    return nextPageSize === Infinity || nextPageSize === -Infinity ? 0 : nextPageSize;
  }

  buildTotalRowClasses(totalRowConfig?: SmartTableTotalRowConfig): Set<string> {
    const set: Set<string> = new Set<string>();

    if (totalRowConfig?.rowColor) {
      set.add(`total-row-bg-${totalRowConfig.rowColor}`);
    }

    if (totalRowConfig?.textColor) {
      set.add(`total-row-text-${totalRowConfig.textColor}`);
    }

    if (totalRowConfig?.textStyle && totalRowConfig.textStyle.length) {
      for (const textStyle of totalRowConfig.textStyle) {
        set.add(`total-row-text-style-${textStyle}`);
      }
    }

    return set;
  }

  buildValidColumns(columns: SmartTableColumn[] | OldSmartTableColumns): SmartTableColumn[] {
    if (typeof columns === 'object' && !Array.isArray(columns)) {
      return this.fromOldToNew(columns);
    }
    return columns;
  }

  getColumnHeaderParamType(column: SmartTableColumn, paramName: string): SmartTableColumnHeaderParamType {
    const param: boolean | SmartTableColumnHeaderParam | undefined = column[paramName];
    if (param === undefined) {
      return SmartTableColumnHeaderParamType.NONE;
    }
    return getColumnHeaderParamType(param);
  }

  getColumnHeaderParamConfig(
    column: SmartTableColumn,
    paramName: string,
  ): SmartTableColumnHeaderParamConfig | undefined {
    if (typeof column[paramName] === 'boolean') {
      return;
    }
    return column[paramName]?.config;
  }

  getPrimaryKeys(columns: SmartTableColumn[] = []): string[] {
    return columns
      .filter((column: SmartTableColumn) => column.primaryKey)
      .map((column: SmartTableColumn) => column.prop);
  }

  findRowIndex(rows: SmartTableRow[], row: SmartTableRow | undefined, primaryKeys: string[]): number {
    const uniqueRow: string | SmartTableRow | undefined = this.getUniqueRow(row, primaryKeys);

    return rows.findIndex((_row: SmartTableRow) => {
      return uniqueRow === this.getUniqueRow(_row, primaryKeys);
    });
  }

  isRowsEqual(row1: SmartTableRow | undefined, row2: SmartTableRow | undefined, keys: string[]): boolean {
    return this.getUniqueRow(row1, keys) === this.getUniqueRow(row2, keys);
  }

  getUniqueRow(row: SmartTableRow | undefined, keys: string[]): string | SmartTableRow | undefined {
    if (!row) {
      return undefined;
    }
    return keys.map((key: string) => row[key]).join('-') || row;
  }

  private isNeedToShowAdditionRow(columns: SmartTableColumn[]): boolean {
    return columns.some((column: SmartTableColumn) => !!column.filter);
  }

  private getPageRangeIndexes({ limit, offset }: SmartTablePageInfo): [number, number] {
    const startIndex: number = offset * limit;
    const endIndex: number = startIndex + limit;
    return [startIndex, endIndex];
  }

  private isNumberInRange(value: number, min: number, max: number): boolean {
    return min <= value && value <= max;
  }

  private recountPageInfoByNewRowsAmount(pageInfo: SmartTablePageInfo, rowsAmount: number): SmartTablePageInfo {
    return {
      ...pageInfo,
      pageSize: this.countPageSize(rowsAmount, pageInfo.limit),
      count: rowsAmount,
    };
  }

  private sortRows(
    rows: SmartTableRow[],
    { dir, prop }: SmartTableSortInfo,
    putEmptyValuesAtTheEnd: boolean,
  ): SmartTableRow[] {
    rows = [...rows];
    const isAsc: boolean = dir === SortDirection.asc;

    return rows.sort((row1: SmartTableRow, row2: SmartTableRow) => {
      const value1: unknown = row1[prop];
      const value2: unknown = row2[prop];

      // needs to set all undefined, null or '' values in end of array
      if (this.isEmptyRowValue(value1, putEmptyValuesAtTheEnd)) {
        return 1;
      }
      if (this.isEmptyRowValue(value2, putEmptyValuesAtTheEnd)) {
        return -1;
      }

      if (value1 > value2) {
        return isAsc ? 1 : -1;
      }
      if (value1 < value2) {
        return isAsc ? -1 : 1;
      }

      return 0;
    });
  }

  private isEmptyRowValue(value: unknown, putEmptyValuesAtTheEnd: boolean): boolean {
    if (putEmptyValuesAtTheEnd) {
      return value === null || value === undefined || value === '';
    }
    return value === undefined;
  }

  private filterRows(rows: SmartTableRow[], filterValues: SmartTableRow, columns: SmartTableColumn[]): SmartTableRow[] {
    const filterTypes: Record<string, SmartTableColumnHeaderParamType> = this.getFiltersTypes(columns);
    return rows.filter((row: SmartTableRow) => this.shouldRowFiltered(row, filterValues, filterTypes));
  }

  private shouldRowFiltered(
    row: SmartTableRow,
    filterValues: SmartTableRow,
    filterTypes: Record<string, SmartTableColumnHeaderParamType>,
  ): boolean {
    return Object.entries(filterValues).every(([columnName, value]: [string, string]) => {
      const rowValue: string | number | undefined | null = row[columnName];
      if (rowValue === undefined || rowValue === null) {
        return false;
      }
      const stringRowValue: string = rowValue.toString();
      const filterType: SmartTableColumnHeaderParamType =
        filterTypes[columnName] || SmartTableColumnHeaderParamType.NONE;
      if (filterType === SmartTableColumnHeaderParamType.NONE || filterType === SmartTableColumnHeaderParamType.INPUT) {
        return stringRowValue.toLowerCase().includes(value);
      }
      return stringRowValue === String(value);
    });
  }

  private getFiltersTypes(columns: SmartTableColumn[]): { [key: string]: SmartTableColumnHeaderParamType } {
    return columns.reduce((types: { [key: string]: SmartTableColumnHeaderParamType }, column: SmartTableColumn) => {
      types[column.prop] = this.getColumnHeaderParamType(column, 'filter');
      return types;
    }, {});
  }

  private fromOldToNew(columns: OldSmartTableColumns): SmartTableColumn[] {
    if (!columns) {
      return [];
    }
    return Object.entries(columns)
      .sort(([, valueA]: [string, OldSmartTableColumn], [, valueB]: [string, OldSmartTableColumn]): number => {
        return valueA.index - valueB.index;
      })
      .map(([prop, { title: name, filter }]: [string, OldSmartTableColumn]) => ({
        name,
        prop,
        filter,
        add: true,
        primaryKey: true,
        // there is no width setting in the old columns, so we need to add a default value.
        // and we cant migrate the model if rows received from data or variables
        width: DEFAULT_COLUMN_WIDTH,
      }));
  }

  private countSummaryInfo(items: unknown[]): string | undefined {
    let result: number = 0;
    let hasValidItems: boolean = false;

    for (const item of items) {
      if (!item) {
        continue;
      }
      const numberItem: number = Number(item);
      if (isNaN(numberItem)) {
        return;
      }
      result += numberItem;
      if (!hasValidItems) {
        hasValidItems = true;
      }
    }

    if (!hasValidItems && !result) {
      return;
    }

    return result.toString();
  }

  private addAdditionalInfoToColumns(
    columns: SmartTableColumn[],
    filteredRows: SmartTableRow[],
    allRows: SmartTableRow[],
    updateTotalRowWhenFiltering: boolean,
  ): SmartTableColumnWithAdditionInfo[] {
    return [...columns].map((column: SmartTableColumn) => {
      const rowsToCount: SmartTableRow[] = updateTotalRowWhenFiltering ? filteredRows : allRows;
      const summary: string | undefined = this.countSummaryInfo(
        rowsToCount.map((row: SmartTableRow) => row[column.prop]),
      );
      if (!column.width) {
        // there is no width setting in the old columns, so we need to add a default value.
        // and we cant migrate the model if rows received from data or variables
        column.width = DEFAULT_COLUMN_WIDTH;
      }
      return { column, summary };
    });
  }

  private isSortingUsed(sortInfo: SmartTableSortInfo): boolean {
    return !!sortInfo.prop && sortInfo.dir in SortDirection;
  }

  private isFilteringUsed(filterValues: SmartTableRow): boolean {
    return filterValues && Object.values(filterValues).some((value: unknown) => value ?? true);
  }
}
