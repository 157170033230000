import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent, ComponentInitService } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  LinkInfo,
  QueryParamsProperty,
  TextStyleProperty,
  WithFlexComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Component({
  selector: 'ub-link',
  styleUrls: ['./link.component.scss'],
  template: `
    <a
      *ngIf="internalPath"
      [routerLink]="link | internalUrl"
      [queryParams]="queryParams"
      [class]="classes"
      [style.font-size]="fontSize"
      [style.width.%]="100"
      [style.height.%]="100"
      ubComponentShield
    >
      {{ this.text }}
    </a>

    <a
      *ngIf="!internalPath"
      [href]="link | externalUrl: queryParams"
      [target]="openInNewTab ? '_blank' : '_top'"
      [class]="classes"
      [style.font-size]="fontSize"
      [style.width.%]="100"
      [style.height.%]="100"
      ubComponentShield
    >
      {{ this.text }}
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent extends BaseComponent implements WithFlexComponent, WithVisibleComponent {
  @Input() linkInfo: LinkInfo = {
    active: 'internalPath',
    internalPath: '',
    externalPath: '',
  };

  get internalPath(): boolean {
    return this.linkInfo.active === 'internalPath';
  }

  get link(): string {
    return this.linkInfo[this.linkInfo.active] || '';
  }
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() text: string = 'Text Link';
  @Input() queryParams: QueryParamsProperty = { '': '' };
  @Input() openInNewTab: boolean = false;
  @Input() fontSize: string = '15px';
  @Input() alignment: string = 'left';
  @Input() textStyle: TextStyleProperty = {
    transform: 'none',
  };
  @Input() decoration: string = 'underline';
  @Input() color: string = 'primary';
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }
  constructor(public cd: ChangeDetectorRef, private router: Router, initService: ComponentInitService) {
    super(cd, initService);
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  get classes(): string {
    let classString: string = `text-${this.color}`;
    classString = classString + (this.textStyle?.styles?.includes('italic') ? ' text-italic' : '');
    classString = classString + (this.textStyle?.styles?.includes('bold') ? ' text-bold' : '');
    classString = classString + (this.textStyle?.transform ? ` text-transform-${this.textStyle.transform}` : '');
    classString = classString + (this.alignment ? ` text-alignment-${this.alignment}` : '');
    classString = classString + (this.decoration ? ` text-decoration-${this.decoration}` : '');

    return classString;
  }
}
