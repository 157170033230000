import { ChangeDetectionStrategy, Component, HostBinding, Input, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BaseComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  TextStyleProperty,
  WithFlexComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

@Pipe({ name: 'htmlSupport' })
export class HtmlSupportPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, htmlSupport: boolean): SafeHtml {
    const unsafeValue: string = String(value);
    return htmlSupport
      ? this.sanitizer.bypassSecurityTrustHtml(unsafeValue)
      : unsafeValue
          // Temporal comment for making angular-like expression working in generated code
          // Following 4 lines wrap { and } symbols into {{'{'}} and {{'}'}} accordingly.
          // .replace(new RegExp('{', 'g'), open_curly)
          // .replace(new RegExp('}', 'g'), close_curly)
          // .replace(new RegExp(unique_open_curly_replacer, 'g'), '{')
          // .replace(new RegExp(unique_close_curly_replacer, 'g'), '}')
          .replace(new RegExp('<', 'g'), '&lt;')
          .replace(new RegExp('>', 'g'), '&gt;');
  }
}

@Component({
  selector: 'ub-text',
  styleUrls: ['./text.component.scss'],
  template: `
    <ng-container [ngSwitch]="true">
      <p
        *ngSwitchCase="type === 'paragraph' || type === 'paragraph-2'"
        [innerHTML]="text | htmlSupport: htmlSupport"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      ></p>
      <label
        *ngSwitchCase="type === 'label'"
        [innerHTML]="text | htmlSupport: htmlSupport"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      ></label>
      <div
        *ngSwitchCase="type === 'div'"
        [innerHTML]="text | htmlSupport: htmlSupport"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      ></div>
      <span
        *ngSwitchDefault
        [innerHTML]="text | htmlSupport: htmlSupport"
        [class]="classes"
        [style.font-size]="fontSize"
        [style.width.%]="100"
        [style.height.%]="100"
      ></span>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent extends BaseComponent implements WithFlexComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() text: string = 'New Text';
  @Input() type: string = 'paragraph';
  @Input() htmlSupport: boolean = false;
  @Input() fontSize: string = '15px';
  @Input() alignment: string = 'left';
  @Input() textStyle: TextStyleProperty = {
    transform: 'none',
  };
  @Input() decoration: string = '';
  @Input() color: string = 'basic';
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  get classes(): string {
    let classString: string = this.type ? `${this.type}` : '';
    classString = classString + ` text-${this.color}`;
    classString = classString + (this.textStyle?.styles?.includes('italic') ? ' text-italic' : '');
    classString = classString + (this.textStyle?.styles?.includes('bold') ? ' text-bold' : '');
    classString = classString + (this.textStyle?.transform ? ` text-transform-${this.textStyle.transform}` : '');
    classString = classString + (this.alignment ? ` text-alignment-${this.alignment}` : '');
    classString = classString + (this.decoration ? ` text-decoration-${this.decoration}` : '');

    return classString;
  }
}
