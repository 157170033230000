import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'tr[ubTableRow]',
  template: `
    <ng-content></ng-content>
    <ng-template ubSlot [content]="content"></ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableRowComponent extends BaseComponent {
  @Input() content?: RenderComponent[];

  @Input() visible: boolean = true;

  @HostBinding('style.background')
  @Input()
  backgroundColor: string = 'unset';

  @HostBinding('hidden')
  get hidden(): boolean {
    return !this.visible;
  }
}
