import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NbStepComponent, NbStepperComponent, NbStepperOrientation } from '@nebular/theme';

import { BAKERY_COMPONENT, BaseComponent, RenderComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  StepperContainerProperty,
  StepperVariation,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../../compute-size';
import { StepperItemInputs } from '../stepper-item/stepper-item.component';

export interface StepperComponentEvent {
  selectedIndex: number;
  length: number;
}

@Component({
  selector: 'ub-stepper',
  styleUrls: ['./stepper.component.scss'],
  template: `
    <ng-container *ngIf="variation === 'raw'">
      <ng-container *ngTemplateOutlet="stepper"></ng-container>
    </ng-container>

    <ng-container *ngIf="variation === 'card'">
      <nb-card>
        <nb-card-header *ngIf="title">{{ title }}</nb-card-header>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="stepper"></ng-container>
        </nb-card-body>
      </nb-card>
    </ng-container>

    <ng-template #stepper>
      <nb-stepper
        cdkScrollable
        [orientation]="orientation"
        [ngClass]="justify"
        [disableStepNavigation]="disableStepNavigation"
      >
        <nb-step
          *ngFor="let step of content; let first = first; let last = last; trackBy: trackStep"
          [label]="step.inputs.title"
        >
          <ub-slot [content]="[step]"></ub-slot>

          <div class="stepper-buttons">
            <button nbButton [disabled]="first" nbStepperPrevious (click)="onPrevClick()">{{ prevText }}</button>
            <button nbButton *ngIf="!last" nbStepperNext (click)="onNextClick()">{{ nextText }}</button>
            <button nbButton *ngIf="last && showComplete" (click)="onCompleteClick()">
              {{ completeText }}
            </button>
          </div>
        </nb-step>
      </nb-stepper>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BAKERY_COMPONENT, useExisting: StepperComponent }],
})
export class StepperComponent extends BaseComponent
  implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() content: RenderComponent<StepperItemInputs>[] = [];

  @Input() title: string = 'Stepper';
  @Input() variation: StepperVariation = 'card';

  @Input() stepperLayout: StepperContainerProperty = {
    direction: 'horizontal',
    justify: 'space-between',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  @Input() prevText: string = 'Prev';
  @Input() nextText: string = 'Next';
  @Input() completeText: string = 'Complete';
  @Input() showComplete: boolean = true;
  @Input() disableStepNavigation: boolean = false;

  @Output() init: EventEmitter<void> = new EventEmitter();
  @Output() stepperPrevStep: EventEmitter<StepperComponentEvent> = new EventEmitter();
  @Output() stepperNextStep: EventEmitter<StepperComponentEvent> = new EventEmitter();
  @Output() stepperComplete: EventEmitter<StepperComponentEvent> = new EventEmitter();
  @Output() lengthChange: EventEmitter<number> = new EventEmitter();
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();

  @ViewChild(NbStepperComponent) stepper!: NbStepperComponent;

  @ViewChildren(NbStepComponent)
  set steps(steps: QueryList<NbStepComponent[]>) {
    // set timeout prevents state update inside of render method
    setTimeout(() => {
      this.lengthChange.emit(steps.length);
    });
  }

  get orientation(): NbStepperOrientation | undefined {
    return this.stepperLayout.direction;
  }

  get justify(): string {
    return `justify-${this.stepperLayout.justify}`;
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.margin')
  get margins(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  onPrevClick(): void {
    this.emitStepperEvent(this.stepperPrevStep);
  }

  onNextClick(): void {
    this.emitStepperEvent(this.stepperNextStep);
  }

  onCompleteClick(): void {
    this.emitStepperEvent(this.stepperComplete);
  }

  private emitStepperEvent(event: EventEmitter<StepperComponentEvent>): void {
    const selectedIndex: number = this.stepper.selectedIndex;
    const length: number = this.stepper.steps.length;

    this.selectedIndexChange.emit(selectedIndex);

    event.emit({
      selectedIndex,
      length,
    });
  }

  trackStep(index: number, item: RenderComponent<StepperItemInputs>): string {
    return item.inputs.name;
  }
}
