import { Observable } from 'rxjs';

export enum AppMode {
  Builder = 'Builder',
  Preview = 'Preview',
}

export abstract class AppModeProvider {
  abstract mode$: Observable<AppMode>;
}
