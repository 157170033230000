import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { ComponentRenderer, ComponentRendererFactory } from '@uibakery/core';
import { AppComponentRenderer } from './renderer.service';

@Injectable()
export class AppComponentRendererFactory implements ComponentRendererFactory {
  constructor(private cfr: ComponentFactoryResolver) {}

  create(): ComponentRenderer {
    return new AppComponentRenderer(this.cfr);
  }
}
