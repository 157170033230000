import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { SmartTableAddAction } from '../smart-table.models';

@Component({
  selector: 'ub-smart-table-header-action-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cell.scss', './smart-table-header-action-cell.component.scss'],
  template: `
    <ng-container *ngIf="withAdditionRow">
      <div class="column" *ngIf="withAdd && !addModeActive">
        <button nbButton class="new-button" status="primary" (click)="action.emit(SmartTableAddAction.ADD)">
          <nb-icon icon="plus-outline"></nb-icon>
          NEW
        </button>
      </div>
      <div class="column" *ngIf="addModeActive">
        <button nbButton status="success" class="add-button" (click)="action.emit(SmartTableAddAction.ADD_SAVE)">
          <nb-icon icon="plus-outline"></nb-icon>
        </button>
        <button nbButton status="basic" (click)="action.emit(SmartTableAddAction.ADD_CANCEL)">
          <nb-icon icon="close"></nb-icon>
        </button>
      </div>
    </ng-container>
  `,
})
export class SmartTableHeaderActionCellComponent {
  public readonly SmartTableAddAction: typeof SmartTableAddAction = SmartTableAddAction;

  @HostBinding('class.with-addition-row')
  @Input()
  withAdditionRow!: boolean;

  @HostBinding('class.add-mode-active')
  @Input()
  addModeActive: boolean = false;
  @Input() withAdd: boolean = true;

  @Output() action: EventEmitter<SmartTableAddAction> = new EventEmitter<SmartTableAddAction>();
}
