import { ChangeDetectorRef, Directive, ViewContainerRef } from '@angular/core';

import { ComponentRendererFactory } from '../component.renderer';
import { SlotBaseDirective } from './slot.base';

@Directive({
  selector: '[ubSlot]',
})
export class SlotDirective extends SlotBaseDirective {
  constructor(componentRendererFactory: ComponentRendererFactory, cd: ChangeDetectorRef, public vcr: ViewContainerRef) {
    super(componentRendererFactory, cd);
  }
}
