import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType, SortDirection, SortType } from '@swimlane/ngx-datatable';
import equal from 'fast-deep-equal';
import ResizeObserver from 'resize-observer-polyfill';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { BAKERY_COMPONENT, BaseComponent, ComponentInitService } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  OldSmartTableColumns,
  SizeProperty,
  SmartTableActions,
  SmartTableColumn,
  SmartTableTotalRowConfig,
  SmartTableTotalRowPosition,
  SmartTableVariation,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';
import {
  SmartTableActionEvent,
  SmartTableAddAction,
  SmartTableColumnWithAdditionInfo,
  SmartTableData,
  SmartTablePageInfo,
  SmartTableRow,
  SmartTableRowAction,
  SmartTableSimpleData,
  SmartTableSortEvent,
  SmartTableSortInfo,
  SMART_TABLE_ROW_DATA,
  SMART_TABLE_ROW_EDIT_STATUS,
  WithEdit,
} from './smart-table.models';
import { SmartTableService } from './smart-table.service';

interface SmartTableHeaderValuesEvent {
  data: SmartTableRow;
  isFilter: boolean;
}

@Component({
  selector: 'ub-smart-table',
  styleUrls: ['./smart-table.component.scss'],
  templateUrl: 'smart-table.component.html',
  providers: [{ provide: BAKERY_COMPONENT, useExisting: SmartTableComponent }, SmartTableService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartTableComponent extends BaseComponent
  implements WithSizeComponent, WithVisibleComponent, AfterViewInit, OnDestroy {
  @Input() title: string = 'Table';
  @Input() variation: SmartTableVariation = 'card';
  @Input() putEmptyValuesAtTheEnd: boolean = true;
  @Input() resetActivePageOnNewData: boolean = false;

  @HostBinding('class.with-card')
  get isWithCard(): boolean {
    return this.variation === 'card';
  }

  public readonly ColumnMode: typeof ColumnMode = ColumnMode;
  public readonly SelectionType: typeof SelectionType = SelectionType;
  public readonly SortType: typeof SortType = SortType;

  public readonly ROW_DATA_KEY: string = SMART_TABLE_ROW_DATA;
  public readonly ROW_EDIT_STATUS_KEY: string = SMART_TABLE_ROW_EDIT_STATUS;

  @ViewChild(DatatableComponent) table?: DatatableComponent;

  private readonly _editInfoMap: Map<SmartTableRow, SmartTableRow> = new Map<SmartTableRow, SmartTableRow>();
  private readonly _editInfoMapChange: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  private readonly _sortInfo: BehaviorSubject<SmartTableSortInfo> = new BehaviorSubject<SmartTableSortInfo>({
    dir: SortDirection.asc,
    prop: '',
  });

  private readonly _headerValues: BehaviorSubject<SmartTableHeaderValuesEvent> = new BehaviorSubject<
    SmartTableHeaderValuesEvent
  >({ data: {}, isFilter: true });
  get headerValues(): SmartTableRow {
    return this._headerValues.value.data;
  }

  private _savedFilters: SmartTableRow = {};
  private readonly _filterValues$: Observable<SmartTableRow> = this._headerValues.pipe(
    debounceTime(250),
    filter(({ isFilter }: SmartTableHeaderValuesEvent) => isFilter),
    map(({ data }: SmartTableHeaderValuesEvent) => data),
    distinctUntilChanged((a: SmartTableRow, b: SmartTableRow) => equal(a, b)),
  );

  private readonly _rows: BehaviorSubject<SmartTableRow[]> = new BehaviorSubject<SmartTableRow[]>([
    {
      id: 1,
      fullName: 'Danielle Kennedy',
      userName: 'danielle.kennedy',
      email: 'danielle_91@example.com',
    },
    {
      id: 2,
      fullName: 'Russell Payne',
      userName: 'russell.payne',
      email: 'russell_88@example.com',
    },
    {
      id: 3,
      fullName: 'Brenda Hanson',
      userName: 'brenda.hanson',
      email: 'brenda97@example.com',
    },
    {
      id: 4,
      fullName: 'Nathan Knight',
      userName: 'nathan.knight',
      email: 'nathan-85@example.com',
    },
  ]);

  private _primaryKeys: string[] = [];
  private readonly _columns: BehaviorSubject<SmartTableColumn[]> = new BehaviorSubject<SmartTableColumn[]>([
    {
      prop: 'id',
      name: 'ID',
      primaryKey: true,
      filter: true,
      width: 50,
    },
    {
      prop: 'fullName',
      name: 'Full Name',
      primaryKey: false,
      filter: true,
      width: 150,
    },
    {
      prop: 'userName',
      name: 'User Name',
      primaryKey: false,
      filter: true,
      width: 150,
    },
    {
      prop: 'email',
      name: 'Email',
      primaryKey: false,
      filter: true,
      width: 150,
    },
  ]);

  private readonly _pageInfo: BehaviorSubject<SmartTablePageInfo> = new BehaviorSubject<SmartTablePageInfo>({
    count: 0,
    pageSize: 0,
    limit: 10,
    offset: 0,
  });

  private readonly _updateTotalRowWhenFiltering: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _updateTotalRowWhenFiltering$: Observable<
    boolean
  > = this._updateTotalRowWhenFiltering.asObservable().pipe(distinctUntilChanged());

  private _selectedRow?: SmartTableRow;
  private _selectedRowUnique?: string | SmartTableRow;
  private readonly _selectedRowChange: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private readonly _selectedRowIndex: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  // compute rows and columns by needed data
  private readonly _smartTableSimpleData$: Observable<SmartTableSimpleData> = combineLatest([
    this._rows,
    this._columns,
    this._filterValues$,
    this._sortInfo,
    this._updateTotalRowWhenFiltering$,
  ]).pipe(
    map((data: [SmartTableRow[], SmartTableColumn[], SmartTableRow, SmartTableSortInfo, boolean]) =>
      // @ts-ignore
      this.smService.buildSmartTableSimpleData(...data, this.putEmptyValuesAtTheEnd),
    ),
    shareReplay(1),
  );

  // compute all data for table according to changes of pageInfo and main data
  private readonly _smartTableDataWithPageInfoChanges$: Observable<SmartTableData<SmartTableRow>> = combineLatest([
    this._pageInfo,
    this._smartTableSimpleData$,
  ]).pipe(
    map(
      ([pageInfo, simpleData]: [SmartTablePageInfo, SmartTableSimpleData]): SmartTableData<SmartTableRow> =>
        // add selectedRows and page info to main data
        this.smService.buildSmartTableDataByPageInfo(pageInfo, simpleData, this._selectedRow, this._primaryKeys),
    ),
    shareReplay(1),
  );

  private readonly _smartTableDataWithSelectedRowIndexChanges$: Observable<
    SmartTableData<SmartTableRow>
  > = this._selectedRowIndex.asObservable().pipe(
    withLatestFrom(this._smartTableDataWithPageInfoChanges$),
    map(([selectedRowIndex, smartTableData]: [number, SmartTableData<SmartTableRow>]) =>
      // update/change selectedRows and page info according to selectedRowIndex changes
      this.smService.updateSmartTableDataBySelectedRowIndex(
        selectedRowIndex,
        smartTableData,
        this._selectedRow,
        this._primaryKeys,
      ),
    ),
    map(([data, isRowOrPageChanged]: [SmartTableData<SmartTableRow>, boolean]) => {
      if (isRowOrPageChanged) {
        const selectedRow: SmartTableRow | undefined = data.selectedRows[0];
        this.emitSmartTableRowSelectAction(selectedRow);
        this.setSelectedRow(selectedRow);
      }
      return data;
    }),
    shareReplay(1),
  );

  private readonly _smartTableDataWithSelectedRowChanges$: Observable<
    SmartTableData<SmartTableRow>
  > = this._selectedRowChange.asObservable().pipe(
    withLatestFrom(this._smartTableDataWithPageInfoChanges$),
    map(
      ([_, smartTableData]: [void, SmartTableData<SmartTableRow>]): SmartTableData<SmartTableRow> => {
        // update selectedRows according to selectedRow changes
        return {
          ...smartTableData,
          selectedRows: this._selectedRow ? [this._selectedRow] : [],
        };
      },
    ),
  );

  // listen to all changes with followed re subscription to editRows changes
  // cut rows in the current page and add edit info to it
  public readonly smartTableData$: Observable<SmartTableData<WithEdit<SmartTableRow>>> = merge(
    this._smartTableDataWithPageInfoChanges$,
    this._smartTableDataWithSelectedRowIndexChanges$,
    this._smartTableDataWithSelectedRowChanges$,
  ).pipe(
    switchMap((smartTableData: SmartTableData<SmartTableRow>) => {
      const { selectedRows }: SmartTableData<SmartTableRow> = smartTableData;
      if (selectedRows[0]) {
        this.emitSmartTableRowSelectAction(selectedRows[0]);
        this.setSelectedRow(selectedRows[0]);
      }
      const rowsInPage: SmartTableRow[] = this.smService.getRowsInPage(smartTableData);
      this.emitRowSelectionAfterDataChangeAndIfSelectedRowExist(this._rows.value, rowsInPage);
      return this._editInfoMapChange.asObservable().pipe(
        map(() => {
          return this.smService.addWithEditToRows(
            // make a copy to avoid changes by link
            {
              ...smartTableData,
              rows: rowsInPage,
            },
            this._editInfoMap,
            this._primaryKeys,
          );
        }),
      );
    }),
  );

  private _dataChangesWhereEmitted: boolean = false;

  @Input() set data(data: SmartTableRow[]) {
    if (!Array.isArray(data)) {
      data = [];
    }
    this._rows.next(data);
    const newPageInfo: Partial<SmartTablePageInfo> = {
      count: data.length,
      offset: this.resetActivePageOnNewData ? 0 : this._pageInfo.value.offset,
    };
    this.updatePageInfo(newPageInfo);
    this.updateUiSelectedRowValueIfSelectedRowChanged();
  }

  get data(): SmartTableRow[] {
    return this._rows.value;
  }

  @Input() set columns(columns: SmartTableColumn[] | OldSmartTableColumns) {
    // new ngx-datatable use array of an object instead of an object with objects like in ng2-smart-table
    // and we can't change the model for all project because some users can use data for columns field
    // that's why we need to check the old format for columns and change it for new
    const validColumns: SmartTableColumn[] = this.smService.buildValidColumns(columns);
    this._primaryKeys = this.smService.getPrimaryKeys(validColumns);
    this._columns.next(validColumns);
  }

  get columns(): SmartTableColumn[] | OldSmartTableColumns {
    return this._columns.value;
  }

  private _totalRowConfig: SmartTableTotalRowConfig = {
    show: false,
    position: SmartTableTotalRowPosition.TOP,
    rowColor: 'disabled',
    textStyle: [],
    textColor: 'primary',
    updateWhenFiltering: true,
  };
  totalRowClasses?: Set<string>;

  @Input() set totalRowConfig(totalRowConfig: SmartTableTotalRowConfig) {
    this._totalRowConfig = totalRowConfig;
    this._updateTotalRowWhenFiltering.next(!!totalRowConfig.updateWhenFiltering);
    this.totalRowClasses = this.smService.buildTotalRowClasses(this._totalRowConfig);
  }

  get totalRowConfig(): SmartTableTotalRowConfig {
    return this._totalRowConfig;
  }

  @Input() set limit(limit: number) {
    limit = parseInt((limit || 0).toString(), 10);
    limit = isNaN(limit) ? 0 : limit;
    this.updatePageInfo({
      limit,
      pageSize: this.smService.countPageSize(this._rows.value.length, limit),
    });
  }

  get limit(): number {
    return this._pageInfo.value.limit;
  }

  @Input() set selectedRowIndex(index: number) {
    index = parseInt(String(index), 10);
    // need to change the value if it has changed
    if (!isNaN(index) && index !== this._selectedRowIndex.value) {
      this._selectedRowIndex.next(index);
    }
  }

  get selectedRowIndex(): number {
    return this._selectedRowIndex.value;
  }

  @Input() tableActions: SmartTableActions = {
    add: false,
    edit: false,
    delete: false,
    showFilter: false,
  };

  addModeActive: boolean = false;

  get showActionColumn(): boolean {
    const { add, delete: deleteAction, edit }: Partial<SmartTableActions> = this.tableActions || {};
    return add || deleteAction || edit;
  }

  @Input() visible: boolean = true;

  @Input() spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  @HostBinding('style.display') get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin') get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.padding') get padding(): string | undefined {
    return this.spacing?.paddings;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    // TODO: revert after using min/max functionality
    // return computeSize(this.size.height, this.spacing?.margins);
    return 'auto';
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @Output() smartTableCreate: EventEmitter<SmartTableActionEvent> = new EventEmitter<SmartTableActionEvent>();
  @Output() smartTableEdit: EventEmitter<SmartTableActionEvent> = new EventEmitter<SmartTableActionEvent>();
  @Output() smartTableDelete: EventEmitter<SmartTableActionEvent> = new EventEmitter<SmartTableActionEvent>();
  private readonly _smartTableRowSelect: Subject<SmartTableRow> = new Subject<SmartTableRow>();
  @Output() smartTableRowSelect: Observable<SmartTableActionEvent> = this._smartTableRowSelect.asObservable().pipe(
    distinctUntilChanged((row1: SmartTableRow, row2: SmartTableRow) =>
      this.smService.isRowsEqual(row1, row2, this._primaryKeys),
    ),
    map((row: SmartTableRow | undefined) => {
      return {
        data: row,
        isSelected: !!row,
      };
    }),
  );
  @Output() uiSelectedRowIndex: Observable<number | undefined> = merge(
    this.smartTableRowSelect,
    this._selectedRowIndex,
  ).pipe(
    map((event: SmartTableActionEvent | number) => {
      if (typeof event === 'number') {
        return event;
      }
      if (!event.data) {
        return;
      }
      return this.smService.findRowIndex(this._rows.value, event.data, this._primaryKeys);
    }),
  );
  private _uiSmartTableSelectedRow: Subject<SmartTableRow> = new Subject<SmartTableRow>();
  private _uiSmartTableSelectedRow$: Observable<SmartTableActionEvent> = this._uiSmartTableSelectedRow.pipe(
    map((row: SmartTableRow) => {
      return {
        data: row,
        isSelected: !!row,
      };
    }),
  );
  @Output() uiSmartTableSelectedRow: Observable<SmartTableActionEvent> = merge(
    this.smartTableRowSelect,
    this._uiSmartTableSelectedRow$,
    this.smartTableEdit.pipe(
      filter((event: SmartTableActionEvent) => {
        const eventNewDataUnique: SmartTableRow | string = this.smService.getUniqueRow(
          event.newData,
          this._primaryKeys,
        );
        return eventNewDataUnique === this._selectedRowUnique;
      }),
      map((event: SmartTableActionEvent) => {
        return {
          data: event.newData,
          isSelected: true,
        };
      }),
    ),
    // set initial selectedRow by initial selectedRowIndex
    this.uiSelectedRowIndex.pipe(
      take(1),
      map((index: number) => {
        const currentSelectedRow: SmartTableRow = this._rows.value[index];
        return {
          data: currentSelectedRow,
          isSelected: !!currentSelectedRow,
        };
      }),
    ),
  );

  private _resizeObserver!: ResizeObserver;

  // arrow function because we register these function on ngx-datatable and we need to get the valid scope in it
  selectCheck = (row: WithEdit<SmartTableRow>): boolean => {
    return this._selectedRowUnique !== row.__SMART_TABLE_ROW_UNIQUE_KEY__;
  };

  constructor(
    cd: ChangeDetectorRef,
    initService: ComponentInitService,
    private smService: SmartTableService,
    private elementRef: ElementRef,
  ) {
    super(cd, initService);
    this._resizeObserver = new ResizeObserver(() => this.table?.recalculate());
  }

  ngAfterViewInit(): void {
    this._resizeObserver.observe(this.elementRef.nativeElement);
    // needs to trigger _smartTableDataWithSelectedRowIndexChanges$ observable in first init
    // for correct row selecting
    this._smartTableDataWithPageInfoChanges$.pipe(take(1)).subscribe(() => {
      const currentIndex: number = parseInt(this._selectedRowIndex.value.toString(), 10);
      // if valid index we need to emit onSelect action
      if (!isNaN(currentIndex) && currentIndex >= 0 && currentIndex < this._rows.value.length) {
        this._selectedRowIndex.next(currentIndex);
      }
    });

    // wait for getting data for correct render
    this.smartTableData$.pipe(take(1)).subscribe(() => {
      setTimeout(() => {
        if (!!this.table?.bodyComponent?.scroller) {
          // fix scroll issue https://github.com/swimlane/ngx-datatable/issues/1852
          this.table.bodyComponent.scroller.scrollWidth = 0;
        }
      });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._resizeObserver.unobserve(this.elementRef.nativeElement);
  }

  withLeftBorder(index: number): boolean {
    return index === 0;
  }

  withRightBorder(index: number, items: unknown[]): boolean {
    return index === items.length - 1 && !this.showActionColumn;
  }

  onRowSelect(event: { selected: WithEdit<SmartTableRow>[] } | Event): void {
    // Ensure we do not process DOM 'select' events.
    // https://github.com/swimlane/ngx-datatable/issues/899
    if (event instanceof Event || !event.selected) {
      return;
    }
    const selectedRow: SmartTableRow | undefined = event.selected[0]?.__SMART_TABLE_ROW_DATA__;
    this.emitSmartTableRowSelectAction(selectedRow);
    this.setSelectedRow(selectedRow);
    this._selectedRowChange.next();
  }

  onColumnSort(event: SmartTableSortEvent): void {
    this._sortInfo.next(event.sorts[0]);
  }

  // needed for prevent rerender components in header
  trackByForColumns(_: number, columnWithSummary: SmartTableColumnWithAdditionInfo): unknown {
    return columnWithSummary?.column?.prop;
  }

  onHeaderValueChange(value: string, column: SmartTableColumn): void {
    const isFilter: boolean = !(this.addModeActive && this.tableActions.add);
    if (isFilter) {
      value = (value ?? '').toString().toLowerCase();
    }
    let newValues: SmartTableRow = { ...this.headerValues };
    if (!value) {
      delete newValues[column.prop];
    } else {
      newValues = {
        ...newValues,
        [column.prop]: value,
      };
    }
    this.updateHeaderValues(newValues, isFilter);
  }

  rowIdentity(row: WithEdit<SmartTableRow>): string | SmartTableRow | undefined {
    return row.__SMART_TABLE_ROW_UNIQUE_KEY__;
  }

  onPageChanged(offset: number): void {
    this._editInfoMap.clear();
    this.updatePageInfo({ offset });
  }

  onRowValueChanged(value: string, column: SmartTableColumn, row: SmartTableRow): void {
    const updatedRow: SmartTableRow = { ...this._editInfoMap.get(row) };
    this._editInfoMap.set(row, { ...updatedRow, [column.prop]: value });
  }

  onRowEditConfirm(value: string, column: SmartTableColumn, row: SmartTableRow): void {
    this.onRowValueChanged(value, column, row);
    this.onRowAction(SmartTableRowAction.EDIT_SAVE, row);
  }

  onAddAction(action: SmartTableAddAction): void {
    if (action === SmartTableAddAction.ADD) {
      this.updateHeaderValues({}, false);
      return;
    }
    if (action === SmartTableAddAction.ADD_SAVE) {
      this.addRow({ ...this.headerValues });
    }
    this.updateHeaderValues(this._savedFilters, true);
  }

  onNewRowConfirm(value: string, column: SmartTableColumn): void {
    this.onHeaderValueChange(value, column);
    this.onAddAction(SmartTableAddAction.ADD_SAVE);
  }

  onRowAction(action: SmartTableRowAction, row: SmartTableRow): void {
    if (action === SmartTableRowAction.EDIT) {
      this._editInfoMap.set(row, row);
    }
    if (action === SmartTableRowAction.EDIT_SAVE) {
      const isRowUpdated: boolean = this.updateRow(row, this._editInfoMap.get(row));
      this._editInfoMap.delete(row);
      if (isRowUpdated) {
        return;
      }
    }
    if (action === SmartTableRowAction.EDIT_CANCEL) {
      this._editInfoMap.delete(row);
    }
    if (action === SmartTableRowAction.REMOVE) {
      this._editInfoMap.delete(row);
      this.removeRow(row);
      return;
    }

    // needed to trigger changes in all data
    this._editInfoMapChange.next();
  }

  private updateUiSelectedRowValueIfSelectedRowChanged(): void {
    const selectedRow: SmartTableRow = this._selectedRow;
    const rows: SmartTableRow[] = this._rows.value;
    const primaryKeys: string[] = this._primaryKeys;
    if (!selectedRow) {
      return;
    }
    const selectedRowIndex: number = this.smService.findRowIndex(rows, selectedRow, primaryKeys);
    const selectedRowInNewData: SmartTableRow | null = rows[selectedRowIndex];
    if (!selectedRowInNewData) {
      return;
    }
    if (this.smService.isRowsEqual(selectedRowInNewData, selectedRow, primaryKeys)) {
      this._uiSmartTableSelectedRow.next(selectedRowInNewData);
    }
  }

  private updateHeaderValues(data: SmartTableRow, isFilter: boolean): void {
    this._headerValues.next({ data, isFilter });
    this.addModeActive = !isFilter;
    if (isFilter) {
      this._savedFilters = this.headerValues;
    }
  }

  private addRow(row: SmartTableRow): void {
    this.emitSmartTableCreateAction({
      newData: row,
      isSelected: false,
    });
    if (row) {
      this._rows.next([...this._rows.value, row]);
    }
  }

  private updateRow(row: SmartTableRow, newRow: SmartTableRow | undefined): boolean {
    const isSelected: boolean = this._selectedRow === row;
    this.emitSmartTableEditAction({
      data: row,
      newData: newRow,
      isSelected,
    });
    if (!newRow || row === newRow) {
      return false;
    }
    if (isSelected && this.smService.isRowsEqual(row, newRow, this._primaryKeys)) {
      this.setSelectedRow(newRow);
    }
    const newRows: SmartTableRow[] = [...this._rows.value];
    const updatedRowIndex: number = newRows.indexOf(row);
    newRows.splice(updatedRowIndex, 1, newRow);
    this._rows.next(newRows);
    return true;
  }

  private removeRow(row: SmartTableRow): void {
    this.emitSmartTableDeleteAction({
      data: row,
      isSelected: this._selectedRow === row,
    });
    const newRows: SmartTableRow[] = [...this._rows.value];
    const updatedRowIndex: number = newRows.indexOf(row);
    newRows.splice(updatedRowIndex, 1);
    this.updatePageInfo({
      count: newRows.length,
      pageSize: this.smService.countPageSize(newRows.length, this._pageInfo.value.limit),
    });
    this._rows.next(newRows);
  }

  private updatePageInfo(update: Partial<SmartTablePageInfo>): void {
    this._pageInfo.next(this.smService.buildNewPageInfo(this._pageInfo.value, update));
  }

  private emitSmartTableCreateAction(data: SmartTableActionEvent): void {
    this.smartTableCreate.emit({ ...data });
  }

  private emitSmartTableDeleteAction(data: SmartTableActionEvent): void {
    this.smartTableDelete.emit({ ...data });
  }

  private emitSmartTableEditAction(data: SmartTableActionEvent): void {
    this.smartTableEdit.emit({ ...data });
  }

  private emitSmartTableRowSelectAction(data: SmartTableRow): void {
    this._smartTableRowSelect.next({ ...data } as SmartTableRow);
  }

  private emitRowSelectionAfterDataChangeAndIfSelectedRowExist(
    allRows: SmartTableRow[],
    pageRows: SmartTableRow[],
  ): void {
    /**
     * there is a selected row.
     * in the case when and there is no this row in a new data we need to emit deselect event
     * and in the case when this row exists in new data - we need to emit the select event
     *
     *
     * if selectedRow not in the data - emit deselect event and set flat = true
     *
     * if selectedRow wasn't in the data, and now, in the data = emit select and set flat = false;
     */
    const selectedRow: SmartTableRow | undefined = this._selectedRow;
    if (!selectedRow) {
      return;
    }
    const rowIndexInAllData: number = this.smService.findRowIndex(allRows, selectedRow, this._primaryKeys);
    const rowIndexInPageData: number = this.smService.findRowIndex(pageRows, selectedRow, this._primaryKeys);
    if (rowIndexInAllData !== -1 && rowIndexInPageData === -1) {
      // ignore page switching
      return;
    }
    if (rowIndexInAllData === -1) {
      this._dataChangesWhereEmitted = true;
      this.emitSmartTableRowSelectAction(undefined);
    }
    if (rowIndexInAllData >= 0 && this._dataChangesWhereEmitted) {
      this._dataChangesWhereEmitted = false;
      this.emitSmartTableRowSelectAction(selectedRow);
    }
  }

  private setSelectedRow(row: SmartTableRow | undefined): void {
    this._selectedRow = row;
    this._selectedRowUnique = this.smService.getUniqueRow(row, this._primaryKeys);
  }
}
