import { EChartOption } from 'echarts';

import {
  ChartConfig,
  ChartDataFormatType,
  ChartDataItem,
  ChartLegacyDataItem,
  ChartLegend,
  ChartVariation,
  DataObject,
} from '@uibakery/fields-types';

import { getRandomChartColor, to2DArray } from '../chart-config';
import { ChartDataMapper } from '../chart.types';
import { getGrid, getLegendDefaults, getTitleDefaults } from './settings';

import Label = echarts.EChartOption.BasicComponents.CartesianAxis.Label;

const initOptions: DataObject = {
  barMaxWidth: 15,
  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'shadow',
    },
    formatter: (config: { value: string | number[] }) => {
      return `<span id="tooltip">${config.value[0]}: <b>${config.value[1]}</b></span>`;
    },
    position: 'top',
    backgroundColor: '#223345',
    extraCssText: 'margin-top: -5px;',
  },
};

export class BarChartDataMapper implements ChartDataMapper {
  computeOptions(
    config: ChartConfig,
    legend: ChartLegend,
    colorConfig: { [colorName: string]: string },
    variation: ChartVariation,
  ): EChartOption {
    return {
      ...initOptions,
      grid: getGrid(variation),
      series: this.mapSeries(config, colorConfig),
      title: {
        ...getTitleDefaults(variation),
        text: config.title,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
      legend: {
        ...getLegendDefaults(variation),
        show: legend.visible,
        orient: legend.orientation,
        left: legend.horizontal,
        top: legend.vertical,
        textStyle: {
          fontFamily: colorConfig.fontMain,
          color: colorConfig.textColor,
        },
      },
      backgroundColor: colorConfig.bg,
      color: [colorConfig.primary],
      xAxis: {
        type: 'category',
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: colorConfig.axisLineColor,
          },
        },
        axisLabel: {
          textStyle: {
            fontFamily: colorConfig.fontMain,
            color: colorConfig.textColor,
          },
        } as Label,
      },
      yAxis: {
        type: 'value',
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: colorConfig.splitLineColor,
            type: 'dashed',
          },
        },
        axisLabel: {
          textStyle: {
            fontFamily: colorConfig.fontMain,
            color: colorConfig.textColor,
          },
        } as Label,
      },
    };
  }

  private mapSeries(config: ChartConfig, colorConfig: { [colorName: string]: string }): DataObject[] {
    return config.data.map((dataItem: ChartDataItem | ChartLegacyDataItem) => {
      let formatDependentOptions: DataObject;

      if (config.dataFormatType === ChartDataFormatType.KEY_VALUE_OBJECT) {
        const newFormatData: ChartDataItem = dataItem as ChartDataItem;
        formatDependentOptions = this.getDataFormatDependentOptions(
          colorConfig[newFormatData.color],
          newFormatData.title,
          to2DArray(newFormatData) as (number | string)[][],
        );
      } else {
        const legacyFormatData: ChartLegacyDataItem = dataItem as ChartLegacyDataItem;
        formatDependentOptions = this.getDataFormatDependentOptions(
          colorConfig[getRandomChartColor()],
          legacyFormatData.name,
          legacyFormatData.data as (number | string)[][],
        );
      }

      return {
        ...formatDependentOptions,
        type: 'bar',
        barWidth: `${50 / config.data.length}%`,
      };
    });
  }

  private getDataFormatDependentOptions(color: string, title: string, data: (number | string)[][]): DataObject {
    return {
      name: title,
      color,
      data: data.map((dataItem: (number | string)[]) => {
        return {
          value: dataItem,
          // check positive/negative number value in coordinate tuple to round bar borders correctly
          itemStyle: {
            barBorderRadius: <number>(dataItem[1] as unknown) < 0 ? [0, 0, 50, 50] : [50, 50, 0, 0],
          },
        };
      }),
    };
  }
}
