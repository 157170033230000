import { createAction, props } from '@ngrx/store';

export const updateStateLoginErrorMessage = createAction('[app] Update State Login Error Message', props<{ stateLoginErrorMessage: any }>());

export const updateStateAIRTABLETOKEN = createAction('[app] Update State A I R T A B L E T O K E N', props<{ stateAIR_TABLE_TOKEN: any }>());

export const updateStateListevenement = createAction('[app] Update State Listevenement', props<{ stateListevenement: any }>());

export const updateStateUsers2 = createAction('[app] Update State Users2', props<{ stateUsers2: any }>());

export const updateStateIntIDEvent = createAction('[app] Update State Int I D Event', props<{ stateIntIDEvent: any }>());

export const updateStateAmoutofEvent = createAction('[app] Update State Amoutof Event', props<{ stateAmoutofEvent: any }>());

export const updateStateGetDataFromEventID = createAction('[app] Update State Get Data From Event I D', props<{ stateGetDataFromEventID: any }>());

export const updateStateTempSelectedIDEvent = createAction('[app] Update State Temp Selected I D Event', props<{ stateTempSelectedIDEvent: any }>());

export const updateStateUsers = createAction('[app] Update State Users', props<{ stateUsers: any }>());

export const updateStateDataFromEventIDAdresse = createAction('[app] Update State Data From Event I D Adresse', props<{ stateDataFromEventID_Adresse: any }>());

export const updateStateDataFromEventIDTransversale = createAction('[app] Update State Data From Event I D Transversale', props<{ stateDataFromEventID_transversale: any }>());

export const updateStateDataFromEventIDNatureincident = createAction('[app] Update State Data From Event I D Natureincident', props<{ stateDataFromEventID_natureincident: any }>());

export const updateStateDataFromEventIDHeureincident = createAction('[app] Update State Data From Event I D Heureincident', props<{ stateDataFromEventID_heureincident: any }>());

export const updateStateDataFromEventIDDateincident = createAction('[app] Update State Data From Event I D Dateincident', props<{ stateDataFromEventID_dateincident: any }>());

export const updateStateDataFromEventIDDeroulement = createAction('[app] Update State Data From Event I D Deroulement', props<{ stateDataFromEventID_deroulement: any }>());

export const updateStateDataFromEventIDIndividu1 = createAction('[app] Update State Data From Event I D Individu1', props<{ stateDataFromEventID_individu1: any }>());

export const updateStateDataFromEventIDIndividu2 = createAction('[app] Update State Data From Event I D Individu2', props<{ stateDataFromEventID_individu2: any }>());

export const updateStateDataFromEventIDIndividu3 = createAction('[app] Update State Data From Event I D Individu3', props<{ stateDataFromEventID_individu3: any }>());

export const updateStateDataFromEventIDIndividu4 = createAction('[app] Update State Data From Event I D Individu4', props<{ stateDataFromEventID_individu4: any }>());

export const updateStateDataFromEventIDIndividu5 = createAction('[app] Update State Data From Event I D Individu5', props<{ stateDataFromEventID_individu5: any }>());

export const updateStateDataFromEventIDIndividu6 = createAction('[app] Update State Data From Event I D Individu6', props<{ stateDataFromEventID_individu6: any }>());

export const updateStateDataFromEventIDInstanceNom = createAction('[app] Update State Data From Event I D Instance Nom', props<{ stateDataFromEventID_instance_nom: any }>());

export const updateStateDataFromEventIDInstanceNomContact = createAction('[app] Update State Data From Event I D Instance Nom Contact', props<{ stateDataFromEventID_instance_nom_contact: any }>());

export const updateStateDataFromEventIDInstanceTel = createAction('[app] Update State Data From Event I D Instance Tel', props<{ stateDataFromEventID_instance_tel: any }>());

export const updateStateDataFromEventIDInstanceHeure = createAction('[app] Update State Data From Event I D Instance Heure', props<{ stateDataFromEventID_instance_heure: any }>());

export const updateStateDataFromEventIDInstanceSuivi = createAction('[app] Update State Data From Event I D Instance Suivi', props<{ stateDataFromEventID_instance_suivi: any }>());

export const updateStateDataFromEventIDInstanceSuiviNum = createAction('[app] Update State Data From Event I D Instance Suivi Num', props<{ stateDataFromEventID_instance_suivi_num: any }>());

export const updateStateMD5Users = createAction('[app] Update State M D5 Users', props<{ stateMD5Users: any }>());

export const updateStateValidtySessionToken = createAction('[app] Update State Validty Session Token', props<{ stateValidtySessionToken: any }>());

export const updateStateBDExtPWD = createAction('[app] Update State B D Ext P W D', props<{ stateBDExtPWD: any }>());

export const updateStateListinc = createAction('[app] Update State Listinc', props<{ stateListinc: any }>());

export const updateStateAmoutofINC = createAction('[app] Update State Amoutof I N C', props<{ stateAmoutofINC: any }>());

export const updateStateTempSelectedIDIntervention = createAction('[app] Update State Temp Selected I D Intervention', props<{ stateTempSelectedIDIntervention: any }>());

export const updateStateDataFromInterventionHoraire = createAction('[app] Update State Data From Intervention Horaire', props<{ stateDataFromIntervention_horaire: any }>());

export const updateStateDataFromInterventionTemps = createAction('[app] Update State Data From Intervention Temps', props<{ stateDataFromIntervention_temps: any }>());

export const updateStateDataFromInterventionLieu = createAction('[app] Update State Data From Intervention Lieu', props<{ stateDataFromIntervention_lieu: any }>());

export const updateStateDataFromInterventionSecteur = createAction('[app] Update State Data From Intervention Secteur', props<{ stateDataFromIntervention_secteur: any }>());

export const updateStateDataFromInterventionHeure = createAction('[app] Update State Data From Intervention Heure', props<{ stateDataFromIntervention_heure: any }>());

export const updateStateDataFromInterventionType = createAction('[app] Update State Data From Intervention Type', props<{ stateDataFromIntervention_type: any }>());

export const updateStateDataFromInterventionResolutionDetail = createAction('[app] Update State Data From Intervention Resolution Detail', props<{ stateDataFromIntervention_resolution_detail: any }>());

export const updateStateDataFromInterventionDescription = createAction('[app] Update State Data From Intervention Description', props<{ stateDataFromIntervention_description: any }>());

export const updateStateDataFromInterventionComments = createAction('[app] Update State Data From Intervention Comments', props<{ stateDataFromIntervention_comments: any }>());

export const updateStateDataFromInterventionAgent1 = createAction('[app] Update State Data From Intervention Agent1', props<{ stateDataFromIntervention_agent1: any }>());

export const updateStateDataFromInterventionAgent2 = createAction('[app] Update State Data From Intervention Agent2', props<{ stateDataFromIntervention_agent2: any }>());

export const updateStateDataFromInterventionDateintervention = createAction('[app] Update State Data From Intervention Dateintervention', props<{ stateDataFromIntervention_dateintervention: any }>());

export const updateStateIntIDIntervention = createAction('[app] Update State Int I D Intervention', props<{ stateIntIDIntervention: any }>());

export const updateStateSubmissionDate = createAction('[app] Update State Submission Date', props<{ stateSubmissionDate: any }>());

export const updateStateUsernameAPI = createAction('[app] Update State Username A P I', props<{ stateUsernameAPI: any }>());

export const updateStateInterventionsConvertComments = createAction('[app] Update State Interventions Convert Comments', props<{ stateInterventions_convert_comments: any }>());

export const updateStateInterventionsConvertDescription = createAction('[app] Update State Interventions Convert Description', props<{ stateInterventions_convert_description: any }>());

export const updateStateInterventionsConvertResolution = createAction('[app] Update State Interventions Convert Resolution', props<{ stateInterventions_convert_resolution: any }>());

export const updateStateDateConvert = createAction('[app] Update State Date Convert', props<{ stateDateConvert: any }>());

export const updateStateIncidentsConvertDescription = createAction('[app] Update State Incidents Convert Description', props<{ stateIncidents_convert_description: any }>());

export const updateStateIncidentsConvertIndividu1 = createAction('[app] Update State Incidents Convert Individu1', props<{ stateIncidents_convert_individu1: any }>());

export const updateStateIncidentsConvertIndividu2 = createAction('[app] Update State Incidents Convert Individu2', props<{ stateIncidents_convert_individu2: any }>());

export const updateStateIncidentsConvertIndividu3 = createAction('[app] Update State Incidents Convert Individu3', props<{ stateIncidents_convert_individu3: any }>());

export const updateStateIncidentsConvertIndividu4 = createAction('[app] Update State Incidents Convert Individu4', props<{ stateIncidents_convert_individu4: any }>());

export const updateStateIncidentsConvertIndividu5 = createAction('[app] Update State Incidents Convert Individu5', props<{ stateIncidents_convert_individu5: any }>());

export const updateStateIncidentsConvertIndividu6 = createAction('[app] Update State Incidents Convert Individu6', props<{ stateIncidents_convert_individu6: any }>());

export const updateStateIncidentsConvertDate = createAction('[app] Update State Incidents Convert Date', props<{ stateIncidents_convert_date: any }>());

export const updateStateB64F1 = createAction('[app] Update State B64 F1', props<{ stateB64F1: any }>());

export const updateStateDashIntJan = createAction('[app] Update State Dash Int Jan', props<{ stateDash_Int_Jan: any }>());

export const updateStateDashIntFeb = createAction('[app] Update State Dash Int Feb', props<{ stateDash_int_Feb: any }>());

export const updateStateDashIntMar = createAction('[app] Update State Dash Int Mar', props<{ stateDash_int_Mar: any }>());

export const updateStateDashIntApr = createAction('[app] Update State Dash Int Apr', props<{ stateDash_Int_Apr: any }>());

export const updateStateDashIncJan = createAction('[app] Update State Dash Inc Jan', props<{ stateDash_Inc_Jan: any }>());

export const updateStateDashIncFeb = createAction('[app] Update State Dash Inc Feb', props<{ stateDash_inc_feb: any }>());

export const updateStateDashIncMar = createAction('[app] Update State Dash Inc Mar', props<{ stateDash_inc_mar: any }>());

export const updateStateDashIncApr = createAction('[app] Update State Dash Inc Apr', props<{ stateDash_inc_apr: any }>());

export const updateStateDataFromInterventionDateinterventionUTC = createAction('[app] Update State Data From Intervention Dateintervention U T C', props<{ stateDataFromIntervention_dateinterventionUTC: any }>());

export const updateStateDataFromEventIDDateutc = createAction('[app] Update State Data From Event I D Dateutc', props<{ stateDataFromEventID_dateutc: any }>());

export const updateStateAVISMessage = createAction('[app] Update State A V I S Message', props<{ stateAVIS_Message: any }>());

export const updateStateAVISTitre = createAction('[app] Update State A V I S Titre', props<{ stateAVIS_Titre: any }>());

export const updateStateAVISStatut = createAction('[app] Update State A V I S Statut', props<{ stateAVIS_Statut: any }>());

export const updateStateTEMPPRINTINTERVENTION = createAction('[app] Update State T E M P P R I N T I N T E R V E N T I O N', props<{ stateTEMP_PRINTINTERVENTION: any }>());

export const updateStateTEMPPRINTINTERVENTIONURL = createAction('[app] Update State T E M P P R I N T I N T E R V E N T I O N U R L', props<{ stateTEMP_PRINTINTERVENTION_URL: any }>());

export const updateStateTEMPPRINTINTERVENTIONID = createAction('[app] Update State T E M P P R I N T I N T E R V E N T I O N I D', props<{ stateTEMP_PRINTINTERVENTION_ID: any }>());

export const updateStateTEMPPAYLOAD = createAction('[app] Update State T E M P P A Y L O A D', props<{ stateTEMP_PAYLOAD: any }>());

export const updateStateDateTest = createAction('[app] Update State Date Test', props<{ stateDateTest: any }>());

export const updateStateInterventionsPersonne1Desc = createAction('[app] Update State Interventions Personne1 Desc', props<{ stateInterventions_personne1_desc: any }>());

export const updateStateInterventionsPersonne2Desc = createAction('[app] Update State Interventions Personne2 Desc', props<{ stateInterventions_personne2_desc: any }>());

export const updateStateInterventionsPersonne3Desc = createAction('[app] Update State Interventions Personne3 Desc', props<{ stateInterventions_personne3_desc: any }>());

export const updateStateInterventionsPersonne4Desc = createAction('[app] Update State Interventions Personne4 Desc', props<{ stateInterventions_personne4_desc: any }>());

export const updateStateEventIDDateintervention = createAction('[app] Update State Event I D Dateintervention', props<{ stateEventID_dateintervention: any }>());

export const updateStateEventIDDateutc = createAction('[app] Update State Event I D Dateutc', props<{ stateEventID_dateutc: any }>());

export const updateStateEventIDHeureintervention = createAction('[app] Update State Event I D Heureintervention', props<{ stateEventID_heureintervention: any }>());

export const updateStateEventIDAgent1 = createAction('[app] Update State Event I D Agent1', props<{ stateEventID_agent1: any }>());

export const updateStateEventIDAgent2 = createAction('[app] Update State Event I D Agent2', props<{ stateEventID_agent2: any }>());

export const updateStateEventIDAgentremplacant = createAction('[app] Update State Event I D Agentremplacant', props<{ stateEventID_agentremplacant: any }>());

export const updateStateEventIDHoraire = createAction('[app] Update State Event I D Horaire', props<{ stateEventID_horaire: any }>());

export const updateStateEventIDTemperature = createAction('[app] Update State Event I D Temperature', props<{ stateEventID_temperature: any }>());

export const updateStateEventIDLieu = createAction('[app] Update State Event I D Lieu', props<{ stateEventID_lieu: any }>());

export const updateStateEventIDSecteur = createAction('[app] Update State Event I D Secteur', props<{ stateEventID_secteur: any }>());

export const updateStateEventIDNatureReferencement = createAction('[app] Update State Event I D Nature Referencement', props<{ stateEventID_nature_referencement: any }>());

export const updateStateEventIDNature311 = createAction('[app] Update State Event I D Nature311', props<{ stateEventID_nature_311: any }>());

export const updateStateEventIDNature911 = createAction('[app] Update State Event I D Nature911', props<{ stateEventID_nature_911: any }>());

export const updateStateEventIDNatureContactCitoyen = createAction('[app] Update State Event I D Nature Contact Citoyen', props<{ stateEventID_nature_contact_citoyen: any }>());

export const updateStateEventIDNatureContactPopulation = createAction('[app] Update State Event I D Nature Contact Population', props<{ stateEventID_nature_contact_population: any }>());

export const updateStateEventIDNatureContactOrganisme = createAction('[app] Update State Event I D Nature Contact Organisme', props<{ stateEventID_nature_contact_organisme: any }>());

export const updateStateEventIDNatureIntervention = createAction('[app] Update State Event I D Nature Intervention', props<{ stateEventID_nature_intervention: any }>());

export const updateStateEventIDNatureDechet = createAction('[app] Update State Event I D Nature Dechet', props<{ stateEventID_nature_dechet: any }>());

export const updateStateEventIDNatureMaterielDonne = createAction('[app] Update State Event I D Nature Materiel Donne', props<{ stateEventID_nature_materiel_donne: any }>());

export const updateStateEventIDNatureMaterielRecupere = createAction('[app] Update State Event I D Nature Materiel Recupere', props<{ stateEventID_nature_materiel_recupere: any }>());

export const updateStateEventIDNatureAppelrecu = createAction('[app] Update State Event I D Nature Appelrecu', props<{ stateEventID_nature_appelrecu: any }>());

export const updateStateEventIDNatureNuisible = createAction('[app] Update State Event I D Nature Nuisible', props<{ stateEventID_nature_nuisible: any }>());

export const updateStateEventIDNaturePremiersoins = createAction('[app] Update State Event I D Nature Premiersoins', props<{ stateEventID_nature_premiersoins: any }>());

export const updateStateEventIDNatureObservation = createAction('[app] Update State Event I D Nature Observation', props<{ stateEventID_nature_observation: any }>());

export const updateStateEventIDNatureAutres = createAction('[app] Update State Event I D Nature Autres', props<{ stateEventID_nature_autres: any }>());

export const updateStateEventIDDescriptionSituation = createAction('[app] Update State Event I D Description Situation', props<{ stateEventID_description_situation: any }>());

export const updateStateEventIDNbpersonnes = createAction('[app] Update State Event I D Nbpersonnes', props<{ stateEventID_nbpersonnes: any }>());

export const updateStateEventIDNbvehicules = createAction('[app] Update State Event I D Nbvehicules', props<{ stateEventID_nbvehicules: any }>());

export const updateStateEventIDPersonne1Statut = createAction('[app] Update State Event I D Personne1 Statut', props<{ stateEventID_personne1_statut: any }>());

export const updateStateEventIDPersonne1Nom = createAction('[app] Update State Event I D Personne1 Nom', props<{ stateEventID_personne1_nom: any }>());

export const updateStateEventIDPersonne1Matricule = createAction('[app] Update State Event I D Personne1 Matricule', props<{ stateEventID_personne1_matricule: any }>());

export const updateStateEventIDPersonne1Description = createAction('[app] Update State Event I D Personne1 Description', props<{ stateEventID_personne1_description: any }>());

export const updateStateEventIDPersonne2Statut = createAction('[app] Update State Event I D Personne2 Statut', props<{ stateEventID_personne2_statut: any }>());

export const updateStateEventIDPersonne2Nom = createAction('[app] Update State Event I D Personne2 Nom', props<{ stateEventID_personne2_nom: any }>());

export const updateStateEventIDPersonne2Matricule = createAction('[app] Update State Event I D Personne2 Matricule', props<{ stateEventID_personne2_matricule: any }>());

export const updateStateEventIDPersonne2Description = createAction('[app] Update State Event I D Personne2 Description', props<{ stateEventID_personne2_description: any }>());

export const updateStateEventIDPersonne3Statut = createAction('[app] Update State Event I D Personne3 Statut', props<{ stateEventID_personne3_statut: any }>());

export const updateStateEventIDPersonne3Nom = createAction('[app] Update State Event I D Personne3 Nom', props<{ stateEventID_personne3_nom: any }>());

export const updateStateEventIDPersonne3Matricule = createAction('[app] Update State Event I D Personne3 Matricule', props<{ stateEventID_personne3_matricule: any }>());

export const updateStateEventIDPersonne3Description = createAction('[app] Update State Event I D Personne3 Description', props<{ stateEventID_personne3_description: any }>());

export const updateStateEventIDPersonne4Statut = createAction('[app] Update State Event I D Personne4 Statut', props<{ stateEventID_personne4_statut: any }>());

export const updateStateEventIDPersonne4Nom = createAction('[app] Update State Event I D Personne4 Nom', props<{ stateEventID_personne4_nom: any }>());

export const updateStateEventIDPersonne4Matricule = createAction('[app] Update State Event I D Personne4 Matricule', props<{ stateEventID_personne4_matricule: any }>());

export const updateStateEventIDPersonne4Description = createAction('[app] Update State Event I D Personne4 Description', props<{ stateEventID_personne4_description: any }>());

export const updateStateEventIDVehicule1Type = createAction('[app] Update State Event I D Vehicule1 Type', props<{ stateEventID_vehicule1_type: any }>());

export const updateStateEventIDVehicule1Plaque = createAction('[app] Update State Event I D Vehicule1 Plaque', props<{ stateEventID_vehicule1_plaque: any }>());

export const updateStateEventIDVehicule1Modele = createAction('[app] Update State Event I D Vehicule1 Modele', props<{ stateEventID_vehicule1_modele: any }>());

export const updateStateEventIDVehicule1Couleur = createAction('[app] Update State Event I D Vehicule1 Couleur', props<{ stateEventID_vehicule1_couleur: any }>());

export const updateStateEventIDVehicule1Signes = createAction('[app] Update State Event I D Vehicule1 Signes', props<{ stateEventID_vehicule1_signes: any }>());

export const updateStateEventIDVehicule2Type = createAction('[app] Update State Event I D Vehicule2 Type', props<{ stateEventID_vehicule2_type: any }>());

export const updateStateEventIDVehicule2Plaque = createAction('[app] Update State Event I D Vehicule2 Plaque', props<{ stateEventID_vehicule2_plaque: any }>());

export const updateStateEventIDVehicule2Modele = createAction('[app] Update State Event I D Vehicule2 Modele', props<{ stateEventID_vehicule2_modele: any }>());

export const updateStateEventIDVehicule2Couleur = createAction('[app] Update State Event I D Vehicule2 Couleur', props<{ stateEventID_vehicule2_couleur: any }>());

export const updateStateEventIDVehicule2Signes = createAction('[app] Update State Event I D Vehicule2 Signes', props<{ stateEventID_vehicule2_signes: any }>());

export const updateStateAmountofInterventions = createAction('[app] Update State Amountof Interventions', props<{ stateAmountofInterventions: any }>());

export const updateStateListInterventions = createAction('[app] Update State List Interventions', props<{ stateListInterventions: any }>());

export const updateStateTempSelectedIntervention = createAction('[app] Update State Temp Selected Intervention', props<{ stateTempSelectedIntervention: any }>());

export const updateStateListinc2 = createAction('[app] Update State Listinc2', props<{ stateListinc2: any }>());

export const updateStateListconcat = createAction('[app] Update State Listconcat', props<{ stateListconcat: any }>());

export const updateStateAMoutofINTCONCAT = createAction('[app] Update State A Moutof I N T C O N C A T', props<{ stateAMoutofINTCONCAT: any }>());

export const listeDesUtilisateurs = createAction('[App] Liste Des Utilisateurs', props<{ param: any }>());

export const checkUserValid = createAction('[App] Check User Valid', props<{ param: any }>());

export const interventionsGotosubmit = createAction('[App] Interventions Gotosubmit', props<{ param: any }>());

export const interventionsBackTo = createAction('[App] Interventions Back To', props<{ param: any }>());

export const archivesIncidentsGoto = createAction('[App] Archives Incidents Goto', props<{ param: any }>());

export const archivesInterventionsGOTO = createAction('[App] Archives Interventions G O T O', props<{ param: any }>());

export const dashboardCount = createAction('[App] Dashboard Count', props<{ param: any }>());

export const archivesIncidentsConsult = createAction('[App] Archives Incidents Consult', props<{ param: any }>());

export const getBackHome = createAction('[App] Get Back Home', props<{ param: any }>());

export const archivesIncidentsList = createAction('[App] Archives Incidents List', props<{ param: any }>());

export const archivesIncidentsGetData = createAction('[App] Archives Incidents Get Data', props<{ param: any }>());

export const printInt = createAction('[App] Print Int', props<{ param: any }>());

export const archivesInterventionsConsult = createAction('[App] Archives Interventions Consult', props<{ param: any }>());

export const archivesInterventionsList = createAction('[App] Archives Interventions List', props<{ param: any }>());

export const archivesInterventionsGetData = createAction('[App] Archives Interventions Get Data', props<{ param: any }>());

export const interventionsSeeResults = createAction('[App] Interventions See Results', props<{ param: any }>());

export const interventionsList = createAction('[App] Interventions List', props<{ param: any }>());

export const getData = createAction('[App] Get Data', props<{ param: any }>());

export const interventionsNew = createAction('[App] Interventions New', props<{ param: any }>());

export const updateInterventions = createAction('[App] Update Interventions', props<{ param: any }>());

export const getAvis = createAction('[App] Get Avis', props<{ param: any }>());

export const updateUiUsername = createAction('[app] Update Ui Username', props<{
  param: {
    value?: any,
  }}>());

export const updateUiSecret = createAction('[app] Update Ui Secret', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTabs2 = createAction('[app] Update Ui Tabs2', props<{
  param: {
    selectedIndex?: any,
    length?: any,
  }}>());

export const updateUiSmartTable = createAction('[app] Update Ui Smart Table', props<{
  param: {
    deletedRow?: any,
    editedRow?: any,
    newRow?: any,
    selectedRow?: any,
    selectedRowIndex?: any,
  }}>());

export const updateUiTabs = createAction('[app] Update Ui Tabs', props<{
  param: {
    selectedIndex?: any,
    length?: any,
  }}>());

export const updateUiTextArea = createAction('[app] Update Ui Text Area', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea1 = createAction('[app] Update Ui Text Area1', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea2 = createAction('[app] Update Ui Text Area2', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea3 = createAction('[app] Update Ui Text Area3', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea4 = createAction('[app] Update Ui Text Area4', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea41 = createAction('[app] Update Ui Text Area41', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea42 = createAction('[app] Update Ui Text Area42', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea5 = createAction('[app] Update Ui Text Area5', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea6 = createAction('[app] Update Ui Text Area6', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTextArea7 = createAction('[app] Update Ui Text Area7', props<{
  param: {
    value?: any,
  }}>());

export const updateUiSmartTableIAInter = createAction('[app] Update Ui Smart Table I A Inter', props<{
  param: {
    deletedRow?: any,
    editedRow?: any,
    newRow?: any,
    selectedRow?: any,
    selectedRowIndex?: any,
  }}>());

export const updateUiTableInterventions = createAction('[app] Update Ui Table Interventions', props<{
  param: {
    deletedRow?: any,
    editedRow?: any,
    newRow?: any,
    selectedRow?: any,
    selectedRowIndex?: any,
  }}>());

export const updateUiDateIntervention = createAction('[app] Update Ui Date Intervention', props<{
  param: {
    value?: any,
  }}>());

export const updateUiHeureIntervention = createAction('[app] Update Ui Heure Intervention', props<{
  param: {
    value?: any,
  }}>());

export const updateUiNomAgent1 = createAction('[app] Update Ui Nom Agent1', props<{
  param: {
    value?: any,
  }}>());

export const updateUiNomAgent2 = createAction('[app] Update Ui Nom Agent2', props<{
  param: {
    value?: any,
  }}>());

export const updateUiNomAgentRemplacant = createAction('[app] Update Ui Nom Agent Remplacant', props<{
  param: {
    value?: any,
  }}>());

export const updateUiHoraire = createAction('[app] Update Ui Horaire', props<{
  param: {
    value?: any,
  }}>());

export const updateUiTemperature = createAction('[app] Update Ui Temperature', props<{
  param: {
    value?: any,
  }}>());

export const updateUiLieuIntervention = createAction('[app] Update Ui Lieu Intervention', props<{
  param: {
    value?: any,
  }}>());

export const updateUiNumSecteur = createAction('[app] Update Ui Num Secteur', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckReferencement = createAction('[app] Update Ui Check Referencement', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckAppel311 = createAction('[app] Update Ui Check Appel311', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckAppel911 = createAction('[app] Update Ui Check Appel911', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckContactCitoyens = createAction('[app] Update Ui Check Contact Citoyens', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckContactMarginalisees = createAction('[app] Update Ui Check Contact Marginalisees', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckContactOrganisme = createAction('[app] Update Ui Check Contact Organisme', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckInterventionCommercant = createAction('[app] Update Ui Check Intervention Commercant', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckRamassageDechet = createAction('[app] Update Ui Check Ramassage Dechet', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckMaterielDonne = createAction('[app] Update Ui Check Materiel Donne', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckMaterielRecupere = createAction('[app] Update Ui Check Materiel Recupere', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckAppelRecu = createAction('[app] Update Ui Check Appel Recu', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckInterventionNuisible = createAction('[app] Update Ui Check Intervention Nuisible', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckPremierssoins = createAction('[app] Update Ui Check Premierssoins', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckObservation = createAction('[app] Update Ui Check Observation', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCheckAutres = createAction('[app] Update Ui Check Autres', props<{
  param: {
    value?: any,
  }}>());

export const updateUiDescriptionIntervention = createAction('[app] Update Ui Description Intervention', props<{
  param: {
    value?: any,
  }}>());

export const updateUiNbPersonnes = createAction('[app] Update Ui Nb Personnes', props<{
  param: {
    value?: any,
  }}>());

export const updateUiNbVehicule = createAction('[app] Update Ui Nb Vehicule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne1Statut = createAction('[app] Update Ui Personne1 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne1Nom = createAction('[app] Update Ui Personne1 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne1Matricule = createAction('[app] Update Ui Personne1 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne1Description = createAction('[app] Update Ui Personne1 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne2Statut = createAction('[app] Update Ui Personne2 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne2Nom = createAction('[app] Update Ui Personne2 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne2Matricule = createAction('[app] Update Ui Personne2 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne2Description = createAction('[app] Update Ui Personne2 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne3Statut = createAction('[app] Update Ui Personne3 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne3Nom = createAction('[app] Update Ui Personne3 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne3Matricule = createAction('[app] Update Ui Personne3 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne3Description = createAction('[app] Update Ui Personne3 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne4Statut = createAction('[app] Update Ui Personne4 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne4Nom = createAction('[app] Update Ui Personne4 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne4Matricule = createAction('[app] Update Ui Personne4 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiPersonne4Description = createAction('[app] Update Ui Personne4 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule1Type = createAction('[app] Update Ui Vehicule1 Type', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule1Plaque = createAction('[app] Update Ui Vehicule1 Plaque', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule1Modele = createAction('[app] Update Ui Vehicule1 Modele', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule1Couleur = createAction('[app] Update Ui Vehicule1 Couleur', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule1Signes = createAction('[app] Update Ui Vehicule1 Signes', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule2Type = createAction('[app] Update Ui Vehicule2 Type', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule2Plaque = createAction('[app] Update Ui Vehicule2 Plaque', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule2Modele = createAction('[app] Update Ui Vehicule2 Modele', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule2Couleur = createAction('[app] Update Ui Vehicule2 Couleur', props<{
  param: {
    value?: any,
  }}>());

export const updateUiVehicule2Signes = createAction('[app] Update Ui Vehicule2 Signes', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCDateIntervention = createAction('[app] Update Ui C Date Intervention', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCHeureIntervention = createAction('[app] Update Ui C Heure Intervention', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCNomAgent1 = createAction('[app] Update Ui C Nom Agent1', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCNomAgent2 = createAction('[app] Update Ui C Nom Agent2', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCNomRemplacant = createAction('[app] Update Ui C Nom Remplacant', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCHoraire = createAction('[app] Update Ui C Horaire', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCTemperature = createAction('[app] Update Ui C Temperature', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCLieu = createAction('[app] Update Ui C Lieu', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCSecteur = createAction('[app] Update Ui C Secteur', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckReferencement = createAction('[app] Update Ui C Check Referencement', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheck311 = createAction('[app] Update Ui C Check311', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheck911 = createAction('[app] Update Ui C Check911', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckContactCitoyen = createAction('[app] Update Ui C Check Contact Citoyen', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckContactmarginalisee = createAction('[app] Update Ui C Check Contactmarginalisee', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckContactOrganisme = createAction('[app] Update Ui C Check Contact Organisme', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckInterventionCommercant = createAction('[app] Update Ui C Check Intervention Commercant', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckRamassagedechet = createAction('[app] Update Ui C Check Ramassagedechet', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckMaterieldonnee = createAction('[app] Update Ui C Check Materieldonnee', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckMaterielRecupere = createAction('[app] Update Ui C Check Materiel Recupere', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckAppelRecu = createAction('[app] Update Ui C Check Appel Recu', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckNuisible = createAction('[app] Update Ui C Check Nuisible', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckPremierssoins = createAction('[app] Update Ui C Check Premierssoins', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckObservation = createAction('[app] Update Ui C Check Observation', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCCheckAutres = createAction('[app] Update Ui C Check Autres', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCDescriptionSituation = createAction('[app] Update Ui C Description Situation', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCNbPersonnes = createAction('[app] Update Ui C Nb Personnes', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCNbvehicules = createAction('[app] Update Ui C Nbvehicules', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP1Statut = createAction('[app] Update Ui C P1 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP1Nom = createAction('[app] Update Ui C P1 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP1Matricule = createAction('[app] Update Ui C P1 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP1Description = createAction('[app] Update Ui C P1 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP2Statut = createAction('[app] Update Ui C P2 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP2Nom = createAction('[app] Update Ui C P2 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP2Matricule = createAction('[app] Update Ui C P2 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP2Description = createAction('[app] Update Ui C P2 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP3Statut = createAction('[app] Update Ui C P3 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP3Nom = createAction('[app] Update Ui C P3 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP3Matricule = createAction('[app] Update Ui C P3 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP3Description = createAction('[app] Update Ui C P3 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP4Statut = createAction('[app] Update Ui C P4 Statut', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP4Nom = createAction('[app] Update Ui C P4 Nom', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP4Matricule = createAction('[app] Update Ui C P4 Matricule', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCP4Description = createAction('[app] Update Ui C P4 Description', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV1Type = createAction('[app] Update Ui C V1 Type', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV1Plaque = createAction('[app] Update Ui C V1 Plaque', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV1Modele = createAction('[app] Update Ui C V1 Modele', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV1Couleur = createAction('[app] Update Ui C V1 Couleur', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV1Signes = createAction('[app] Update Ui C V1 Signes', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV2Type = createAction('[app] Update Ui C V2 Type', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV2Plaque = createAction('[app] Update Ui C V2 Plaque', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV2Modele = createAction('[app] Update Ui C V2 Modele', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV2Couleur = createAction('[app] Update Ui C V2 Couleur', props<{
  param: {
    value?: any,
  }}>());

export const updateUiCV2Signes = createAction('[app] Update Ui C V2 Signes', props<{
  param: {
    value?: any,
  }}>());

export const updateUiNomAgentSupport = createAction('[app] Update Ui Nom Agent Support', props<{
  param: {
    value?: any,
  }}>());

export const updateUiSupportURGENT = createAction('[app] Update Ui Support U R G E N T', props<{
  param: {
    value?: any,
  }}>());

export const updateUiSUpportMesage = createAction('[app] Update Ui S Upport Mesage', props<{
  param: {
    value?: any,
  }}>());
