import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  TableVariation,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../../compute-size';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ub-table',
  template: `
    <ng-container *ngIf="variation === 'raw'">
      <ng-container *ngTemplateOutlet="table"></ng-container>
    </ng-container>

    <ng-container *ngIf="variation === 'card'">
      <nb-card>
        <nb-card-header *ngIf="title">{{ title }}</nb-card-header>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="table"></ng-container>
        </nb-card-body>
      </nb-card>
    </ng-container>

    <ng-template #table>
      <table [ngClass]="{ border: border }">
        <thead>
          <ng-content select="tr[ubTableHeader]"></ng-content>
          <ng-template ubSlot [content]="header"></ng-template>
        </thead>
        <tbody>
          <ng-content select="tr[ubTableRow]"></ng-content>
          <ng-template ubSlot [content]="body"></ng-template>
        </tbody>
      </table>
    </ng-template>
  `,
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends BaseComponent
  implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() header?: RenderComponent[];
  @Input() body?: RenderComponent[];

  @Input() title: string = 'Simple Table';
  @Input() variation: TableVariation = 'card';

  @HostBinding('class.with-card')
  get isWithCard(): boolean {
    return this.variation === 'card';
  }

  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @Input() visible: boolean = true;

  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };

  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  @Input()
  border: boolean = true;

  @HostBinding('style.background')
  @Input()
  backgroundColor: string = 'unset';

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }
}
