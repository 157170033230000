import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { BaseComponent, ComponentInitService, RenderComponent, ThemeService } from '@uibakery/core';
import { SizeProperty, WithSizeComponent } from '@uibakery/fields-types';

@Component({
  selector: 'ub-layout-header',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        z-index: 1043;
      }

      :host.fixed {
        position: fixed;
        left: 0;
        right: 0;
      }
    `,
  ],
  template: `
    <nb-layout-header>
      <ng-template ubSlot name="content" [content]="content"> </ng-template>
      <ng-content></ng-content>
    </nb-layout-header>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHeaderComponent extends BaseComponent implements WithSizeComponent {
  @Input() content?: RenderComponent[];

  @HostBinding('class.fixed')
  @Input()
  fixed: boolean = false;

  constructor(private theme: ThemeService, cd: ChangeDetectorRef, initService: ComponentInitService) {
    super(cd, initService);
  }

  private _size: SizeProperty = {
    width: 'auto',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };

  get size(): SizeProperty {
    return this._size;
  }

  @Input() set size(size: SizeProperty) {
    this.theme.setVariable('--header-height', size?.height!);
    this._size = size;
  }

  private _spacing: { paddings: string; margins: string } = { paddings: '', margins: '' };

  get spacing(): { paddings: string; margins: string } {
    return this._spacing;
  }

  @Input() set spacing(spacing: { paddings: string; margins: string }) {
    this._spacing = spacing;

    if (spacing?.paddings) {
      this.theme.setVariable('--header-padding', spacing.paddings);
    }
  }

  private _backgroundColor: string = 'var(--background-basic-color-1)';

  get backgroundColor(): string {
    return this._backgroundColor;
  }

  @Input() set backgroundColor(color: string) {
    this._backgroundColor = color;
    this.theme.setVariable('--header-background-color', color);
  }

  get width(): string | undefined {
    return this._size?.width;
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return this._size?.height;
  }
}
