import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbIconModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { ExternalUrlModule } from '../shared/external-url/external-url.module';
import { InternalUrlModule } from '../shared/internal-url/internal-url.module';
import { ButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, CoreModule, NbButtonModule, NbIconModule, RouterModule, ExternalUrlModule, InternalUrlModule],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class ButtonModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: ButtonComponent,
    schema: require('./button.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./button.calculator.svg').default,
        componentTree: require('!!raw-loader?!./button.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./button.component-tree-active.svg').default,
      },
    },
  };
}
