import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbCalendarModule, NbFormFieldModule, NbInputModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { CalendarComponent } from './calendar.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CoreModule, NbCalendarModule],
  declarations: [CalendarComponent],
  exports: [CalendarComponent],
})
export class CalendarModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: CalendarComponent,
    schema: require('./calendar.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./calendar.calculator.svg').default,
        componentTree: require('!!raw-loader?!./calendar.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./calendar.component-tree-active.svg').default,
      },
    },
  };
}
