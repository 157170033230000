import { Pipe, PipeTransform } from '@angular/core';

import { QueryParamsProperty } from '@uibakery/fields-types';

@Pipe({ name: 'externalUrl' })
export class ExternalUrlPipe implements PipeTransform {
  transform(path: string, queryParams: QueryParamsProperty): string {
    return this.getExternalHref(path, queryParams);
  }

  private getExternalHref(path: string, queryParams: QueryParamsProperty): string {
    if (!queryParams || !Object.keys(queryParams).length) {
      return path;
    }

    const stringParams: string = Object.entries<string>(queryParams)
      .filter(([key]: [string, string]): boolean => !!key)
      .map(([key, value]: [string, string]): string => {
        if (!value) {
          return key;
        }
        return `${key}=${value}`;
      })
      .join('&');

    // this case can appear when we have empty key value pares
    if (!stringParams) {
      return path;
    }

    const connector: string = path.includes('?') ? '&' : '?';
    return `${path}${connector}${stringParams}`;
  }
}
