import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';

export interface AccordionItemInputs {
  name: string;
  title: string;
}

@Component({
  selector: 'ub-accordion-item',
  styleUrls: ['accordion-item.component.scss'],
  template: `
    <ng-content></ng-content>
    <ub-slot name="content" [content]="content"></ub-slot>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemComponent extends BaseComponent implements AccordionItemInputs {
  @Input() content?: RenderComponent[];
  @Input() title: string = 'Item';
  @Input() name: string = '';
}
