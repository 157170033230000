import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { BaseComponent, RenderComponent } from '@uibakery/core';
import {
  AccordionVariation,
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../../compute-size';
import { AccordionItemInputs } from '../accordion-item/accordion-item.component';

@Component({
  selector: 'ub-accordion',
  styleUrls: ['./accordion.component.scss'],
  template: `
    <ng-container *ngIf="variation === 'raw'">
      <ng-container *ngTemplateOutlet="accordion"></ng-container>
    </ng-container>

    <ng-container *ngIf="variation === 'card'">
      <nb-card>
        <nb-card-header *ngIf="title">{{ title }}</nb-card-header>
        <nb-card-body>
          <ng-container *ngTemplateOutlet="accordion"></ng-container>
        </nb-card-body>
      </nb-card>
    </ng-container>

    <ng-template #accordion>
      <nb-accordion cdkScrollable [multi]="multi">
        <nb-accordion-item *ngFor="let item of content; trackBy: trackByName">
          <nb-accordion-item-header> {{ item.inputs.title }}</nb-accordion-item-header>
          <nb-accordion-item-body>
            <ub-slot [content]="[item]"></ub-slot>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent extends BaseComponent
  implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() content?: RenderComponent<AccordionItemInputs>[];

  @Input() title: string = 'Accordion';
  @Input() variation: AccordionVariation = 'card';

  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() multi: boolean = false;
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.margin')
  get margins(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }

  trackByName(index: number, item: RenderComponent<AccordionItemInputs>): string {
    return item.inputs.name;
  }
}
