import { NgModule } from '@angular/core';
import { NbTabsetModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { TabComponent } from './tab.component';

@NgModule({
  imports: [CoreModule, NbTabsetModule],
  declarations: [TabComponent],
  exports: [TabComponent],
})
export class TabModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: TabComponent,
    schema: require('./tab.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./tab.calculator.svg').default,
        componentTree: require('!!raw-loader?!./tab.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./tab.component-tree-active.svg').default,
      },
    },
  };
}
