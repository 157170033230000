import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({ name: 'internalUrl' })
export class InternalUrlPipe implements PipeTransform {
  constructor(private router: Router) {}

  transform(link: string): string {
    return this.router.serializeUrl(this.router.createUrlTree([link]));
  }
}
