import { NgModule } from '@angular/core';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { TableRowComponent } from './table-row.component';

@NgModule({
  imports: [CoreModule],
  declarations: [TableRowComponent],
  exports: [TableRowComponent],
})
export class TableRowModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: TableRowComponent,
    schema: require('./table-row.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!../table/table.calculator.svg').default,
        componentTree: require('!!raw-loader?!../table/table.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!../table/table.component-tree-active.svg').default,
      },
    },
  };
}
