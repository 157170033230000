import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { InternalUrlPipe } from './internal-url.pipe';

@NgModule({
  imports: [RouterModule],
  declarations: [InternalUrlPipe],
  exports: [InternalUrlPipe],
})
export class InternalUrlModule {}
