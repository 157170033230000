export type ChartColorModel = 'primary' | 'success' | 'warning' | 'danger' | 'info';

export enum ChartType {
  LINE_CHART = 'lineChart',
  BAR_CHART = 'barChart',
  PIE_CHART = 'pieChart',
  DOUGHNUT_CHART = 'doughnutChart',
}

export interface DataObject {
  [key: string]: Object;
}

export interface ChartDataItem {
  title: string;
  data: DataObject[];
  color: ChartColorModel;
  xProp: string;
  yProp: string;
}

export interface ChartLegacyDataItem {
  name: string;
  data: (number | string)[][] | DataObject[];
}

export interface ChartConfig {
  title: string;
  dataFormatType: ChartDataFormatType;
  data: (ChartDataItem | ChartLegacyDataItem)[];
}

export enum ChartDataFormatType {
  KEY_VALUE_OBJECT = 'keyValueObject',
  TUPLE = 'tuple',
}
