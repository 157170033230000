import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbComponentStatus } from '@nebular/theme';
import { BehaviorSubject, merge, Observable } from 'rxjs';

import { BAKERY_COMPONENT, BaseComponent } from '@uibakery/core';
import { AlignSelfValue, FlexProperty, WithFlexComponent, WithVisibleComponent } from '@uibakery/fields-types';

@Component({
  selector: 'ub-toggle',
  template: `
    <nb-toggle [formControl]="formControl" [status]="status">
      {{ label }}
    </nb-toggle>
  `,
  providers: [{ provide: BAKERY_COMPONENT, useExisting: ToggleComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent extends BaseComponent implements WithFlexComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() status: NbComponentStatus = 'primary';
  @Input() label: string = 'Toggle me';
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  formControl: FormControl = new FormControl(false);
  @Output() toggleChange: Observable<boolean> = this.formControl.valueChanges;
  private _uiValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() uiValue: Observable<boolean> = merge(this._uiValue.asObservable(), this.formControl.valueChanges);

  get value(): boolean {
    return this.formControl.value;
  }

  @Input()
  set value(value: boolean) {
    value = Boolean(value);
    this.formControl.setValue(value, { emitEvent: false });
    this._uiValue.next(value);
  }

  get disabled(): boolean {
    return this.formControl.disabled;
  }

  @Input()
  set disabled(disable: boolean) {
    if (disable) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }
}
