import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent, ComponentType } from '@uibakery/core';

@Component({
  selector: 'ub-card-body',
  template: `
    <ng-content></ng-content>
    <ub-slot name="content" [content]="content"></ub-slot>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBodyComponent extends BaseComponent {
  @Input() content: ComponentType[] = [];
}
