import { EChartTitleOption } from 'echarts';

import { ChartVariation } from '@uibakery/fields-types';

// TODO Replace custom types with echarts type.
// In v4 this types are missing. Probably improved in v5.
interface GridOptions {
  top: string;
  left: string;
  right: string;
  bottom: string;
  containLabel: boolean;
}

interface LegendOptions {
  itemWidth: number;
  itemHeight: number;
  icon: string;
  padding: number;
}

export function getGrid(variation: ChartVariation): GridOptions {
  if (variation === 'card') {
    return {
      top: '60',
      left: '30',
      right: '30',
      bottom: '45',
      containLabel: true,
    };
  }

  return {
    top: '60',
    left: '10',
    right: '10',
    bottom: '25',
    containLabel: true,
  };
}

export function getTitleDefaults(variation: ChartVariation): EChartTitleOption {
  return {
    padding: getTextPadding(variation),
  };
}

export function getLegendDefaults(variation: ChartVariation): LegendOptions {
  return {
    itemWidth: 16,
    itemHeight: 16,
    icon: 'roundRect',
    padding: getTextPadding(variation),
  };
}

export function getTextPadding(variation: ChartVariation): number {
  if (variation === 'card') {
    return 20;
  }

  return 5;
}
