import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbDatepickerModule, NbFormFieldModule, NbInputModule } from '@nebular/theme';

import { ComponentMetadata, COMPONENT_METADATA, CoreModule } from '@uibakery/core';

import { DatepickerComponent } from './datepicker.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CoreModule, NbInputModule, NbDatepickerModule, NbFormFieldModule],
  declarations: [DatepickerComponent],
  exports: [DatepickerComponent],
})
export class DatepickerModule {
  static [COMPONENT_METADATA]: ComponentMetadata = {
    componentType: DatepickerComponent,
    schema: require('./datepicker.schema.json'),
    assets: {
      icons: {
        calculator: require('!!raw-loader?!./datepicker.calculator.svg').default,
        componentTree: require('!!raw-loader?!./datepicker.component-tree.svg').default,
        componentTreeActive: require('!!raw-loader?!./datepicker.component-tree-active.svg').default,
      },
    },
  };
}
