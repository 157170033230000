import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NbComponentStatus } from '@nebular/theme';

import { BaseComponent, RenderComponent } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../../compute-size';

@Component({
  selector: 'ub-card',
  styleUrls: ['./card.component.scss'],
  template: `
    <nb-card
      [nbSpinner]="showLoading"
      [status]="getValidCardStatus(status)"
      [accent]="getValidCardStatus(accent)"
      [style.width.%]="100"
      [style.height.%]="100"
      [style.background]="backgroundColorStyle"
      (click)="cardClick.emit()"
    >
      <nb-card-header *ngIf="showHeader" [class.no-padding]="!headerPadding">
        <ub-slot name="header" [content]="header"></ub-slot>
        <ng-content select="ub-card-header"></ng-content>
      </nb-card-header>

      <nb-card-body [class.no-padding]="!bodyPadding" cdkScrollable>
        <ub-slot name="body" [content]="body"></ub-slot>
        <ng-content select="ub-card-body"></ng-content>
      </nb-card-body>

      <nb-card-footer *ngIf="showFooter" [class.no-padding]="!footerPadding">
        <ub-slot name="footer" [content]="footer"></ub-slot>
        <ng-content select="ub-card-footer"></ng-content>
      </nb-card-footer>
    </nb-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent extends BaseComponent implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() header?: RenderComponent[];
  @Input() body?: RenderComponent[];
  @Input() footer?: RenderComponent[];

  @Input() visible: boolean = true;
  @Input() status: NbComponentStatus | 'default' = 'default';
  @Input() accent: NbComponentStatus | 'default' = 'default';
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };
  @Input() size: SizeProperty = {
    width: '100%',
    height: 'auto',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };
  @Input() backgroundColor: string = 'var(--background-basic-color-1)';
  @Input() backgroundImage: string = '';
  @Input() showHeader: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() headerPadding: boolean = true;
  @Input() bodyPadding: boolean = true;
  @Input() footerPadding: boolean = true;
  @Input() showLoading: boolean = false;

  @Output() cardClick: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf!;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order!;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex!;
  }

  @HostBinding('style.margin')
  get margins(): string | undefined {
    return this.spacing?.margins;
  }

  get backgroundColorStyle(): string {
    return this.backgroundImage || this.backgroundColor;
  }

  getValidCardStatus(status: NbComponentStatus | 'default'): NbComponentStatus | undefined {
    return status === 'default' ? undefined : status;
  }
}
