import { SortDirection, TableColumn } from '@swimlane/ngx-datatable';

import { SmartTableColumn } from '@uibakery/fields-types';

export enum SmartTableRowAction {
  EDIT = 'edit',
  EDIT_SAVE = 'edit_save',
  EDIT_CANCEL = 'edit_cancel',
  REMOVE = 'remove',
}

export enum SmartTableAddAction {
  ADD = 'add',
  ADD_SAVE = 'add_save',
  ADD_CANCEL = 'add_cancel',
}

export type SmartTableRow = Record<string, string | number>;

export const SMART_TABLE_ROW_DATA: string = '__SMART_TABLE_ROW_DATA__';
export const SMART_TABLE_ROW_EDITED_DATA: string = '__SMART_TABLE_ROW_EDITED_DATA__';
export const SMART_TABLE_ROW_EDIT_STATUS: string = '__SMART_TABLE_ROW_EDIT_STATUS__';
export const SMART_TABLE_ROW_UNIQUE_KEY: string = '__SMART_TABLE_ROW_UNIQUE_KEY__';

export interface WithEdit<T> {
  __SMART_TABLE_ROW_DATA__: T;
  __SMART_TABLE_ROW_EDITED_DATA__: T;
  __SMART_TABLE_ROW_EDIT_STATUS__: boolean;
  __SMART_TABLE_ROW_UNIQUE_KEY__: T | string | undefined;
}

export interface SmartTableColumnWithAdditionInfo {
  summary: string | undefined;
  column: SmartTableColumn;
}

export interface SmartTablePageInfo {
  count: number;
  pageSize: number;
  limit: number;
  offset: number;
}

export interface SmartTableSimpleData {
  columns: SmartTableColumnWithAdditionInfo[];
  rows: SmartTableRow[];
  showAdditionRow?: boolean;
}

export interface SmartTableData<T> {
  columns: SmartTableColumnWithAdditionInfo[];
  rows: T[];
  showAdditionRow?: boolean;
  selectedRows: T[];
  pageInfo: SmartTablePageInfo;
}

export interface SmartTableFilterInfo {
  value: string;
  column: string;
}

export interface SmartTableSortInfo {
  dir: SortDirection;
  prop: string;
}

export interface SmartTableSortEvent {
  column: TableColumn;
  prevValue: SortDirection;
  newValue: SortDirection;
  sorts: SmartTableSortInfo[];
}

export interface SmartTableActionEvent {
  data?: SmartTableRow;
  newData?: SmartTableRow;
  isSelected?: boolean;
}
