import { AfterViewInit, Directive, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

import { Direction } from '@uibakery/fields-types';

const MAX_SIZE: number = 3;
const MIN_SIZE: number = 1;
const GAP: number = 6;

/**
 * DividerDragHandleDirective purpose is to ensure that divider remains draggable when it's width or height is too small.
 * The solution is not ideal though, and needs to be refactored.
 * TODO: https://akveo.myjetbrains.com/youtrack/issue/UIB_DEV_UB-3355
 * */
@Directive({ selector: '[ubDividerDragHandle]' })
export class DividerDragHandleDirective implements OnInit, OnDestroy, AfterViewInit {
  private direction?: Direction;
  private innerElement?: HTMLDivElement;
  private resizeObserver?: ResizeObserver;

  @Input() set ubDividerDragHandle(direction: Direction) {
    this.direction = direction;
  }

  private get nativeElement(): HTMLElement {
    return this.viewContainerRef.element.nativeElement;
  }

  constructor(private viewContainerRef: ViewContainerRef, private renderer2: Renderer2) {}

  ngOnInit(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.setStyles();
    });
    this.resizeObserver.observe(this.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver?.unobserve(this.nativeElement);
  }

  ngAfterViewInit(): void {
    this.createElement();
  }

  private createElement(): void {
    this.innerElement = this.renderer2.createElement('div');
    this.renderer2.appendChild(this.nativeElement, this.innerElement);
    this.setStyles();
  }

  private setStyles(): void {
    if (!this.needShow()) {
      this.setInnerStyle('display', 'none');
      return;
    }

    this.setInnerStyle('display', 'block');

    let sizeProp: string = 'height';
    let translate: string = 'translate(0, -50%)';
    let left: string = '0';
    let top: string = '50%';

    if (this.direction === 'column') {
      sizeProp = 'width';
      translate = 'translate(-50%, 0)';
      left = '50%';
      top = '0';
    }

    this.setInnerStyle('left', left);
    this.setInnerStyle('top', top);
    this.setInnerStyle(sizeProp, `calc(100% + ${GAP}px)`);
    this.setInnerStyle('transform', translate);
  }

  private needShow(): boolean {
    const size: number = this.direction === 'row' ? this.nativeElement.offsetHeight : this.nativeElement.offsetWidth;
    return size >= MIN_SIZE && size <= MAX_SIZE;
  }

  private setInnerStyle(key: string, value: string): void {
    if (this.innerElement) {
      this.renderer2.setStyle(this.innerElement, key, value);
    }
  }
}
