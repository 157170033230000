import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { BaseComponent, ComponentInitService } from '@uibakery/core';
import {
  AlignSelfValue,
  FlexProperty,
  SizeProperty,
  WithFlexComponent,
  WithSizeComponent,
  WithVisibleComponent,
} from '@uibakery/fields-types';

import { computeSize } from '../compute-size';

@Component({
  selector: 'ub-iframe',
  styleUrls: ['./iframe.component.scss'],
  template: ` <iframe ubComponentShield [src]="resourceUrl$ | async"></iframe> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeComponent extends BaseComponent
  implements WithFlexComponent, WithSizeComponent, WithVisibleComponent {
  @Input() visible: boolean = true;
  @Input() spacing: { paddings: string; margins: string } = { margins: '', paddings: '' };

  url$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  resourceUrl$: Observable<SafeResourceUrl> = this.url$.pipe(
    distinctUntilChanged(),
    map((url: string) => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
  );
  @Input() set url(url: string) {
    this._url = url;
    this.url$.next(url);
  }
  get url(): string {
    return this._url;
  }
  private _url: string = '';

  @Input() size: SizeProperty = {
    width: '300px',
    height: '300px',
    minWidth: '0',
    minHeight: '0',
    maxWidth: 'none',
    maxHeight: 'none',
  };
  @Input() flex: FlexProperty = { flex: '0 1 auto', alignSelf: 'auto', order: 0 };

  constructor(cd: ChangeDetectorRef, public sanitizer: DomSanitizer, initService: ComponentInitService) {
    super(cd, initService);
  }

  @HostBinding('style.display')
  get hiddenDisplay(): 'none' | undefined {
    return this.visible ? undefined : 'none';
  }

  @HostBinding('style.margin')
  get margin(): string | undefined {
    return this.spacing?.margins;
  }

  @HostBinding('style.width')
  get width(): string | undefined {
    return computeSize(this.size?.width, this.spacing?.margins, 'inline');
  }

  @HostBinding('style.height')
  get height(): string | undefined {
    return computeSize(this.size?.height, this.spacing?.margins);
  }

  @HostBinding('style.alignSelf')
  get alignSelf(): AlignSelfValue | undefined {
    return this.flex?.alignSelf;
  }

  @HostBinding('style.order')
  get flexOrder(): number | undefined {
    return this.flex?.order;
  }

  @HostBinding('style.flex')
  get flexChild(): string | undefined {
    return this.flex?.flex;
  }
}
